import React from 'react';
import Paper from '@mui/material/Paper';

function Impressum() {
    return (
        <div >
            <Paper style={{ padding: '20px'}}>
                <div><p><strong>Herausgeber</strong></p><p>Bayerische Staatsgüter<br />Prof.-Zorn-Straße 19<br />D-85586 Poing/Grub<br />Tel.: +49 89 6933442-100<br />Fax: +49 89 6933442-109<br />E-Mail: poststelle@baysg.bayern.de</p></div>


                <div >
                    <strong>Vertretungsberechtigter:<br /></strong>
                    <div >Anton Dippold, Güterdirektor</div></div>
                <div >
                    <strong>USt-Identifikationsnummer:<br /></strong>
                    <div >DE 327422140</div></div><div>
                    <p><strong>Verantwortlich für den Inhalt</strong><br />E-Mail: <a href="mailto:presse@baysg.bayern.de" title="Eine Nachricht versenden an: presse@baysg.bayern.de">presse@baysg.bayern.de</a></p>
                </div>

                <div>
                    <p> <strong>In Zusammenarbeit mit:<br /></strong></p>
                    Bayerische Landesanstalt für Landwirtschaft
                    <p>(<a href="www.bibb.de">www.lfl.bayern.de</a>) <br/></p>

                </div>


                <div>
                    <strong>Förderhinweis:<br /></strong>
                    Geförderte durch <br />
                    BIBB (<a href="www.bibb.de">www.bibb.de</a>) <br />
                    BMBF (<a href="www.bmbf.de">www.bmbf.de</a>) <br />
                </div>
            </Paper>
        </div>
    );
}

export default Impressum;
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Food, FoodCalculator, FoodExtended } from '../../foodcalc/FoodCalc';
import { BlendCalculator } from './blendcalc';


function Row(props: { row: FoodExtended; }) {
    const { row } = props;

    //console.log(row);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                <TableCell>{row.xf.toFixed(2)} g</TableCell>
                <TableCell>{row.andfom.toFixed(2)} g</TableCell>
                <TableCell>{row.rnbPerKg.toFixed()}</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.xp} g</TableCell>
                <TableCell>{row.nxp} g</TableCell>
                <TableCell>{row.nel} MJ</TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function RowMinerals(props: { row: FoodExtended; }) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.tmPerKg} kg</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.ca} g</TableCell>
                <TableCell>{row.p} g</TableCell>
                <TableCell>{row.mg} g</TableCell>
                <TableCell>{row.na} g</TableCell>
                <TableCell>{row.k} g</TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function SumBlendComponent(props: { isMinerals: boolean; SetOpen: boolean; dialogType: any; foodList: FoodExtended[]; calculator: BlendCalculator }) {
    const { isMinerals, SetOpen, dialogType, foodList, calculator } = props;

    const [open, setOpen] = React.useState(SetOpen ? SetOpen : false);

    useEffect(() => {

    }, []);


    if (foodList.length > 0) {
        if (isDesktop) {
            return (
                <React.Fragment>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsable table"
                        >
                            <TableHead onClick={() => setOpen(!open)} >
                                <TableRow>
                                    <TableCell style={{ width: 210 }}>
                                        <Typography sx={{ float: "left", marginTop: .6 }}></Typography>
                                        <IconButton aria-label="expand row" size="small">
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>TM</TableCell>
                                    {isMinerals ? <TableCell></TableCell> : null}
                                    <TableCell>{isMinerals ? "Ca" : "XF"} </TableCell>
                                    <TableCell>{isMinerals ? "P" : "aNDF"}</TableCell>

                                    <TableCell>{isMinerals ? "Mg" : "RNB"}</TableCell>
                                    {isMinerals ? null : <TableCell></TableCell>}
                                    <TableCell>{isMinerals ? "Na" : "XP"}</TableCell>
                                    <TableCell>{isMinerals ? "K" : "nXP"}</TableCell>

                                    {isMinerals ? null : <TableCell>NEL</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {open ?
                                    <React.Fragment >
                                        {
                                            !isMinerals ?
                                                foodList.map((row, index) => {
                                                    return <Row row={row} />
                                                })
                                                :
                                                foodList.map((row, index) => {
                                                    return <RowMinerals row={row} />
                                                })
                                        }

                                    </React.Fragment>
                                    :
                                    null
                                }
                                <TableRow >
                                    <TableCell>Summe</TableCell>
                                    <TableCell>{calculator.Blend.tmPerKg.toFixed()} kg</TableCell>
                                    {isMinerals ? (!open ? <TableCell></TableCell> : <TableCell></TableCell>) : null}
                                    <TableCell>{isMinerals ? (!open ? calculator.Blend.ca.toFixed() + " kg" : calculator.Blend.ca.toFixed() + " g") : calculator.ANDFPercentage.toFixed() + "%"}</TableCell>
                                    <TableCell>{isMinerals ? (!open ? calculator.Blend.p.toFixed() + " kg" : calculator.Blend.p.toFixed() + " g") : calculator.XfPercentage.toFixed() + "%"}</TableCell>
                                    <TableCell>{isMinerals ? (!open ? calculator.Blend.mg.toFixed() + " kg" : calculator.Blend.mg.toFixed() + " g") : calculator.Blend.rnb.toFixed()} </TableCell>
                                    {isMinerals ? null : !open ? <TableCell></TableCell> : <TableCell></TableCell>}
                                    <TableCell>{isMinerals ? (!open ? calculator.Blend.na.toFixed() + " kg" : calculator.Blend.na.toFixed() + " g") : open ? calculator.Blend.xp.toFixed() + " g" : calculator.Blend.xp.toFixed() + " g"} </TableCell>
                                    <TableCell>{isMinerals ? (!open ? calculator.Blend.k.toFixed() + " kg" : calculator.Blend.k.toFixed() + " g") : open ? calculator.Blend.nxp.toFixed() + " g" : calculator.Blend.nxp.toFixed() + " g"}</TableCell>
                                    {
                                        isMinerals ?
                                            ""
                                            :
                                            <React.Fragment>
                                                <TableCell>{open ? calculator.Blend.nel.toFixed() + " MJ" : calculator.Blend.nel.toFixed() + " MJ"}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>



                            </TableBody>
                        </Table>
                    </TableContainer>

                </React.Fragment>
            );
        }

        if (isMobile) {
            if (isMinerals) {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead onClick={() => setOpen(!open)} >
                                    <TableRow>
                                        <TableCell style={{ width: 210 }}>
                                            <Typography sx={{ float: "left", marginTop: .6 }}></Typography>
                                            <IconButton aria-label="expand row" size="small">
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        {dialogType === 0 || open ?
                                            <TableCell>Summe</TableCell>
                                            : null}
                                        {open ? calculator.Blend.Blend.map((row, index) => {
                                            return <TableCell>{row.name}</TableCell>
                                        }) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogType === 0 || open ?
                                        <React.Fragment>

                                            <TableRow>
                                                <TableCell>TM </TableCell>
                                                <TableCell>{calculator.Blend.tm.toFixed()} kg ({(calculator.Blend.tm / calculator.Blend.fm * 100).toFixed()}%)</TableCell>
                                                {open ? calculator.Blend.Blend.map((row, index) => {
                                                    return <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                                                }) : null}
                                            </TableRow>
                                            {open ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell>Ca</TableCell>
                                                        <TableCell>{calculator.Blend.ca.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.ca.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>P</TableCell>
                                                        <TableCell>{calculator.Blend.p.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.p.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Mg</TableCell>
                                                        <TableCell>{calculator.Blend.mg.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.mg.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Na</TableCell>
                                                        <TableCell>{calculator.Blend.na.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.na.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>K</TableCell>
                                                        <TableCell>{calculator.Blend.k.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.k.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </React.Fragment>
                                                : null
                                            }
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Ca</TableCell>
                                                <TableCell>{calculator.Blend.ca.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>P</TableCell>
                                                <TableCell>{calculator.Blend.p.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mg</TableCell>
                                                <TableCell>{calculator.Blend.mg.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Na</TableCell>
                                                <TableCell>{calculator.Blend.na.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>K</TableCell>
                                                <TableCell>{calculator.Blend.k.toFixed()} kg</TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )
            }
            else {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead onClick={() => setOpen(!open)} >
                                    <TableRow>
                                        <TableCell style={{ width: 210 }}>
                                            <Typography sx={{ float: "left", marginTop: .6 }}>Grundfutter</Typography>
                                            <IconButton aria-label="expand row" size="small">
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        {dialogType === 0 || open ?
                                            <TableCell>Summe</TableCell>
                                            : null}
                                        {open ? calculator.Blend.Blend.map((row, index) => {
                                            return <TableCell>{row.name}</TableCell>
                                        }) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogType === 0 || open ?
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell>TM</TableCell>
                                                <TableCell>{calculator.Blend.tm.toFixed()} kg ({(calculator.Blend.tm / calculator.Blend.fm * 100).toFixed()}%)</TableCell>
                                                {open ? calculator.Blend.Blend.map((row, index) => {
                                                    return <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>XF</TableCell>
                                                <TableCell>{calculator.XfPercentage.toFixed() + "%"}</TableCell>
                                                {open ? calculator.Blend.Blend.map((row, index) => {
                                                    return <TableCell>{row.xf.toFixed()} g</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>aNDF</TableCell>
                                                <TableCell>{calculator.ANDFPercentage.toFixed() + "%"} </TableCell>
                                                {open ? calculator.Blend.Blend.map((row, index) => {
                                                    return <TableCell>{row.andfom.toFixed()} g</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>RNB</TableCell>
                                                <TableCell>{calculator.Blend.rnb.toFixed()}</TableCell>
                                                {open ? calculator.Blend.Blend.map((row, index) => {
                                                    return <TableCell>{row.rnbPerKg.toFixed()}</TableCell>
                                                }) : null}
                                            </TableRow>

                                            {open ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell>XP</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.xp.toFixed()} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>nXP</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.nxp.toFixed()} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>NEL</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.Blend.Blend.map((row, index) => {
                                                                return <TableCell>{row.nel.toFixed()} MJ</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </React.Fragment>

                                                : null}


                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>XP</TableCell>
                                                <TableCell>{calculator.Blend.xp.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>nXP</TableCell>
                                                <TableCell>{calculator.Blend.nxp.toFixed() + " kg"}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>NEL</TableCell>
                                                <TableCell>{calculator.Blend.nel.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                );
            }
        }
    }

    return (
        <React.Fragment>

        </React.Fragment>
    );
}
import React, { useEffect } from "react";
import "./App.css";
import Layout from "./components/Layout";
import AppContext from "./components/appcontext";
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";

/*
{
      id: 0,
      active: false,
      name: "Standard",
      date: new Date().toISOString().split('.')[0],
      cowWeight: 700,
      performanceMilkProductionKg: 30,
      compensationMilkProductionKg: 24,
      milkFatPercent: 4.2,
      proteinPercent: 3.6,
      foodIntake: 0,
      cowNumber: 20,
      basicRation: [],
      compensationRation: [],
      performanceRation: [],
    },
*/
const theme = createTheme();

const globalState = {
    ownFodder: [
        {
            name: "Maissilage",
            tm: 350,
            xf: 195,
            andfom: 485,
            xp: 82,
            nxp: 134,
            nel: 6.69,
            ca: 2.0,
            p: 2.2,
            mg: 1.3,
            na: 0.3,
            k: 11,
        },
        {
            name: "Grassil.",
            tm: 350,
            xf: 224,
            andfom: 465,
            xp: 180,
            nxp: 143,
            nel: 6.36,
            ca: 6.5,
            p: 4.0,
            mg: 2.4,
            na: 0.7,
            k: 31,
        },
        {
            name: "Heu",
            tm: 860,
            xf: 315,
            andfom: 605,
            xp: 98,
            nxp: 118,
            nel: 5.27,
            ca: 4.0,
            p: 2.5,
            mg: 1.6,
            na: 0.4,
            k: 20,
        },
        {
            name: "Weizen (AF)",
            tm: 880,
            xp: 121,
            xf: 0,
            andfom: 0,
            nxp: 150,
            nel: 7.5,
            ca: 0.6,
            p: 3.3,
            mg: 1.1,
            na: 0.2,
            k: 4,
        },
        {
            name: "MLF (16% XP 6,7MJ NEL)",
            tm: 880,
            xp: 160,
            xf: 0,
            andfom: 0,
            nxp: 151,
            nel: 6.7,
            ca: 7.0,
            p: 4.0,
            mg: 1.5,
            na: 1.5,
            k: 9,
        },
    ],
    rations: [
        {
            id: 0,
            active: true,
            name: "Beispiel 50:50",
            date: "2022-09-08T13:12:04",
            cowWeight: 700,
            compensationMilkProductionKg: "22",
            performanceMilkProductionKg: "30",
            milkFatPercent: 4.2,
            proteinPercent: 3.6,
            foodIntake: 21,
            cowNumber: "480",
            basicRation: [
                {
                    id: 90,
                    GruberId: 2014,
                    name: "GrasSilage, angew., 1.Schnitt, RispenSchieben",
                    tm: 350,
                    xf: 224,
                    andfom: 465,
                    xp: 180,
                    nxp: 143.1,
                    nel: 6.37,
                    ca: 6.5,
                    p: 4,
                    mg: 2.35,
                    na: 0.7,
                    k: 31,
                    rnb: 5.9,
                    fm: 18,
                },
                {
                    id: 124,
                    GruberId: 2226,
                    name: "MaisSilage, in Wachsreife, körnerreich, 36% TM",
                    tm: 360,
                    xf: 172,
                    andfom: 440,
                    xp: 82,
                    nxp: 136.15,
                    nel: 6.84,
                    ca: 1.9,
                    p: 2.2,
                    mg: 1.2,
                    na: 0.25,
                    k: 9,
                    rnb: -8.66,
                    fm: 18,
                },
                {
                    id: 163,
                    GruberId: 3010,
                    name: "Heu, Wiese",
                    tm: 860,
                    xf: 282,
                    andfom: 555,
                    xp: 115,
                    nxp: 125.02,
                    nel: 5.65,
                    ca: 4.4,
                    p: 2.9,
                    mg: 1.8,
                    na: 0.4,
                    k: 22,
                    rnb: -1.6,
                    fm: 2,
                },
            ],
            compensationRation: [
                {
                    id: 305,
                    GruberId: 6425,
                    name: "RapsExtraktionsSchrot",
                    tm: 890,
                    xf: 133.1,
                    andfom: 300.6,
                    xp: 386.6,
                    nxp: 251.98,
                    nel: 7.16,
                    ca: 8.67,
                    p: 11.9,
                    mg: 6,
                    na: 0.5,
                    k: 14.2,
                    rnb: 21.54,
                    fm: 1.6,
                },
                {
                    id: 227,
                    GruberId: 4205,
                    name: "Körnermais",
                    tm: 880,
                    xf: 26.14,
                    andfom: 170,
                    xp: 102.27,
                    nxp: 165.81,
                    nel: 8.39,
                    ca: 0.4,
                    p: 3.5,
                    mg: 1.25,
                    na: 0.2,
                    k: 4.1,
                    rnb: -10.17,
                    fm: 1,
                },
            ],
            performanceRation: [
                {
                    id: 363,
                    GruberId: 8105,
                    name: "MilchleistFutter, 14% Rohprotein, EnergieStufe 3",
                    tm: 880,
                    xf: 85.22,
                    andfom: 285,
                    xp: 159.09,
                    nxp: 165.61,
                    nel: 7.61,
                    ca: 7.95,
                    p: 4.55,
                    mg: 1.7,
                    na: 1.7,
                    k: 10.23,
                    rnb: -1.04,
                    fm: 4.2,
                },
                {
                    id: 383,
                    GruberId: 8185,
                    name: "Mineralfutter Milchkuh laktierend, 25% Ca, 0% P",
                    tm: 950,
                    xf: 0,
                    andfom: 0,
                    xp: 0,
                    nxp: 0,
                    nel: 0,
                    ca: 263.16,
                    p: 0,
                    mg: 21.05,
                    na: 105.26,
                    k: 0,
                    rnb: 0,
                    fm: 0.1,
                },
                {
                    id: 248,
                    GruberId: 4945,
                    name: "Viehsalz",
                    tm: 990,
                    xf: 0,
                    andfom: 0,
                    xp: 0,
                    nxp: 0,
                    nel: 0,
                    ca: 0,
                    p: 0,
                    mg: 0,
                    na: 365,
                    k: 0,
                    rnb: 0,
                    fm: 0.02,
                },
            ],
        },
        {
            id: 1,
            active: false,
            name: "Beispiel 100 Gras",
            date: "2022-09-08T13:19:05",
            cowWeight: 700,
            compensationMilkProductionKg: "21",
            performanceMilkProductionKg: "24",
            milkFatPercent: 4.2,
            proteinPercent: 3.6,
            foodIntake: 21,
            cowNumber: 60,
            basicRation: [
                {
                    id: 92,
                    GruberId: 2016,
                    name: "GrasSilage, angew., 1.Schnitt, Mitte Blüte",
                    tm: 350,
                    xf: 295,
                    andfom: 570,
                    xp: 145,
                    nxp: 127.76,
                    nel: 5.7,
                    ca: 5.8,
                    p: 3.7,
                    mg: 2.15,
                    na: 0.8,
                    k: 29,
                    rnb: 2.76,
                    fm: 40,
                },
            ],
            compensationRation: [
                {
                    id: 227,
                    GruberId: 4205,
                    name: "Körnermais",
                    tm: 880,
                    xf: 26.14,
                    andfom: 170,
                    xp: 102.27,
                    nxp: 165.81,
                    nel: 8.39,
                    ca: 0.4,
                    p: 3.5,
                    mg: 1.25,
                    na: 0.2,
                    k: 4.1,
                    rnb: -10.17,
                    fm: 2.9,
                },
                {
                    id: 219,
                    GruberId: 4025,
                    name: "Gerste, 2-zeilig",
                    tm: 880,
                    xf: 50,
                    andfom: 210,
                    xp: 125,
                    nxp: 165.11,
                    nel: 8.21,
                    ca: 0.7,
                    p: 4,
                    mg: 1.3,
                    na: 0.3,
                    k: 5,
                    rnb: -6.42,
                    fm: 0.6,
                },
                {
                    id: 325,
                    GruberId: 6514,
                    name: "MelasseSchnitzel, 12% Zucker",
                    tm: 910,
                    xf: 160,
                    andfom: 330,
                    xp: 90,
                    nxp: 145.33,
                    nel: 7.46,
                    ca: 11,
                    p: 0.8,
                    mg: 1.8,
                    na: 1,
                    k: 12,
                    rnb: -8.85,
                    fm: 0.6,
                },
            ],
            performanceRation: [
                {
                    id: 383,
                    GruberId: 8185,
                    name: "Mineralfutter Milchkuh laktierend, 25% Ca, 0% P",
                    tm: 950,
                    xf: 0,
                    andfom: 0,
                    xp: 0,
                    nxp: 0,
                    nel: 0,
                    ca: 263.16,
                    p: 0,
                    mg: 21.05,
                    na: 105.26,
                    k: 0,
                    rnb: 0,
                    fm: 0.05,
                },
                {
                    id: 366,
                    GruberId: 8116,
                    name: "MilchleistFutter, 16% Rohprotein, EnergieStufe 3",
                    tm: 880,
                    xf: 90.91,
                    andfom: 285,
                    xp: 181.82,
                    nxp: 171.56,
                    nel: 7.61,
                    ca: 7.95,
                    p: 4.55,
                    mg: 1.7,
                    na: 1.7,
                    k: 10.23,
                    rnb: 1.64,
                    fm: 1.5,
                },
                {
                    id: 248,
                    GruberId: 4945,
                    name: "Viehsalz",
                    tm: 990,
                    xf: 0,
                    andfom: 0,
                    xp: 0,
                    nxp: 0,
                    nel: 0,
                    ca: 0,
                    p: 0,
                    mg: 0,
                    na: 365,
                    k: 0,
                    rnb: 0,
                    fm: 0.025,
                },
            ],
        },
    ],
};

let ownFodder = JSON.parse(localStorage.getItem("ownFodder"));

if (!ownFodder) {
    localStorage.setItem("ownFodder", JSON.stringify(globalState.ownFodder));
}

globalState.ownFodder = JSON.parse(localStorage.getItem("ownFodder"));

let rations = JSON.parse(localStorage.getItem("rations"));

if (!rations) {
    localStorage.setItem("rations", JSON.stringify(globalState.rations));
}

globalState.rations = JSON.parse(localStorage.getItem("rations"));

function App() {
    useEffect(() => {
        /*
    // Fix: HOTFIX FOR react-scritps bug!!!!!
    window.process = { 
      ...window.process,
    };
    */

        const interval = setInterval(() => {
            localStorage.setItem(
                "ownFodder",
                JSON.stringify(globalState.ownFodder)
            );
            localStorage.setItem(
                "rations",
                JSON.stringify(globalState.rations)
            );
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AppContext.Provider value={globalState}>
                    <div className="App">
                        <Layout />
                    </div>
                </AppContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";
import { isMobile } from "react-device-detect";
import BasicRationtTutorial from "./tutorial/BasicRationtTutorial";
import CompensationTationTutorial from "./tutorial/CompensationTationTutorial";
import PerformancerationTutorial from "./tutorial/performancerationtutorial";
import RationTutorial from "./tutorial/rationtutorial";
import SumRationTutorial from "./tutorial/SumRationTutorial";

function Home(props) {
    const [value, setValue] = React.useState("0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const ResetApplication = () => {
        localStorage.removeItem("ownFodder");
        localStorage.removeItem("rations");
        window.location.reload();
    };

    return (
        <div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            backgroundColor: "#80BA26",
                        }}
                    >
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            orientation={isMobile ? "vertical" : "horizontal"}
                            sx={{
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "#946038",
                                },
                            }}
                        >
                            <Tab
                                label="Anleitung"
                                value="0"
                                sx={{
                                    fontSize: 16,
                                    color: "white",
                                    "&:focus": {
                                        color: "#946038",
                                    },
                                    "&.Mui-selected": {
                                        color: "#946038",
                                    },
                                }}
                            />
                            <Tab
                                label="Abkürzungen"
                                value="1"
                                sx={{
                                    fontSize: 16,
                                    color: "white",
                                    "&:focus": {
                                        color: "#946038",
                                    },
                                    "&.Mui-selected": {
                                        color: "#946038",
                                    },
                                }}
                            />
                            <Tab
                                label="Richtwerte"
                                value="2"
                                sx={{
                                    fontSize: 16,
                                    color: "white",
                                    "&:focus": {
                                        color: "#946038",
                                    },
                                    "&.Mui-selected": {
                                        color: "#946038",
                                    },
                                }}
                            />
                            <Tab
                                label="Einstellungen"
                                value="3"
                                sx={{
                                    fontSize: 16,
                                    color: "white",
                                    "&:focus": {
                                        color: "#946038",
                                    },
                                    "&.Mui-selected": {
                                        color: "#946038",
                                    },
                                }}
                            />
                        </TabList>
                    </Box>

                    <TabPanel value="0" sx={{ padding: 0, paddingTop: "12px" }}>
                        <Card
                            sx={{
                                minHeight: 150,
                                margin: "0px",
                                color: "white",
                                backgroundColor: "#80BA26",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    color="black"
                                >
                                    Schritt 1. Ration anlegen
                                </Typography>
                            </CardContent>
                            <RationTutorial />
                        </Card>

                        <Card
                            sx={{
                                minHeight: 150,
                                marginTop: "10px",
                                color: "white",
                                backgroundColor: "#80BA26",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    color="black"
                                >
                                    Schritt 2. Grundfutter eingeben
                                </Typography>
                            </CardContent>
                            <BasicRationtTutorial />
                        </Card>

                        <Card
                            sx={{
                                minHeight: 150,
                                marginTop: "10px",
                                color: "white",
                                backgroundColor: "#80BA26",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    color="black"
                                >
                                    Schritt 3. Ausgleichsfutter eingeben
                                </Typography>
                            </CardContent>
                            <CompensationTationTutorial />
                        </Card>

                        <Card
                            sx={{
                                minHeight: 150,
                                marginTop: "10px",
                                color: "white",
                                backgroundColor: "#80BA26",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    color="black"
                                >
                                    Schritt 4. Leistungsfutter eingeben
                                </Typography>
                            </CardContent>
                            <PerformancerationTutorial />
                        </Card>

                        <Card
                            sx={{
                                minHeight: 150,
                                marginTop: "10px",
                                color: "white",
                                backgroundColor: "#80BA26",
                            }}
                        >
                            <CardContent>
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    color="black"
                                >
                                    Schritt 5. Gesamtration anzeigen
                                </Typography>
                            </CardContent>
                            <SumRationTutorial />
                        </Card>
                    </TabPanel>

                    <TabPanel value="1" sx={{ padding: 0, paddingTop: "12px" }}>
                        <Paper>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    backgroundColor: "#80BA26",
                                }}
                            >
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    sx={{ padding: 0, paddingTop: "12px" }}
                                >
                                    Abkürzungsverzeichnis
                                </Typography>
                            </Box>

                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow></TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>ADFom</TableCell>
                                            <TableCell>
                                                Acid Detergent Fibre/Säure
                                                Detergentien Faser, aschefreier
                                                Rückstand nach der Behandlung
                                                mit sauren Lösungsmitteln
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>bXS</TableCell>
                                            <TableCell>
                                                beständige Stärke (pansenstabil)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Ca</TableCell>
                                            <TableCell>Kalzium</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Cl</TableCell>
                                            <TableCell>Chlor</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>DCAB</TableCell>
                                            <TableCell>
                                                Dietary Cation Anion Balance /
                                                Kationen-Anionen-Bilanz
                                                energiekorrigierte Milchmenge
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>ECM</TableCell>
                                            <TableCell>
                                                energiekorrigierte Milchmenge
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>EKA</TableCell>
                                            <TableCell>
                                                Erstkalbealter
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>ELOS</TableCell>
                                            <TableCell>
                                                Enzymlösliche Organische
                                                Substanz
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>FM</TableCell>
                                            <TableCell>Frischmasse</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>GB</TableCell>
                                            <TableCell>
                                                Gasbildung nach dem Hohenheimer
                                                Futterwerttest (in 200 mg Futter
                                                TM)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>K</TableCell>
                                            <TableCell>Kalium</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>ME MJ</TableCell>
                                            <TableCell>
                                                Metabolizable Energy /
                                                Umsetzbare Energie in Megajoule
                                                Energiebe wertungsmaßstab für
                                                alle Rinder, außer Milchkühe
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Mg</TableCell>
                                            <TableCell>Magnesium</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>mom.T7</TableCell>
                                            <TableCell>
                                                momentane Tageszunahmen
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>N</TableCell>
                                            <TableCell>Stickstoff</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Na</TableCell>
                                            <TableCell>Natrium</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>aNDFom</TableCell>
                                            <TableCell>
                                                Neutral Detergent Fibre/Neutrale
                                                Detergentien Faser, aschefreier
                                                Rückstand nach der Behandlung
                                                mit neutralen Lösungsmitteln und
                                                Amylase
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>NEL MJ</TableCell>
                                            <TableCell>
                                                Netto-Energie-Laktation in
                                                Megajoule
                                                Energiebewertungsmaßstab für
                                                Milchkühe und Ziegen
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>NFC</TableCell>
                                            <TableCell>
                                                Non Fibre Carbohydrates / Nicht
                                                Faser Kohlehydrate{" "}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>nXP</TableCell>
                                            <TableCell>
                                                nutzbares Rohprotein,
                                                Gesamtmenge des im Dünndarm
                                                verfügbaren Proteins
                                                (Mikrobenprotein + im Pansen
                                                unabgebautes Protein)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>P</TableCell>
                                            <TableCell>Phosphor</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>pabKH</TableCell>
                                            <TableCell>
                                                Pansenabbaubare Kohlenhydrate
                                                (=XZ+XS-bXS) ohne weitere
                                                abbaubare Kohlenhydrate
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>RNB</TableCell>
                                            <TableCell>
                                                Ruminale-Stickstoff-Bilanz,
                                                errechnet aus der Menge von
                                                Rohprotein minus nutzbarem
                                                Protein, geteilt durch 6,25
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>S</TableCell>
                                            <TableCell>Schwefel</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>TM</TableCell>
                                            <TableCell>
                                                Trockenmasse - Anteil im Futter
                                                (Anhaltswerte)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>UDP</TableCell>
                                            <TableCell>
                                                im Pansen unabgebautes Protein
                                                in % des Rohproteins
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>XF</TableCell>
                                            <TableCell>Rohfaser</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>XL</TableCell>
                                            <TableCell>Rohfett</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>XP</TableCell>
                                            <TableCell>Rohprotein</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>XS</TableCell>
                                            <TableCell>Stärke</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>XZ</TableCell>
                                            <TableCell>Zucker</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0, paddingTop: "12px" }}>
                        <Paper>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    backgroundColor: "#80BA26",
                                }}
                            >
                                <Typography
                                    variant="h7"
                                    component="h3"
                                    sx={{ padding: 0, paddingTop: "12px" }}
                                >
                                    Praktische Richtwerte für eine Rinderration{" "}
                                    <br /> aus LfL Gruber Tabelle 47. veränderte
                                    Auflage/Stand 2021
                                </Typography>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow></TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Trockenmasseaufnahme
                                            </TableCell>
                                            <TableCell>
                                                bei Laktierenden ca. 3 % der
                                                Lebendmasse, davon 2/3 aus
                                                Grobfutter; bei Trockenstehern
                                                ca. 1,7% der Lebendmasse
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                Milcherzeugungswert
                                            </TableCell>
                                            <TableCell>
                                                Teilmischration auf Ø
                                                Tagesmilchleistung minus 4 - 6
                                                kg Milch aufwerten nach nXP und
                                                NEL unter Berücksichtigung der
                                                RNB ausgleichen
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                Ruminale Stickstoffbilanz
                                            </TableCell>
                                            <TableCell>
                                                -10 bis 0 g/Tag für Vorbereiter
                                                und Frischlaktierer 0 - 10 g/Tag
                                                für Altmelker und
                                                Frühtrockensteher
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                Strukturgehalt
                                            </TableCell>
                                            <TableCell>
                                                min. 28 % aNDFom aus Grobfutter
                                                in der TM der Gesamtration,
                                                (vorausgesetzt Futtermischwagen,
                                                Homogenität, gleichbleibende
                                                Fütterung, ständige Vorlage,
                                                Tier-/Fressplatzverhältnis von 1
                                                : 1 etc.) entspricht ca. 800
                                                g/100 kg LG
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                pansenabbaubare Kohlenhydrate
                                            </TableCell>
                                            <TableCell>
                                                max. 25 %, aber min. 15 % i. d.
                                                TM in der Hochleistung
                                                (Pansenoptimum bei 19 %)
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                pansenstabile Stärke
                                            </TableCell>
                                            <TableCell>
                                                bei Hochleistenden: min. 3 %,
                                                max. 5 % i. d. TM
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Zucker</TableCell>
                                            <TableCell>
                                                max. 7,5 % i. d. TM, in den
                                                ersten 4 Laktationswochen max.
                                                6,5 %
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                Strukturindex aNDFom
                                            </TableCell>
                                            <TableCell>
                                                mindestens 50 in der
                                                Gesamtration
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Rohfettanteil</TableCell>
                                            <TableCell>
                                                bei langsamer Gewöhnung max. 4 –
                                                4,5 % i. d. TM bzw. 6 % bei
                                                Einsatz von speziell geschütztem
                                                Fett
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                K:Na-Verhältnis
                                            </TableCell>
                                            <TableCell>
                                                bei Laktierenden ca. 12:1; bei
                                                Trockenstehern bis 30:1
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                TM-Gehalt Ration
                                            </TableCell>
                                            <TableCell>
                                                38 - 42 % in der Mischration
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>DCAB</TableCell>
                                            <TableCell>
                                                bei Laktierenden mindestens +
                                                150 meq/kg TM
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Futterrest</TableCell>
                                            <TableCell>
                                                min. 5 % vor nächster
                                                Futtervorlage{" "}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: 0, paddingTop: "12px" }}>
                        <Box>
                            <Card>
                                <CardContent>
                                    <Typography variant="body1" component="h3">
                                        Mit Zurücksetzen wird die ganze Webseite
                                        auf den Ursprungszustand zurückgesetzt.{" "}
                                        <br />
                                        Achtung: Es werden alle erstellten
                                        Rationen und Änderungen gelöscht! <br />
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={ResetApplication}
                                        >
                                            Zurücksetzen
                                        </Button>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}

export default Home;

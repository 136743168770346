
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


Gefuege.propTypes = {

};

function Gefuege(props) {

    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Gefüge:</Typography>
            <div style={{ paddingTop: 10 }}>
                <Typography>Prüfung auf mikrobioelle Zersetzung der Pflanzenteile und Schimmel</Typography>

                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="gefuege-radio-buttons-group-label"
                            name="gefuege"
                            value={values.gefuege}
                            onChange={(e) => handleChange("gefuege", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="Pflanzenteile nicht angegriffen (-0 Punkte)" />
                            <FormControlLabel value="1" control={<Radio color="default"/>} label="Pflanzenteile nur an Schnittstellen leicht angegriffen (-1 Punkte)" />
                            <FormControlLabel value="2" control={<Radio color="default"/>} label="Blätter deutlich angegriffen, schmierig (-2 Punkte)" />
                            <FormControlLabel value="4" control={<Radio color="default"/>} label="Blätter und Halme stark angegriffen, verottet, mistartig (-4 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default Gefuege;
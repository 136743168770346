import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AppBar, Box, Button, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableBody, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import { Food, FoodExtended } from '../../foodcalc/FoodCalc';
import { BlendCalculator } from './blendcalc';
import AddFodderDialogComponent, { IAddFodderDialogState } from '../fodder/addFodderDialog';
import SumBlendComponent from './sumblend';
import { useContext } from 'react';
import AppContext from '../appcontext';


export interface IAppProps {

}

interface IStateType {
  array: FoodExtended[];
}

function Blend(props: IAppProps) {
  const appContext = useContext(AppContext);
  let currentDate = new Date();

  const [tabValue, setTabValue] = React.useState<string>('1');
  const [addFoodDialogProps, setAddFoodDialogProps] = React.useState({ dialogOpen: false } as IAddFodderDialogState);
  const [blendname, setBlendname] = React.useState<string>('Teilration ' + currentDate.getHours() + ':' + currentDate.getMinutes() + ' ' + currentDate.getDate() + '.' + (currentDate.getMonth() + 1) + '.' + currentDate.getFullYear());
  const [foodList, setFoodList] = React.useState<FoodExtended[]>([]);
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [firstRender, setFirstRender] = React.useState(true);

  const calculator = new BlendCalculator(foodList);
  const location = useLocation();

  let array: FoodExtended[] = [];


  if (firstRender) {
    (location.state as IStateType)?.array?.map((food: FoodExtended) => {
      array.push(Object.assign(new FoodExtended(), food));
    });

    if (foodList.length == 0 && array.length > 0) {
      //console.log("initialy setting foodlist");
      setFoodList(array as FoodExtended[]);
    }
    setFirstRender(false);
  }
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  calculator.Blend.Blend = foodList as FoodExtended[];

  calculator.CreateBlend();

  const handleChangeChangeTabValue = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
  };

  const handleAddFoodClick = (event: any) => {
    setAddFoodDialogProps({ dialogOpen: true, food: new FoodExtended() });
  }

  const handleSaveRationClick = (event: any) => {
    setSaveDialogOpen(true);
  }

  const handleSaveDialogClose = (event: any) => {
    calculator.Blend.name = blendname;
    appContext.ownFodder.push(calculator.Blend);
    setSaveDialogOpen(false);
  }

  const handleSaveDialogAbort = (event: any) => {
    setSaveDialogOpen(false);
  }

  const handelTextChange = (event: any) => {
    setBlendname(event.target.value);
  }



  return (
    <div>
      <AddFodderDialogComponent DialogProps={addFoodDialogProps} onCloseDialog={(fodderValues?: FoodExtended, index?: number) => {
        if (fodderValues == null) {
          setAddFoodDialogProps({ dialogOpen: false, food: new FoodExtended() });
          return;
        }

        if (index) {
          foodList[index] = fodderValues;
          setFoodList(foodList);
          setAddFoodDialogProps({ dialogOpen: false, food: new FoodExtended() });
          return;
        }

        setFoodList([...foodList, fodderValues]);
        setAddFoodDialogProps({ dialogOpen: false, food: new FoodExtended() });
      }} />

      <Dialog open={saveDialogOpen} onClose={handleSaveDialogAbort} aria-labelledby="form-dialog-title">
        <Box sx={{
          backgroundColor: '#80BA26',
          width: '100%',
        }}>
          <DialogTitle id="form-dialog-title">Teilmischung speichern</DialogTitle>
        </Box>
        <DialogContent>

        </DialogContent>
        <Box sx={{ margin: 2 }}>
          <TextField sx={{ width: '100%' }} id="outlined-basic" label="Name" variant="outlined" value={blendname} onChange={handelTextChange} />
        </Box>
        <DialogActions>
          <Box sx={{
            backgroundColor: '#80BA26',
            width: '100%',
          }}>
            <Button sx={{
              fontSize: 16,
              fontWeight: 600,
              color: 'white',
            }} color="primary" onClick={handleSaveDialogAbort}>
              Abbrechen
            </Button>
            <Button sx={{
              fontSize: 16,
              fontWeight: 600,
              color: 'white',
            }} color="primary" onClick={handleSaveDialogClose}>
              Speichern
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Box sx={{
        backgroundColor: '#80BA26',
        padding: '5px',
        color: 'black',
      }}>
        <Typography sx={{ color: '#2E2E2E' }} variant="h6" align="left">Teilmischung</Typography>
      </Box>

      <Box sx={{ paddingTop: '10px' }}>
        <TabContext value={tabValue}>
          <AppBar position="static" sx={{
            backgroundColor: '#80BA26',
            color: 'white',
            paddingTop: '10px',
          }}>
            <Box sx={{ alignSelf: 'end' }}>
              <Button sx={{
                fontSize: 16,
                fontWeight: 600,
                color: 'white',
              }}
                onClick={handleAddFoodClick}><AddIcon />Futter hinzufügen</Button>
            </Box>

            <TabList onChange={handleChangeChangeTabValue} aria-label="simple tabs example"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: '#946038',
                },
              }}>
              <Tab label="Nährstoffe" value="1" sx={{
                fontSize: 16,
                color: 'white',
                '&:focus': {
                  color: '#946038',
                },
                '&.Mui-selected': {
                  color: '#946038',
                }
              }} />
              <Tab label="Mineralstoffe" value="2" sx={{
                fontSize: 16,
                color: 'white',
                '&:focus': {
                  color: '#946038',
                },
                '&.Mui-selected': {
                  color: '#946038',
                }
              }} />
            </TabList>
          </AppBar>

          <TabPanel value="1" style={{ padding: 0, paddingTop: '12px' }} >

            <TableContainer component={Paper}>
              <Table aria-label="collapsable table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Futterart</TableCell>
                    <TableCell>FM</TableCell>
                    <TableCell>TM </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FodderTable editRow={(food, index) => { setAddFoodDialogProps({ dialogOpen: true, food: food, edit: { index: index } }) }} />
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ paddingTop: '12px', }}>
              <SumBlendComponent isMinerals={false} SetOpen={false} dialogType={0} foodList={foodList} calculator={calculator} />
            </Box>
          </TabPanel>
          <TabPanel value="2" style={{ padding: 0, paddingTop: '12px' }} >

            <TableContainer component={Paper}>
              <Table aria-label="collapsable table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Futterart</TableCell>
                    <TableCell>FM</TableCell>
                    <TableCell>TM </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FodderTable editRow={(food, index) => { setAddFoodDialogProps({ dialogOpen: true, food: food, edit: { index: index } }) }} />
                </TableBody>
              </Table>
            </TableContainer>


            <Box sx={{ paddingTop: '12px', }}>
              <SumBlendComponent isMinerals={true} SetOpen={false} dialogType={0} foodList={foodList} calculator={calculator} />
            </Box>
          </TabPanel>
        </TabContext>


      </Box>

      <Box sx={{
        backgroundColor: '#80BA26',
        padding: '5px',
        color: 'black',
      }}>

        <Button sx={{
          fontSize: 16,
          fontWeight: 600,
          color: 'white',
        }} disabled={calculator.Blend.Blend.length === 0} onClick={handleSaveRationClick}>Speichern</Button>
      </Box>
    </div>
  );


  function FodderTable(props: { editRow: (row: any, index: any) => void }) {

    var fmAll = 0;
    var tmAll = 0;
    var array = foodList;

    const editFm = (event: any, row: any, index: any) => {
      foodList[index].fm = parseFloat(event.target.value);
      forceUpdate();
    }

    const deleteRow = (e: any, row: any, index: any) => {
      //        var newArray = array.splice(index, 1);
      //        console.log("New array:");
      //        console.log(newArray);
      //        setFoodList(array);
      //        forceUpdate();
      //        
      array = array.filter((item) => item.name !== row.name);
      console.log(array);
      setFoodList(array);
    }

    return (
      <React.Fragment>
        {
          array.map((row, index) => {
            fmAll += row.fm;
            tmAll += row.fm * (row.tm / 1000);
            return <FodderRow
              index={index} key={row.name} row={row} editFm={(event: any, row: any, index: any) => { editFm(event, row, index); }} editRow={() => { props.editRow(row, index) }} deleteRow={(e: any, row: any, index: any) => { deleteRow(e, row, index) }} />
          })
        }

        <TableRow >
          <TableCell>
            Summe
          </TableCell>
          <TableCell />
          <TableCell>{fmAll.toFixed()} kg</TableCell>
          <TableCell>{tmAll.toFixed()} kg</TableCell>
        </TableRow >
      </React.Fragment>
    )
  }



  function FodderRow(props: any) {
    const { row, editFm, editRow, deleteRow, index } = props;

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      editFm(event, row, index);

    }

    const keyDown = (event: any) => {
      event.stopPropagation();
    }

    return (
      <React.Fragment>
        <TableRow >
          <TableCell>
            <IconButton aria-label="edit row" size="small" onClick={(e) => { editRow(e, row, index) }}>
              <EditIcon />
            </IconButton>

            <IconButton aria-label="edit  row" size="small" onClick={(e) => { deleteRow(e, row, index) }}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
          <TableCell >{row.name}</TableCell>
          <TableCell component="th" scope="row">
            <Box alignItems="center"
              justifyContent="flex-start"
              display="flex">
              <TextField type='number' inputProps={{ min: '0', step: "0.1" }} size="small" onChange={(e) => handleFormChange(e)} value={row.fm} sx={{
                marginLeft: '4px',
                marginRight: '4px',
                width: '10ch',
              }} onKeyDown={keyDown} />
              kg
            </Box>
          </TableCell>

          <React.Fragment>
            <TableCell component="th" scope="row">
              {(row.fm * (row.tm / 1000)).toFixed(2)} kg
            </TableCell>
          </React.Fragment>

        </TableRow>
      </React.Fragment>
    );
  }
}









export default Blend;


import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

Farbe.propTypes = {

};

function Farbe(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <div>
                <Typography variant='h6'>Farbe:</Typography>

                <div style={{ paddingTop: 10 }}>
                    <Typography>Prüfung auf Niederschlags- und Hitzeinwirkung sowie Schimmel</Typography>
                    <div style={{ paddingTop: 10 }}>
                        <FormControl fullWidth>
                            <RadioGroup
                                aria-labelledby="farbe-radio-buttons-group-label"
                                name="farbe"
                                value={values.farbe}
                                onChange={(e) => handleChange("farbe", e.target.value)}
                            >
                                <FormControlLabel value="0" control={<Radio color="default"/>} label="einwandfreie grüne Farbe (-0 Punkte)" />
                                <FormControlLabel value="2" control={<Radio color="default"/>} label="ausgeblichen oder schwach gebräunt (-2 Punkte)" />
                                <FormControlLabel value="5" control={<Radio color="default"/>} label="stark ausgeblichen oder stark gebräunt (-5 Punkte)" />
                                <FormControlLabel value="7" control={<Radio color="default"/>} label="sichtbarer Schimmelbefall (-7 Punkte)" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Farbe;
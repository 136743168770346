import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import logoLeistungsfutter from './../../assets/icons/braun/87/icon0006.png';

function PerformancerationTutorial(props) {
    return (
        <div >
            <Paper>
                <img sx={{backgroundColor: '#80BA26',}}  src={logoLeistungsfutter} alt="" style={{float: 'left', marginRight: '10px'}}/>

                <Typography variant="body1" align='left' style={{ padding: 10 }}>
                    Wähle + FUTTER HINZUFÜGEN, um beliebige Kraftfuttermittel zur Ration hinzuzufügen. <br/>
                    Bestätige das Futtermittel mit HINZUFÜGEN <br/>
                    Info:	Hier kannst du auf die gewünschte maximale Milchmenge aufmischen. <br/>
                    MINERALSTOFFE: Die Mineralstoffe auch immer an die maximale Milchmenge anpassen <br/>
                    Mit WEITER kannst du die Gesamtration überprüfen. <br/>
                </Typography>


            </Paper>
        </div>
    );
}

export default PerformancerationTutorial;
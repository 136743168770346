import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

GeruchButter.propTypes = {

};



function GeruchButter(props) {

    const { values, onValueChanged } = props;



    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Geruch:</Typography>
            <div style={{ paddingTop: 10 }}>
                <Typography>Erwärmung (Röstgeruch)</Typography>
                <div style={{ paddingTop: 10 }}>

                    <FormControl fullWidth >

                        <RadioGroup
                            aria-labelledby="erwaermung-radio-buttons-group-label"
                            name="erwaermung"
                            value={values.erwaermung}
                            onChange={(e) => handleChange("erwaermung", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="nicht wahrnehmbar (-0 Punkte)" />
                            <FormControlLabel value="1" control={<Radio color="default"/>} label="schwacher Röstgeruch, angenehm (-1 Punkte)" />
                            <FormControlLabel value="2" control={<Radio color="default"/>} label="deutlicher Röstgeruch, leicht rauchig (-2 Punkte)" />
                            <FormControlLabel value="4" control={<Radio color="default"/>} label="starker Röstgeruch, brandig, unangenehm (-4 Punkte)" />
                        </RadioGroup>


                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default GeruchButter;

import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

Struktur.propTypes = {

};

function Struktur(props) {

    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Struktur:</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Prüfung auf unzweckmäßige mechanische Behandlung</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="struktur-radio-buttons-group-label"
                            name="struktur"
                            value={values.struktur}
                            onChange={(e) => handleChange("struktur", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="weich (Blätter vorhanden) (-0 Punkte)" />
                            <FormControlLabel value="3" control={<Radio color="default"/>} label="blattarm (Blätter noch überwiegen vorhanden) (-3 Punkte)" />
                            <FormControlLabel value="6" control={<Radio color="default"/>} label="sehr blattarm (Blätter nur noch teilweise vorhanden) (-6 Punkte)" />
                            <FormControlLabel value="9" control={<Radio color="default"/>} label="fast nur Stängel, strohartig (-9 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default Struktur;
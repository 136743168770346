
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


function Kolbenanteil(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Kolbenanteil:</Typography>

            <div style={{ paddingTop: 10 }}>
                <FormControl fullWidth style={{ paddingLeft: 8 }}>
                    <RadioGroup
                        aria-labelledby="Kolbenanteil-radio-buttons-group-label"
                        name="Kolbenanteil"
                        value={values.poppetquantity}
                        onChange={(e) => handleChange("poppetquantity", e.target.value)}
                    >
                        <FormControlLabel value="0" control={<Radio color="default" />} label="Niedrig" />
                        <FormControlLabel value="1" control={<Radio color="default" />} label="Mittel" />
                        <FormControlLabel value="2" control={<Radio color="default" />} label="Hoch" />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
}

export default Kolbenanteil;

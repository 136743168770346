import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import GruberTableComponent from '../grubertable/grubertablecomponent';
import OwnFodderComponenet from '../ownfoddercomponent/ownfoddercomponent';
import { Food, FoodExtended } from '../../foodcalc/FoodCalc';
import NumberTextBox from '../controls/NumberTextBox';


export interface IAddFodderDialogProps {
    DialogProps: IAddFodderDialogState;
    onCloseDialog: (food?: FoodExtended, index?: number, addOwnFodder?: boolean) => void;
}

export interface IAddFodderDialogState {
    dialogOpen: boolean;
    food: FoodExtended;
    edit?: {
        index: number;
    }
}

export default function AddFodderDialogComponent(props: IAddFodderDialogProps) {
    const { onCloseDialog } = props;
    const { dialogOpen } = props.DialogProps;

    const [fodderValues, setFodderValues] = React.useState<Food>(new Food());

    const [dialogOpenAddNewFodderValues, setDialogOpenAddNewFodderValues] = React.useState(false);
    const [showGruberTable, setShowGruberTable] = React.useState(false);

    const addToOwnFodder = React.useRef(false);
    const importedFromGrubberTable = React.useRef(false);


    let newFodderValues: Food;


    useEffect(() => {
        if (props.DialogProps.food) {
            setFodderValues(props.DialogProps.food);
        }

    }, [props])

    const handleClickDialogAbortDialog = () => {
        setFodderValues(new Food());
        onCloseDialog();
    }

    const handleClickDialogClose = () => {
        onCloseDialog(fodderValues as FoodExtended, props.DialogProps.edit?.index);
        setFodderValues(new Food());
    }


    const handleClickDialogAbortDialogGruberTable = (event: any) => {
        setShowGruberTable(false);
        setDialogOpenAddNewFodderValues(false);
    }


    const handleClickOpenDialogOwnFodderComponent = () => {
        setShowGruberTable(false);
        setDialogOpenAddNewFodderValues(true)
    }


    const handleClickOpenDialogGruberTable = () => {
        setShowGruberTable(true);
        setDialogOpenAddNewFodderValues(true)
    }

    const handleRowsSelectedDialogAddNewFodderValues = (values: any, finished: any) => {
        newFodderValues = values;

        if (finished) {
            handleAddFodderFromGruberTable();
        }
    }


    const handleAddFodderFromGruberTable = () => {
        addToOwnFodder.current = false;
        importedFromGrubberTable.current = true;

        //setAddToOwnFodder(false);

        setShowGruberTable(false)
        setDialogOpenAddNewFodderValues(false);
        //setGrubValuesEdited(true);
        newFodderValues.fm = 0;

        setFodderValues(newFodderValues);
    }

    return (
        <div>
            <Dialog open={dialogOpen} onClose={handleClickDialogAbortDialog} aria-labelledby="form-dialog-title">
                <DialogTitle sx={{
                    backgroundColor: '#80BA26',
                    width: '100%',
                }} id="form-dialog-title">
                    <Typography variant="h5" >Futter hinzufügen </Typography>
                    <Button sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'white', float: "right"
                    }} onClick={handleClickOpenDialogGruberTable}>
                        Gruber Tabelle
                    </Button>
                    <Button sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'white', float: "right"
                    }} onClick={handleClickOpenDialogOwnFodderComponent}>
                        Eigene Futtermittel
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        overflowY: "auto",
                        height: '550px',
                        overflow: 'auto',

                        '&::-webkit-scrollbar': {
                            '-webkit-appearance': 'none',
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0,0,0,.1)',
                            outline: '1px solid slategrey'
                        }
                    }}>
                        <TextField id="txt_name" variant="outlined" sx={{ margin: 1, }}
                            label="Futterart" onChange={(e) => {

                                let newName: string = e.target.value;

                                if (importedFromGrubberTable.current) {
                                    newName = newName + ' (geändert)';
                                    importedFromGrubberTable.current = false;
                                }


                                setFodderValues({ ...fodderValues, name: newName } as Food);
                            }
                            } autoComplete="off" value={fodderValues.name} />
                            { 
                                fodderValues.hasOwnProperty('fm') &&

                                    <NumberTextBox id="txt_fm" label="FM in kg" variant="outlined" autoComplete="off" sx={{ margin: 1, }} value={fodderValues.fm} newValue={(value) => {
                                        setFodderValues({ ...fodderValues, fm: value } as Food);
                                    }} />
                            }

                        <NumberTextBox id="txt_tm" label="TM g/kg" variant="outlined" autoComplete="off" sx={{ margin: 1, }} value={fodderValues.tm} newValue={(value) => {
                            setFodderValues({ ...fodderValues, tm: value } as Food);
                        }} />

                        <NumberTextBox id="txt_xf" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="XF (Grobf. g)" newValue={(e) => {
                            setFodderValues({ ...fodderValues, xf: e } as Food);
                        }} value={fodderValues.xf} />

                        <NumberTextBox id="txt_andfom" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="aNDFom (Grobf.) g/kg (TM)" newValue={(value) => {
                            fodderValues.andfom = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.andfom} />
                        <NumberTextBox id="txt_xp" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="XP g/kg (TM)" newValue={(value) => {
                            fodderValues.xp = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.xp} />
                        <NumberTextBox id="txt_nxp" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="nXP g/kg (TM)" newValue={(value) => {
                            fodderValues.nxp = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.nxp} />
                        <NumberTextBox id="txt_nel" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="NEL MJ" newValue={(value) => {
                            fodderValues.nel = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.nel} />
                        <NumberTextBox id="txt_ca" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="Ca g/kg (TM)" newValue={(value) => {
                            fodderValues.ca = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.ca} />
                        <NumberTextBox id="txt_p" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="P g/kg (TM)" newValue={(value) => {
                            fodderValues.p = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.p} />
                        <NumberTextBox id="txt_mg" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="Mg g/kg (TM)" newValue={(value) => {
                            fodderValues.mg = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.mg} />
                        <NumberTextBox id="txt_na" variant="outlined" autoComplete="off" sx={{ margin: 1, }} label="Na g/kg (TM)" newValue={(value) => {
                            fodderValues.na = value
                            setFodderValues(fodderValues);
                        }} value={fodderValues.na} />
                        <NumberTextBox id="txt_k" variant="outlined" sx={{ margin: 1, }} label="K g/kg (TM)" newValue={(value) => {
                            fodderValues.k = value;
                            setFodderValues(fodderValues);
                        }} value={fodderValues.k} />
                    </Box>
                </DialogContent>
                <DialogActions sx={{
                    backgroundColor: '#80BA26',
                    width: '100%',
                }}>

                    <Button sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'white',
                    }} onClick={handleClickDialogAbortDialog}>
                        Abbrechen
                    </Button>
                    {
                        props.DialogProps.edit ?
                            <Button sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: 'white',
                            }} onClick={handleClickDialogClose}>
                                Speichern
                            </Button>
                            :
                            <Button sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: 'white',
                            }} onClick={handleClickDialogClose}>
                                Hinzufügen
                            </Button>
                    }
                </DialogActions>
            </Dialog>


            <Dialog open={dialogOpenAddNewFodderValues} onClose={handleClickDialogAbortDialogGruberTable} aria-labelledby="form-dialog-title" fullScreen={false} maxWidth="xl">
                <DialogTitle sx={{
                    backgroundColor: '#80BA26',
                    width: '100%',
                }} id="form-dialog-title">
                    {showGruberTable ?
                        <Typography sx={{ color: '#2E2E2E' }} variant="h6" align="left">Gruber Tabelle</Typography>
                        :
                        <Typography sx={{ color: '#2E2E2E' }} variant="h6" align="left">Eigene Futtermittel</Typography>
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {showGruberTable ?
                            <GruberTableComponent onRowsSelected={(rows: any, finished: any) => handleRowsSelectedDialogAddNewFodderValues(rows, finished)} selectable='true' />
                            :
                            <OwnFodderComponenet onRowsSelected={(rows: any, finished: any) => handleRowsSelectedDialogAddNewFodderValues(rows, finished)} selectable='true' />
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    backgroundColor: '#80BA26',
                    width: '100%',
                }}>
                    <Button sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'white',
                    }} color="primary" onClick={handleClickDialogAbortDialogGruberTable}>
                        Abbrechen
                    </Button>
                    <Button sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'white',
                    }} color="primary" onClick={handleAddFodderFromGruberTable}>
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


/*
                        <TextField id="txt_fm" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="FM in kg" onChange={(e) => {
                                setFodderValues({ ...fodderValues, fm: parseFloat(e.target.value) } as Food);
                            }
                            } value={fodderValues.fm} />
                        <TextField id="txt_tm" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="TM g/kg" onChange={(e) => {
                                setFodderValues({ ...fodderValues, tm: parseFloat(e.target.value) } as Food);
                            }
                            } value={fodderValues.tm} />
                        <TextField id="txt_xf" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="XF (Grobf. g)" onChange={(e) => {
                                setFodderValues({ ...fodderValues, xf: parseFloat(e.target.value) } as Food);
                            }} value={fodderValues.xf} />
                        <TextField id="txt_andfom" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="aNDFom (Grobf.) g/kg (TM)" onChange={(e) => {
                                fodderValues.andfom = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.andfom} />
                        <TextField id="txt_xp" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="XP g/kg (TM)" onChange={(e) => {
                                fodderValues.xp = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.xp} />
                        <TextField id="txt_nxp" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="nXP g/kg (TM)" onChange={(e) => {
                                fodderValues.nxp = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.nxp} />
                        <TextField id="txt_nel" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="NEL MJ" onChange={(e) => {
                                fodderValues.nel = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.nel} />
                        <TextField id="txt_ca" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="Ca g/kg (TM)" onChange={(e) => {
                                fodderValues.ca = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.ca} />
                        <TextField id="txt_p" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="P g/kg (TM)" onChange={(e) => {
                                fodderValues.p = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.p} />
                        <TextField id="txt_mg" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="Mg g/kg (TM)" onChange={(e) => {
                                fodderValues.mg = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.mg} />
                        <TextField id="txt_na" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                            label="Na g/kg (TM)" onChange={(e) => {
                                fodderValues.na = parseFloat(e.target.value)
                                setFodderValues(fodderValues);
                            }} value={fodderValues.na} />
                        <TextField id="txt_k" type='number' inputProps={{ min: '0' }} variant="outlined" sx={{ margin: 1, }}
                            label="K g/kg (TM)" onChange={(e) => {
                                fodderValues.k = parseFloat(e.target.value);
                                setFodderValues(fodderValues);
                            }} value={fodderValues.k} />
*/

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "primeicons/primeicons.css";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { useContext } from "react";
import { FoodExtended } from "../../foodcalc/FoodCalc";
import AddFodderDialogComponent from "../fodder/addFodderDialog";
import AppContext from "./../appcontext";
import "./../css/dataTable.css";
import { useNavigate } from "react-router-dom";


function OwnFodderComponenet(props) {
    const { onRowsSelected, isAddable, selectable } = props;
    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const [selectedFodder, setSelectedFodder] = React.useState(null);

    const [addFoodDialogProps, setAddFoodDialogProps] = React.useState({
        dialogOpen: false,
        food: new FoodExtended(),
    });

    // Hack
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    function handleOnCloseAddFodderDialog(food, index) {
        if (food && index && index >= 0) {
            appContext.ownFodder[index] = food;
        } else if (food) {
            appContext.ownFodder.push(food);
        }

        setAddFoodDialogProps({ dialogOpen: false });
    }

    const handleClickOpenDialog = (event, row, index) => {
        if (row && index >= 0) {
            if (row.Blend && row.Blend.length > 0) {
                navigate("/teilmischung", {
                    replace: true,
                    state: { array: row.Blend },
                });
                return;
            } else {
                setAddFoodDialogProps({
                    dialogOpen: true,
                    food: row,
                    edit: { index: index },
                });}

        } else {
            setAddFoodDialogProps({ dialogOpen: true });
        }
    };

    const RowsSelected = (param) => {
        setSelectedFodder(param);

        if (onRowsSelected) {
            onRowsSelected(param);
        }
    };

    const actionBodyTemplate = (rowData, rowInfo) => {
        return (
            <React.Fragment>
                <div style={{ display: "flex" }}>
                    <IconButton
                        aria-label="edit row"
                        size="small"
                        onClick={(e) => {
                            handleClickOpenDialog(e, rowData, rowInfo.rowIndex);
                        }}
                    >
                        <EditIcon />
                    </IconButton>

                    <IconButton
                        aria-label="delete row"
                        size="small"
                        onClick={(e) => {
                            deleteFodder(rowInfo.rowIndex);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </React.Fragment>
        );
    };

    const deleteFodder = (index) => {
        appContext.ownFodder.splice(index, 1);
        forceUpdate();
    };

    const onRowDoubleClick = (param) => {
        setSelectedFodder(param);

        if (onRowsSelected) {
            onRowsSelected(param, true);
        }
    };

    const rnbTemplate = (rowData, rowInfo) => {
        return ((rowData.xp - rowData.nxp) / 6.25).toFixed(2);
    };

    const toFixedTemplate = (rowData, rowInfo, field) => {
        if (rowData[field] % 1 !== 0) {
            return rowData[field].toFixed(2);
        }

        return rowData[field];
    };


    return (
        <div>
            {isAddable && (
                <AddFodderDialogComponent
                    DialogProps={addFoodDialogProps}
                    onCloseDialog={handleOnCloseAddFodderDialog}
                />
            )}
            {isAddable && (
                <React.Fragment>
                    <Box
                        sx={{
                            backgroundColor: "#80BA26",
                            padding: "5px",
                            color: "black",
                            minHeight: "85px",
                        }}
                        style={{ minWidth: "1000px" }}
                    >
                        <Typography variant="h6">
                            Eigene Futtermittel
                        </Typography>
                    </Box>

                    <Box sx={{ padding: "10px" }} />
                </React.Fragment>
            )}
            {isAddable && (
                <Box
                    sx={{
                        backgroundColor: "#80BA26",
                        display: "flex",
                        padding: "5px",
                        color: "black",
                        minHeight: "85px",
                        minWidth: "1000px",
                    }}
                >
                    <Button
                        color="primary"
                        onClick={handleClickOpenDialog}
                        sx={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "white",
                            marginLeft: "auto",
                        }}
                    >
                        <AddIcon />
                        Futter hinzufügen
                    </Button>
                </Box>
            )}
            <Box sx={{ padding: "10px" }} />
            <Box sx={{ paddingTop: "0px" }}>
                {selectable ? (
                    <DataTable
                        value={appContext.ownFodder}
                        onSelectionChange={(e) => RowsSelected(e.value)}
                        selectionMode="single"
                        selection={selectedFodder}
                        responsiveLayout="scroll"
                        onRowDoubleClick={(e) => onRowDoubleClick(e.data)}
                        style={{ minWidth: "1000px" }}
                    >
                        <Column
                            field="name"
                            header="Name"
                            sortable
                            filter
                            filterMatchMode="contains"
                            filterPlaceholder="Suche"
                        ></Column>
                        <Column field="tm" header="TM" sortable></Column>
                        <Column field="rnb" header="RNB" sortable></Column>
                        <Column field="xf" header="XF" sortable></Column>
                        <Column field="andfom" header="aNDF" sortable></Column>
                        <Column field="xp" header="XP" sortable></Column>
                        <Column field="nxp" header="nXP" sortable></Column>
                        <Column field="nel" header="NEL" sortable></Column>
                        <Column field="ca" header="Ca" sortable></Column>
                        <Column field="p" header="P" sortable></Column>
                        <Column field="mg" header="Mg" sortable></Column>
                        <Column field="na" header="Na" sortable></Column>
                        <Column field="k" header="K" sortable></Column>
                    </DataTable>
                ) : (
                        <DataTable
                            value={appContext.ownFodder}
                            responsiveLayout="scroll"
                            style={{ minWidth: "1000px" }}
                        >
                            <Column headerStyle={{ width: "4rem" }} body={actionBodyTemplate} />
                            <Column
                                field="name"
                                header="Name"
                                sortable
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Suche"
                                style={{ width: "35%" }}
                            ></Column>
                            <Column
                                field="tm"
                                header="TM"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "tm"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                header="RNB"
                                body={rnbTemplate}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="xf"
                                header="XF"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "xf"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="andfom"
                                header="aNDF"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "andfom"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="xp"
                                header="XP"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "xp"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="nxp"
                                header="nXP"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "nxp"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="nel"
                                header="NEL"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "nel"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="ca"
                                header="Ca"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "ca"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="p"
                                header="P"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "p"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="mg"
                                header="Mg"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "mg"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="na"
                                header="Na"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "na"); }}
                                style={{ width: "10%" }}
                            ></Column>
                            <Column
                                field="k"
                                header="K"
                                sortable
                                body={(rowData, rowInfo) => { return toFixedTemplate(rowData, rowInfo, "k"); }}
                                style={{ width: "10%" }}
                            ></Column>
                        </DataTable>
                    )}
            </Box>
        </div>
    );
}

export default OwnFodderComponenet;

import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import logoRationen from './../../assets/icons/braun/87/icon0001.png';

function RationTutorial(props) {
    return (
        <div >
            <Paper style={{ backgroundColor: '' }}>
                <img sx={{backgroundColor: '#80BA26',}}  src={logoRationen} alt="" style={{float: 'left', marginRight: '10px'}}/>
                
                <Typography variant="body1" align='left' style={{ padding: 10}}>
                    Wähle + RATION HINZUFÜGEN fülle die Felder aus und bestätige mit HINZUFÜGEN<br />
                    Info:	bei Teil TMR kannst du die gewünschte Milchmenge der Mischwagenration eingeben<br />
                    Bei Voll TMR ist die Milchmenge der Gesamtration, also mit Leistungskraftfutter an der Futterstation gemeint.<br />
                    Mit WEITER kommst du zur Grundfuttereingabe<br />

                </Typography>


            </Paper>
        </div>
    );
}

export default RationTutorial;
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';


function TMGehalt(props) {

    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>TM-Gehalt:</Typography>

            <Box sx={{'text-align': 'left'}}>
                <ul>
                    <li>Soviel Maissilage in die Faust füllen, <br></br> dass die Faust nicht ganz geschlossen ist und ein fingerbreiter Spalt bleibt.</li>
                    <li>einmal kräftig drücken, nicht mehrfach pumpen</li>
                    <li>beobachten, ob und wie die Feuchtigkeit zwischen den Fingern hervortritt</li>
                </ul>
            </Box>

            <div style={{ paddingTop: 10 }}>
                <FormControl fullWidth style={{ paddingLeft: 8 }}>

                    <RadioGroup
                        aria-labelledby="tmpercent-radio-buttons-group-label"
                        name="tmpercent"
                        value={values.tmpercent}
                        onChange={(e) => handleChange("tmpercent", e.target.value)}
                    >
                        <FormControlLabel value="1" control={<Radio color="default" />} label="Schnelles und starkes Tropfen (&lt;28%)" />
                        <FormControlLabel value="2" control={<Radio color="default" />} label="Leichtes und langsames Tropfen(30%)" />
                        <FormControlLabel value="3" control={<Radio color="default" />} label="Kein Tropfen mehr, aber Feuchtigkeit zwischen den Fingern (32% - 33%)" />
                        <FormControlLabel value="4" control={<Radio color="default" />} label="Keine Feuchtigkeit zwischen den Fingern sichtbar, Hände werden aber feucht. noch Feuchtegefühl (34% - 35%)" />
                        <FormControlLabel value="5" control={<Radio color="default" />} label="Kein Feuchtegefühl mehr (&gt;36%)" />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
}

export default TMGehalt;
import { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AppContext from './../../appcontext';
import SumTable from './Views/SumTable';

function RationTable(props) {
    const { rationIndex, onEditRow, Editable, DialogType } = props;
    const appContext = useContext(AppContext);

    let tm = 0.0;
    let fm = 0.0;
    let fmCow = 0.0;

    const editRow = (event) => {
        if (onEditRow) {
            onEditRow(event);
        }
    }

    let sum = (item) => {
        fm += item.fm;
        fmCow += item.fm * appContext.rations[rationIndex].cowNumber;
        tm += item.fm * (item.tm / 1000)
    }

    appContext.rations[rationIndex].basicRation.forEach(item => {
        sum(item);
    })

    appContext.rations[rationIndex].compensationRation.forEach(item => {
        sum(item);
    })

    appContext.rations[rationIndex].performanceRation.forEach(item => {
        sum(item);
    })

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="collapsable table">
                    <TableHead>
                        <TableRow>
                            {Editable ?
                                <TableCell />
                                :
                                ""
                            }
                            <TableCell>Futterart</TableCell>

                            <TableCell>FM</TableCell>
                            <TableCell>TM</TableCell>
                            <TableCell>FM für {appContext.rations[rationIndex].cowNumber} Kühe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            DialogType.map(val => {
                                return <SumTable Editable={Editable} RationIndex={rationIndex} SetDialogType={val} onEditRow={editRow} />
                            })
                        }

                        <TableRow>
                            {Editable ?
                                <TableCell />
                                :
                                ""
                            }
                            <TableCell>Summe</TableCell>

                            <TableCell>{fm.toFixed(2)} kg</TableCell>
                            <TableCell>{tm.toFixed(2)} kg</TableCell>
                            <TableCell>{fmCow.toFixed(2)} kg</TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        </div>
    );
}

export default RationTable;
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';




function Verschmutzung(props) {
    const { onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };




    return (
        <div>
            <Typography variant='h6'>Verschmutzung:</Typography>
            
            <FormGroup>
                <FormControlLabel control={
                    <Checkbox
                        onChange={(e) => e.target.checked ? handleChange('schimmel', true) : handleChange('schimmel', false)} />
                } label="Sichtbarer Schimmelbefall: Silage nicht verfüttern!  (-7 Punkte)" />
                
                <FormControlLabel control={
                    <Checkbox

                        onChange={(e) => e.target.checked ? handleChange('verschmutzung', 0.3) : handleChange('verschmutzung', 0)} />
                } label="Verschmutzung (-0.3 MJ)" />
            </FormGroup>
        </div>
    );
}

export default Verschmutzung;
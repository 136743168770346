import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React, { useContext } from "react";
import {
    BrowserView,
    isBrowser,
    isMobile,
    MobileView,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { FoodCalculator, FoodExtended } from "../../foodcalc/FoodCalc";
import {
    ParseAlertsBasicFodderNutrients,
    ParseAlertsCompenstationFodderNutrients,
    ParseAlertsPerformanceFodderMinerals,
    ParseAlertsPerformanceFodderNutrients,
} from "../../alertparser/alertparser";
import AppContext from "../appcontext";
import CowPopOver from "../cowpopovercomponent";
import DialogType from "./dialogType";
import SumBasicRation from "./SumBasicRation";
import SumCompensationRation from "./SumCompenstationRation";
import SumPerformanceRation from "./SumPerformanceRation";
import NumberTextBox from "../controls/NumberTextBox";
import AddFodderDialogComponent from "./addFodderDialog";

export default function FodderComponent(props) {
    const { dialogName, dialogType } = props;
    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const [addFoodDialogProps, setAddFoodDialogProps] = React.useState({
        dialogOpen: false,
        food: new FoodExtended(),
    });

    const [value, setValue] = React.useState("1");

    const calculator = FoodCalculator.getInstance();

    // Hack
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    let rationIndex = -1;
    let warningMessages = [];

    // Search Active Ration
    appContext.rations.forEach((ration, index) => {
        if (ration.active) {
            rationIndex = index;
            return;
        }
    });

    if (rationIndex === -1) {
        return (
            <React.Fragment>
                <div>Bitte erst Ration anlegen!</div>
            </React.Fragment>
        );
    }

    calculator.PrepareNewCalculation(
        appContext.rations[rationIndex].cowWeight,
        appContext.rations[rationIndex].performanceMilkProductionKg,
        appContext.rations[rationIndex].milkFatPercent,
        appContext.rations[rationIndex].proteinPercent
    );

    calculator.AddBasicRationArray(appContext.rations[rationIndex].basicRation);
    calculator.AddCompensationRationArray(
        appContext.rations[rationIndex].compensationRation
    );
    calculator.AddPerformanceRationArray(
        appContext.rations[rationIndex].performanceRation
    );

    calculator.CalculateRation();

    if (dialogType === DialogType.BasicRation) {
        warningMessages = ParseAlertsBasicFodderNutrients({
            tm: calculator.sumBasicRation.tm,
            cowWeight: appContext.rations[rationIndex].cowWeight,
            andf: calculator.andfomBasicRationPercentage / 100,
            xf: calculator.xfBasicRationPercentage / 100,
        });
    }

    if (dialogType === DialogType.CompensationRation) {
        let minValMilkInKg = Math.min(
            ...[
                calculator.milkInKgCompensationRation.xp,
                calculator.milkInKgCompensationRation.nxp,
            ]
        );
        warningMessages = ParseAlertsCompenstationFodderNutrients({
            tm: calculator.sumCompensationRation.tm,
            nxpMilkKg: calculator.milkInKgCompensationRation.nxp,
            nelMilkKg: calculator.milkInKgCompensationRation.nel,
            expectedMilkKg:
                appContext.rations[rationIndex].compensationMilkProductionKg,
            actualMilkKg: minValMilkInKg,
            cowWeight: appContext.rations[rationIndex].cowWeight,
            xf: calculator.xfCompensationRationPercentage / 100,
            andf: calculator.andfomCompensationRationPercentage / 100,
            rnb: calculator.sumCompensationRation.rnb,
        });
    }

    if (dialogType === DialogType.PerformanceRation) {
        let minValMilkInKg = Math.min(
            ...[
                calculator.milkInKgPerformanceRation.xp,
                calculator.milkInKgPerformanceRation.nxp,
            ]
        );
        warningMessages = ParseAlertsPerformanceFodderNutrients({
            tm: calculator.sumPerformanceRation.tm,
            nxpMilkKg: calculator.milkInKgPerformanceRation.nxp,
            nelMilkKg: calculator.milkInKgPerformanceRation.nel,
            expectedMilkKg:
                appContext.rations[rationIndex].performanceMilkProductionKg,
            actualMilkKg: minValMilkInKg,
            cowWeight: appContext.rations[rationIndex].cowWeight,
            xf: calculator.xfPerformanceRationPercentage / 100,
            andf: calculator.andfomPerformanceRationPercentage / 100,
            rnb: calculator.sumPerformanceRation.rnb,
        });
        warningMessages = warningMessages.concat(
            ParseAlertsPerformanceFodderMinerals({
                tm: calculator.sumPerformanceRation.tm,
                cowWeight: appContext.rations[rationIndex].cowWeight,
                expectedMilkKg:
                    appContext.rations[rationIndex].performanceMilkProductionKg,
                actualMilkKg: minValMilkInKg,
                xf: calculator.xfPerformanceRationPercentage / 100,
                andf: calculator.andfomPerformanceRationPercentage / 100,
                rnb: calculator.sumPerformanceRation.rnb,
                p: calculator.milkInKgPerformanceRation.p,
                mg: calculator.milkInKgPerformanceRation.mg,
                na: calculator.milkInKgPerformanceRation.na,
                k: calculator.milkInKgPerformanceRation.k,
                ca: calculator.milkInKgPerformanceRation.ca,
                vollTmr:
                    appContext.rations[rationIndex].performanceMilkProductionKg,
            })
        );
    }

    const nextPage = () => {
        switch (dialogType) {
            case DialogType.BasicRation:
                navigate("/ausgleichsfutter");
                return;
            case DialogType.CompensationRation:
                navigate("/leistungsfutter");
                return;
            case DialogType.PerformanceRation:
                navigate("/gesamtration");
                return;
            default:
                return "";
        }
    };

    const previousPage = () => {
        switch (dialogType) {
            case DialogType.BasicRation:
                navigate("/ration");
                return;
            case DialogType.CompensationRation:
                navigate("/grundfutter");
                return;
            case DialogType.PerformanceRation:
                navigate("/ausgleichsfutter");
                return;
            default:
                return "";
        }
    };

    function handleClickOpenAddFodderDialog(event) {
        setAddFoodDialogProps({ dialogOpen: true });
    }

    function handleOnCloseAddFodderDialog(food, index) {
        if (food !== null && food !== undefined) {
            if (index !== null && index !== undefined && index >= 0) {
                if (dialogType === DialogType.BasicRation) {
                    appContext.rations[rationIndex].basicRation[index] = food;
                } else if (dialogType === DialogType.CompensationRation) {
                    appContext.rations[rationIndex].compensationRation[index] =
                        food;
                } else if (dialogType === DialogType.PerformanceRation) {
                    appContext.rations[rationIndex].performanceRation[index] =
                        food;
                }
            } else {
                if (dialogType === DialogType.BasicRation) {
                    appContext.rations[rationIndex].basicRation = [
                        ...appContext.rations[rationIndex].basicRation,
                        food,
                    ];
                } else if (dialogType === DialogType.CompensationRation) {
                    appContext.rations[rationIndex].compensationRation = [
                        ...appContext.rations[rationIndex].compensationRation,
                        food,
                    ];
                } else if (dialogType === DialogType.PerformanceRation) {
                    appContext.rations[rationIndex].performanceRation = [
                        ...appContext.rations[rationIndex].performanceRation,
                        food,
                    ];
                }
            }
        }

        // Todo: When do we add it to own fodder?
        /*
        if (addToOwnFodder || grubValuesEdited) {
            appContext.ownFodder = [
                ...appContext.ownFodder,
                fodderValues.values,
            ];
        }
        */

        setAddFoodDialogProps({ dialogOpen: false });
    }

    const handleClickBlendRation = (event) => {
        var array;
        if (dialogType === DialogType.CompensationRation) {
            array = calculator.compensationRation;
        } else if (dialogType === DialogType.PerformanceRation) {
            array = calculator.performanceRation;
        }
        
        navigate("/teilmischung", { replace: true, state: { array: array } });
        return;
    };

    const editRow = (event, row, index) => {
        if (row.Blend && row.Blend.length > 0) {
            navigate("/teilmischung", {
                replace: true,
                state: { array: row.Blend },
            });
            return;
        }

        setAddFoodDialogProps({
            dialogOpen: true,
            food: row,
            edit: { index: index },
        });
    };

    const editFm = (fm, row, index) => {
        if (dialogType === DialogType.BasicRation) {
            appContext.rations[rationIndex].basicRation[index].fm =
                parseFloat(fm);
        } else if (dialogType === DialogType.CompensationRation) {
            appContext.rations[rationIndex].compensationRation[index].fm =
                parseFloat(fm);
        } else if (dialogType === DialogType.PerformanceRation) {
            appContext.rations[rationIndex].performanceRation[index].fm =
                parseFloat(fm);
        }

        forceUpdate();
    };

    const deleteRow = (event, row, index) => {
        if (dialogType === DialogType.BasicRation) {
            appContext.rations[rationIndex].basicRation = appContext.rations[
                rationIndex
            ].basicRation.filter((item) => item.name !== row.name);
        } else if (dialogType === DialogType.CompensationRation) {
            appContext.rations[rationIndex].compensationRation =
                appContext.rations[rationIndex].compensationRation.filter(
                    (item) => item.name !== row.name
                );
        } else if (dialogType === DialogType.PerformanceRation) {
            appContext.rations[rationIndex].performanceRation =
                appContext.rations[rationIndex].performanceRation.filter(
                    (item) => item.name !== row.name
                );
        }

        forceUpdate();
    };

    const FoodTable = () => {
        var fmAll = 0;
        var array = [];

        if (dialogType === DialogType.BasicRation) {
            array = calculator.basicRation;
        } else if (dialogType === DialogType.CompensationRation) {
            array = calculator.compensationRation;
        } else if (dialogType === DialogType.PerformanceRation) {
            array = calculator.performanceRation;
        }

        return (
            <React.Fragment>
                {array.map((row, index) => {
                    fmAll += row.fm * appContext.rations[rationIndex].cowNumber;
                    row.cowNumber = appContext.rations[rationIndex].cowNumber;
                    return (
                        <Row
                            index={index}
                            key={row.name}
                            row={row}
                            newFm={editFm}
                            editRow={editRow}
                            deleteRow={deleteRow}
                        />
                    );
                })}

                <TableRow>
                    <TableCell>Summe</TableCell>
                    <TableCell></TableCell>
                    <TableCell component="th" scope="row">
                        {calculator.sumBasicRation.fm.toFixed()} kg
                        <MobileView>
                            {fmAll.toFixed()} kg
                            <br />
                            {calculator.sumBasicRation.tm.toFixed(2)} kg
                        </MobileView>
                    </TableCell>
                    {isMobile ? null : (
                        <TableCell>{fmAll.toFixed()} kg</TableCell>
                    )}

                    {isMobile ? null : (
                        <TableCell component="th" scope="row">
                            {calculator.sumBasicRation.tm.toFixed(2)} kg
                        </TableCell>
                    )}
                </TableRow>
            </React.Fragment>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div>
                <AddFodderDialogComponent
                    DialogProps={addFoodDialogProps}
                    onCloseDialog={handleOnCloseAddFodderDialog}
                />
            </div>
            <Box
                sx={{
                    backgroundColor: "#80BA26",
                    padding: "5px",
                    color: "black",
                }}
            >
                <Typography sx={{ color: "#2E2E2E" }} variant="h5" align="left">
                    {dialogName}{" "}
                    {isBrowser
                        ? "(" + appContext.rations[rationIndex].name + ")"
                        : ""}
                </Typography>
                <br />

                {dialogType === DialogType.BasicRation ? (
                    <Typography variant="h6" align="left">
                        Grundfuttermittel hinzufügen.
                    </Typography>
                ) : null}
                {dialogType === DialogType.CompensationRation ? (
                    <Typography variant="h6" align="left">
                        RNB Wert ausgleichen und auf Teil-TMR aufmischen!
                    </Typography>
                ) : null}
                {dialogType === DialogType.PerformanceRation ? (
                    <Typography variant="h6" align="left">
                        Kraftfutter an maximale Milchleistung anpassen.
                    </Typography>
                ) : null}
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
                <TabContext value={value}>
                    <AppBar
                        position="static"
                        sx={{
                            backgroundColor: "#80BA26",
                            color: "white",
                            paddingTop: "10px",
                        }}
                    >
                        <Box sx={{ alignContent: "end" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Button
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: "white",
                                        alignSelf: "end",
                                    }}
                                    onClick={handleClickOpenAddFodderDialog}
                                >
                                    <AddIcon />
                                    Futter hinzufügen
                                </Button>

                                {dialogType !== DialogType.BasicRation ? (
                                    <Button
                                    sx={{
                                        fontSize: 16,
                                            fontWeight: 600,
                                            color: "white",
                                            alignSelf: "end",
                                    }}
                                    disabled={appContext.rations[rationIndex].basicRation.length === 0}
                                    onClick={handleClickBlendRation}
                                    >
                                    <AddIcon />
                                    Teilmischung
                                    </Button>
                                ) : null}
                            </Box>
                        </Box>

                        <TabList
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            sx={{
                                "& .MuiTabs-indicator": {
                                    backgroundColor: "#946038",
                                },
                            }}
                        >
                            <Tab
                                label="Nährstoffe"
                                value="1"
                                sx={{
                                    fontSize: 16,
                                    color: "white",
                                    "&:focus": {
                                        color: "#946038",
                                    },
                                    "&.Mui-selected": {
                                        color: "#946038",
                                    },
                                }}
                            />
                            <Tab
                                label="Mineralstoffe"
                                value="2"
                                sx={{
                                    fontSize: 16,
                                    color: "white",
                                    "&:focus": {
                                        color: "#946038",
                                    },
                                    "&.Mui-selected": {
                                        color: "#946038",
                                    },
                                }}
                            />
                        </TabList>
                    </AppBar>

                    <TabPanel
                        value="1"
                        style={{ padding: 0, paddingTop: "12px" }}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Futterart</TableCell>

                                        {isMobile ? null : (
                                            <TableCell>FM pro Kuh </TableCell>
                                        )}

                                        {isMobile ? (
                                            <TableCell>
                                                FM pro Kuh
                                                <br /> FM für{" "}
                                                {
                                                    appContext.rations[
                                                        rationIndex
                                                    ].cowNumber
                                                }{" "}
                                                Kühe <br /> TM
                                            </TableCell>
                                        ) : null}

                                        {isMobile ? null : (
                                            <TableCell>
                                                FM für{" "}
                                                {
                                                    appContext.rations[
                                                        rationIndex
                                                    ].cowNumber
                                                }{" "}
                                                Kühe
                                            </TableCell>
                                        )}

                                        {isMobile ? null : (
                                            <TableCell>TM </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>{FoodTable()}</TableBody>
                            </Table>
                        </TableContainer>

                        <Box sx={{ paddingTop: "12px" }}>
                            <SumBasicRation
                                dialogType={dialogType}
                                ration={appContext.rations[rationIndex]}
                            />
                        </Box>

                        <Box sx={{ paddingTop: "12px" }}>
                            <SumCompensationRation
                                dialogType={dialogType}
                                ration={appContext.rations[rationIndex]}
                            />
                        </Box>

                        <Box sx={{ paddingTop: "12px" }}>
                            <SumPerformanceRation
                                dialogType={dialogType}
                                ration={appContext.rations[rationIndex]}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel
                        value="2"
                        style={{ padding: 0, paddingTop: "12px" }}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Futterart</TableCell>

                                        <BrowserView>
                                            <TableCell>FM pro Kuh </TableCell>
                                        </BrowserView>

                                        <MobileView>
                                            <TableCell>
                                                FM pro Kuh
                                                <br /> FM für{" "}
                                                {
                                                    appContext.rations[
                                                        rationIndex
                                                    ].cowNumber
                                                }{" "}
                                                Kühe <br /> TM
                                            </TableCell>
                                        </MobileView>

                                        {isMobile ? null : (
                                            <TableCell>
                                                FM für{" "}
                                                {
                                                    appContext.rations[
                                                        rationIndex
                                                    ].cowNumber
                                                }{" "}
                                                Kühe
                                            </TableCell>
                                        )}

                                        <BrowserView>
                                            <TableCell>TM </TableCell>
                                        </BrowserView>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{FoodTable()}</TableBody>
                            </Table>
                        </TableContainer>

                        <Box sx={{ paddingTop: "12px" }}>
                            <SumBasicRation
                                dialogType={dialogType}
                                ration={appContext.rations[rationIndex]}
                                isMinerals="true"
                            />
                        </Box>

                        <Box sx={{ paddingTop: "12px" }}>
                            <SumCompensationRation
                                dialogType={dialogType}
                                ration={appContext.rations[rationIndex]}
                                isMinerals="true"
                            />
                        </Box>

                        <Box sx={{ paddingTop: "12px" }}>
                            <SumPerformanceRation
                                dialogType={dialogType}
                                ration={appContext.rations[rationIndex]}
                                isMinerals="true"
                            />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
            <Box sx={{ paddingTop: "10px" }} />
            <Box sx={{ backgroundColor: "#80BA26", overflow: "hidden" }}>
                <Button
                    sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "white",
                        float: "left",
                    }}
                    onClick={previousPage}
                >
                    Zurück
                </Button>
                <Button
                    sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "white",
                        float: "right",
                    }}
                    onClick={nextPage}
                >
                    Weiter
                </Button>

                <Typography
                    variant="h7"
                    align="right"
                    sx={{ color: "#2E2E2E" }}
                >
                    {isMobile ? dialogName : null}
                </Typography>
            </Box>
            <CowPopOver warnings={warningMessages} />
        </div>
    );
}

function Row(props) {
    const { row, newFm, editRow, deleteRow, index } = props;
    //const [fm, setFm] = React.useState(row.fm);

    function onFmChange(fm) {
        newFm(fm, row, index);
    }
    /*
    const handleFormChange = (event) => {
        const re = /^\d*(\.|\,)?\d*$/;

        if (event.target.value === "" || re.test(event.target.value)) {
            setFm(event.target.value);
        }
    };

    const handleOnBlur = (event) => {
        if (fm === "") {
            setFm(0);
            newFm(0, row, index);
        } else {
            newFm(fm, row, index);
        }
    };

    const keyDown = (event) => {
        event.stopPropagation();

        if (event.key === "Enter") {
            if (fm === "") {
                setFm(0);
            } else {
                handleOnBlur(event);
            }
        }
    };
    */

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="edit row"
                        size="small"
                        onClick={(e) => {
                            editRow(e, row, index);
                        }}
                    >
                        <EditIcon />
                    </IconButton>

                    <IconButton
                        aria-label="edit  row"
                        size="small"
                        onClick={(e) => {
                            deleteRow(e, row, index);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell component="th" scope="row">
                    <Box
                        alignItems="center"
                        justifyContent="flex-start"
                        display="flex"
                    >
                        <NumberTextBox
                            size="small"
                            value={row.fm}
                            newValue={onFmChange}
                            sx={{
                                marginLeft: "4px",
                                marginRight: "4px",
                                width: "10ch",
                            }}
                        />
                        kg
                    </Box>
                    {isMobile ? (
                        <React.Fragment>
                            {(row.fm * row.cowNumber).toFixed(2)} kg
                            <br />
                            {(row.fm * (row.tm / 1000)).toFixed(2)} kg
                        </React.Fragment>
                    ) : null}
                </TableCell>

                {isMobile ? null : (
                    <React.Fragment>
                        <TableCell>
                            {(row.fm * row.cowNumber).toFixed(2)} kg
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {(row.fm * (row.tm / 1000)).toFixed(2)} kg
                        </TableCell>
                    </React.Fragment>
                )}
            </TableRow>
        </React.Fragment>
    );
}

import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


function Final(props) {
    const { values } = props;

    return (
        <div>
            <Typography variant='h6'>Gesamtbewertung:</Typography>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Punkte Abzug</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Farbe
                            </TableCell>
                            <TableCell align="right">{values.farbe} </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">
                                Geruch
                            </TableCell>
                            <TableCell align="right">{values.geruch}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">
                                Struktur
                            </TableCell>
                            <TableCell align="right">{values.struktur}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row">
                                Verschmutzung
                            </TableCell>
                            <TableCell align="right">{values.verschmutzung}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>

            <Typography sx={{ paddingTop: 4 }}>Energiegehalt im Heu NEL {values.realEnergy().toFixed(2)} MJ / {values.energy().toFixed(2)} MJ</Typography>

        </div>
    );
}

export default Final;
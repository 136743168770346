import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
    createTheme
} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppRouter from './../router/approuter';
import BaySgLogo from './logos/Logo_White.png';

import logoRationenSelected from './../assets/icons/braun/29/icon0001.png';
import logoRationen from './../assets/icons/weiß/29/icon0001.png';

import logoFuttermittel from './../assets/icons/weiß/29/icon0013.png';

import logoAusgleichsfutterSelected from './../assets/icons/braun/29/icon0003.png';
import logoAusgleichsfutter from './../assets/icons/weiß/29/icon0003.png';

import logoLeistungsfutterSelected from './../assets/icons/braun/29/icon0006.png';
import logoLeistungsfutter from './../assets/icons/weiß/29/icon0006.png';

import logoGesamrationSelected from './../assets/icons/braun/29/icon0002.png';
import logoGesamration from './../assets/icons/weiß/29/icon0002.png';

import logoGrundfutterSelected from './../assets/icons/braun/29/icon0011.png';
import logoGrundfutter from './../assets/icons/weiß/29/icon0011.png';

import logoEigeneFuttermittelSelected from './../assets/icons/braun/29/icon0012.png';
import logoEigeneFuttermittel from './../assets/icons/weiß/29/icon0012.png';

import logoGruberTabelleSelected from './../assets/icons/braun/29/icon0016.png';
import logoGruberTabelle from './../assets/icons/weiß/29/icon0016.png';

import logoFutterbewertungSelected from './../assets/icons/braun/29/icon0010.png';
import logoFutterbewertung from './../assets/icons/weiß/29/icon0010.png';

import HelpIcon from '@mui/icons-material/Help';
import MenuItem from './MenuItem';

/*
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
*/
import { Container } from '@mui/material';

import { useLocation } from 'react-router-dom';



const drawerWidth = 240;


function Layout(props) {
    const { window } = props;

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#404040'
            },
            button: {
                main: '#f3e5f5'
            }
        }
    });

    let location = useLocation();

    useEffect(() => {
        setSelectedIndex(location.pathname)
    }, [location])

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(MenuItem.Rations);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    }

    const drawer = (
        <div>
            <Box sx={{ paddingLeft: 1, paddingTop: 1 }}>
                <img src={BaySgLogo} alt="Logo" height='60px' />
            </Box>
            <Box sx={{
                backgroundColor: '#946038',
                minHeight: '10px',
                paddingTop: 1
            }} />


            <List sx={{
                color: 'white',
                '& .Mui-selected': {
                    color: '#946038',
                },
            }}>
                <ListItem button component={Link} to="/hilfe" selected={selectedIndex === MenuItem.Help} onClick={event => handleListItemClick(event, MenuItem.Help)}>
                    <HelpIcon sx={{ paddingLeft: theme.spacing(0), }} />
                    <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Hilfe </ListItemText>
                </ListItem>
                <Divider variant='middle' />
                <ListItem button component={Link} to="/ration" selected={selectedIndex === MenuItem.Rations} onClick={event => handleListItemClick(event, MenuItem.Rations)}>
                    <img src={selectedIndex === MenuItem.Rations ? logoRationenSelected : logoRationen} alt="" />
                    <ListItemText>Rationen  </ListItemText>
                </ListItem>
                <div>
                    <ListItem button component={Link} to="/grundfutter" selected={selectedIndex === MenuItem.BasicRation} onClick={event => handleListItemClick(event, MenuItem.BasicRation)} >
                        <img src={selectedIndex === MenuItem.BasicRation ? logoGrundfutterSelected : logoGrundfutter} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Grundfutter </ListItemText>
                    </ListItem>

                    <ListItem button component={Link} to="/ausgleichsfutter" selected={selectedIndex === MenuItem.CompensationRation} onClick={event => handleListItemClick(event, MenuItem.CompensationRation)}>
                        <img src={selectedIndex === MenuItem.CompensationRation ? logoAusgleichsfutterSelected : logoAusgleichsfutter} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Ausgleichsfutter</ListItemText>
                    </ListItem>

                    <ListItem button component={Link} to="/leistungsfutter" selected={selectedIndex === MenuItem.PerformanceRation} onClick={event => handleListItemClick(event, MenuItem.PerformanceRation)}>
                        <img src={selectedIndex === MenuItem.PerformanceRation ? logoLeistungsfutterSelected : logoLeistungsfutter} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Leistungsfutter</ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/gesamtration" selected={selectedIndex === MenuItem.Analysis} onClick={event => handleListItemClick(event, MenuItem.Analysis)}>
                        <img src={selectedIndex === MenuItem.Analysis ? logoGesamrationSelected : logoGesamration} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Gesamtration</ListItemText>
                    </ListItem>
                </div>
                <Divider />
                <div>
                <ListItem button component={Link} to="/teilmischung" selected={selectedIndex === MenuItem.Blend} onClick={event => handleListItemClick(event, MenuItem.Blend)}>
                        
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Teilmischung </ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/eigenefuttermittel" selected={selectedIndex === MenuItem.OwnFodder} onClick={event => handleListItemClick(event, MenuItem.OwnFodder)}>
                        <img src={selectedIndex === MenuItem.OwnFodder ? logoEigeneFuttermittelSelected : logoEigeneFuttermittel} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Eigene Futtermittel </ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/grubertabelle" selected={selectedIndex === MenuItem.GruberTable} onClick={event => handleListItemClick(event, MenuItem.GruberTable)}>
                        <img src={selectedIndex === MenuItem.GruberTable ? logoGruberTabelleSelected : logoGruberTabelle} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Gruber Tabelle </ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/futterbewertung" selected={selectedIndex === MenuItem.FodderAnalysis} onClick={event => handleListItemClick(event, MenuItem.FodderAnalysis)}>
                        <img src={selectedIndex === MenuItem.FodderAnalysis ? logoFutterbewertungSelected : logoFutterbewertung} alt="" />
                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Futterbewertung </ListItemText>
                    </ListItem>
                    {
                        /*
                    <ListItem button component={Link} to="/webfulab" selected={selectedIndex === MenuItem.WebFuLab} onClick={event => handleListItemClick(event, MenuItem.WebFuLab)}>

                        <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>WebFuLab </ListItemText>
                    </ListItem>
                    */
                    }
                </div>
                <Divider />
                <ListItem button component={Link} to="/impressum" selected={selectedIndex === MenuItem.Impressum} onClick={event => handleListItemClick(event, MenuItem.Impressum)}>
                    <ListItemText sx={{ paddingLeft: theme.spacing(0), }}>Impressum</ListItemText>
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{
            display: 'flex',
            backgroundColor: "#404040"
        }}>
            <Container>
                <AppBar position="fixed" sx={{
                    [theme.breakpoints.up('md')]: {
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: drawerWidth,
                        backgroundColor: "#80BA26"
                    },

                    backgroundColor: "#80BA26"
                }} >
                    <Toolbar sx={{ paddingBottom: 2.5 }} >

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{
                                marginRight: theme.spacing(2),
                                [theme.breakpoints.up('md')]: {
                                    display: 'none',
                                },
                            }}
                            size="large">
                            <MenuIcon />
                        </IconButton>

                        <Box sx={{ width: '100%', display: 'flex', 'justifyContent': 'center', paddingTop: 1 }} >
                            <img src={logoFuttermittel} alt="" />
                            <Button sx={{
                                color: 'white',
                                'textTransform': 'none'
                            }} component={Link} to={'/ration'}>
                                <Typography variant="h5" noWrap>
                                    BayFeedsApp
                                </Typography>
                            </Button>
                        </Box>
                    </Toolbar>
                    <Box sx={{
                        backgroundColor: '#946038',
                        minHeight: '10px',
                    }}>
                    </Box>
                </AppBar>

                {
                    /* 
                    nav:
                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            width: drawerWidth,
                                            flexShrink: 0,
                                        },
                                    }} 
                    */
                }
                <nav>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="js">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            sx={{
                                '& .MuiDrawer-paper': {
                                    width: `${drawerWidth}px`,
                                    backgroundColor: "#80BA26",
                                },
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown implementation="js">
                        <Drawer
                            sx={{
                                '& .MuiDrawer-paper': {
                                    width: `${drawerWidth}px`,
                                    backgroundColor: "#80BA26",
                                },
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>

                </nav>

                <div>
                    <main>
                        <Box sx={{
                            [theme.breakpoints.up('md')]: {
                                flexGrow: 1,
                                paddingTop: theme.spacing(9),
                                paddingLeft: `${drawerWidth}px`,
                                maxWidth: '100%'
                            },
                            flexGrow: 1,
                            paddingTop: theme.spacing(9),
                            maxWidth: '100%'
                        }}>
                            <AppRouter />
                        </Box>
                    </main>

                </div>

            </Container>
        </Box>
    );
}

/*
const useStyles = makeStyles((theme) => ({
    div_appBarSeperator: {
        backgroundColor: '#946038',
        minHeight: 10,

    },
    root: {
        display: 'flex',
        backgroundColor: "#404040"
    },
    text: {
        color: "black",
    },
    bottomNavigation: {
        position: "absolute",
        width: "200px",
        bottom: "0px",
        right: "25%",
        left: "50%",
        marginLeft: "-150px",
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            backgroundColor: "#80BA26"
        },

        backgroundColor: "#80BA26"
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#80BA26",

    },
    button: {
        color: 'white',
        'text-transform': 'none'
    },

    content: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 1,
            paddingTop: theme.spacing(9),
            paddingLeft: drawerWidth,
            maxWidth: '100%'
        },
        flexGrow: 1,
        paddingTop: theme.spacing(9),
        maxWidth: '100%'
    },

    listItem: {
        color: 'white',
        '& .Mui-selected': {
            color: '#946038',
        },
    },
    nested: {
        paddingLeft: theme.spacing(0),
    },
    start: {
        paddingLeft: theme.spacing(0),

    }
}));
*/
export default Layout;
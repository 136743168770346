import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import grubJson from './grub.json';
import './../css/dataTable.css';
import Box from '@mui/material/Box';

function GruberTableComponent(props) {
  const { onRowsSelected, selectable } = props

  const [selectedProduct1, setSelectedProduct1] = React.useState(null);

  const [loading, setLoading] = React.useState(true);
  const [grubData, setGrubData] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    setGrubData(grubJson);
    setLoading(false);

  }, [])

  const RowsSelected = (param) => {
    setSelectedProduct1(param)

    if (onRowsSelected) {
      onRowsSelected(param, false);
    }
  }

  const onRowDoubleClick = (param) => {
    setSelectedProduct1(param)

    if (onRowsSelected) {
      onRowsSelected(param, true);
    }
  }

  return (
    <React.Fragment>
      {
        <Box sx={{ paddingTop: 0 }}>
          {
            selectable ? (
              <DataTable value={grubJson}
                className="p-datatable-lg"
                scrollable
                scrollDirection="both"
                scrollHeight="50vh"
                filterDisplay="row"
                loading={loading}
                dataKey="id"
                style={{ minWidth: '1000px' }}
                onSelectionChange={e => RowsSelected(e.value)}
                onRowDoubleClick={e => onRowDoubleClick(e.data)}
                selectionMode="single"
                selection={selectedProduct1}
              >
                <Column field="name" header="Name" style={{ width: '180px' }} sortable filter filterMatchMode="contains" filterPlaceholder="Suche"></Column>
                <Column field="tm" header="TM" style={{ width: '50px' }} sortable></Column>
                <Column field="rnb" header="RNB" style={{ width: '50px' }} sortable></Column>
                <Column field="xf" header="XF" style={{ width: '50px' }} sortable></Column>
                <Column field="andfom" header="aNDF" style={{ width: '50px' }} sortable></Column>
                <Column field="xp" header="XP" style={{ width: '50px' }} sortable></Column>
                <Column field="nxp" header="nXP" style={{ width: '50px' }} sortable></Column>
                <Column field="nel" header="NEL" style={{ width: '50px' }} sortable></Column>
                <Column field="ca" header="Ca" style={{ width: '50px' }} sortable></Column>
                <Column field="p" header="P" style={{ width: '50px' }} sortable></Column>
                <Column field="mg" header="Mg" style={{ width: '50px' }} sortable></Column>
                <Column field="na" header="Na" style={{ width: '50px' }} sortable></Column>
                <Column field="k" header="K" style={{ width: '50px' }} sortable></Column>
              </DataTable>
            )
              :
              (
                <React.Fragment>
                  <Box sx={{
                    backgroundColor: '#80BA26',
                    padding: '5px',
                    color: 'black',
                    minHeight: '85px'
                  }} style={{ minWidth: '1000px' }} >
                    <Typography variant="h6">Gruber Tabelle</Typography>
                  </Box>

                  <Box sx={{ padding: '10px' }} />

                  <DataTable value={grubData}
                    className="p-datatable-lg"
                    scrollable
                    scrollDirection="both"
                    scrollHeight="50vh"
                    filterDisplay="row"
                    loading={loading}
                    dataKey="id"
                    style={{ minWidth: '1000px' }}
                  >
                    <Column field="name" header="Name" style={{ width: '180px' }} sortable filter filterMatchMode="contains" filterPlaceholder="Suche"></Column>
                    <Column field="tm" header="TM" style={{ width: '50px' }} sortable></Column>
                    <Column field="rnb" header="RNB" style={{ width: '50px' }} sortable></Column>
                    <Column field="xf" header="XF" style={{ width: '50px' }} sortable></Column>
                    <Column field="andfom" header="aNDF" style={{ width: '50px' }} sortable></Column>
                    <Column field="xp" header="XP" style={{ width: '50px' }} sortable></Column>
                    <Column field="nxp" header="nXP" style={{ width: '50px' }} sortable></Column>
                    <Column field="nel" header="NEL" style={{ width: '50px' }} sortable></Column>
                    <Column field="ca" header="Ca" style={{ width: '50px' }} sortable></Column>
                    <Column field="p" header="P" style={{ width: '50px' }} sortable></Column>
                    <Column field="mg" header="Mg" style={{ width: '50px' }} sortable></Column>
                    <Column field="na" header="Na" style={{ width: '50px' }} sortable></Column>
                    <Column field="k" header="K" style={{ width: '50px' }} sortable></Column>
                  </DataTable>
                </React.Fragment>
              )
          }

        </Box>
      }
    </React.Fragment>
  )
}


export default GruberTableComponent;

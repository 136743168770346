import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


function PHWert(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    const GetPhValues = () => {
        switch (values.tmpercent) {
            case 0:
                return (
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="Ph-radio-buttons-group-label"
                            name="Ph"
                            value={values.ph}
                            onChange={(e) => handleChange("ph", e.target.value)}
                        >
                            <FormControlLabel value="-1" control={<Radio color="default" />} label="Nicht beurteilen" />
                            <FormControlLabel value="0" control={<Radio color="default" />} label="< 4,2" />
                            <FormControlLabel value="1" control={<Radio color="default" />} label="4,2" />
                            <FormControlLabel value="2" control={<Radio color="default" />} label="4,6" />
                            <FormControlLabel value="3" control={<Radio color="default" />} label="5,0" />
                            <FormControlLabel value="4" control={<Radio color="default" />} label="5,4" />
                            <FormControlLabel value="5" control={<Radio color="default" />} label="> 5,4" />

                        </RadioGroup>
                    </FormControl>
                )
            case 1:
            case 2:
                return (
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="Ph-radio-buttons-group-label"
                            name="Ph"
                            value={values.ph}
                            onChange={(e) => handleChange("ph", e.target.value)}
                        >
                            <FormControlLabel value="-1" control={<Radio color="default" />} label="Nicht beurteilen" />
                            <FormControlLabel value="0" control={<Radio color="default" />} label="< 4,4" />
                            <FormControlLabel value="1" control={<Radio color="default" />} label="4,4" />
                            <FormControlLabel value="2" control={<Radio color="default" />} label="4,8" />
                            <FormControlLabel value="3" control={<Radio color="default" />} label="5,2" />
                            <FormControlLabel value="4" control={<Radio color="default" />} label="5,6" />
                            <FormControlLabel value="5" control={<Radio color="default" />} label="> 5,6" />

                        </RadioGroup>
                    </FormControl>
                )
            case 3:
            case 4:
            case 5:
                return (
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="Ph-radio-buttons-group-label"
                            name="Ph"
                            value={values.ph}
                            onChange={(e) => handleChange("ph", e.target.value)}
                        >
                            <FormControlLabel value="-1" control={<Radio color="default" />} label="Nicht beurteilen" />
                            <FormControlLabel value="0" control={<Radio color="default" />} label="< 4,6" />
                            <FormControlLabel value="1" control={<Radio color="default" />} label="4,6" />
                            <FormControlLabel value="2" control={<Radio color="default" />} label="5,0" />
                            <FormControlLabel value="3" control={<Radio color="default" />} label="5,4" />
                            <FormControlLabel value="4" control={<Radio color="default" />} label="5,8" />
                            <FormControlLabel value="5" control={<Radio color="default" />} label="> 5,8" />

                        </RadioGroup>
                    </FormControl>
                )
            case 6:
                return (
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="Ph-radio-buttons-group-label"
                            name="Ph"
                            value={values.ph}
                            onChange={(e) => handleChange("ph", e.target.value)}
                        >
                            <FormControlLabel value="-1" control={<Radio color="default" />} label="Nicht beurteilen" />
                            <FormControlLabel value="0" control={<Radio color="default" />} label="< 4,8" />
                            <FormControlLabel value="1" control={<Radio color="default" />} label="4,8" />
                            <FormControlLabel value="2" control={<Radio color="default" />} label="5,2" />
                            <FormControlLabel value="3" control={<Radio color="default" />} label="5,6" />
                            <FormControlLabel value="4" control={<Radio color="default" />} label="6,0" />
                            <FormControlLabel value="5" control={<Radio color="default" />} label="> 6,0" />

                        </RadioGroup>
                    </FormControl>
                )
            default:
                return;
        }

    }

    return (
        <div>
            <Typography variant='h6'>PH Wert:</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Die Silagenbeurteilung ist auch ohne Bestimmung des pH-Wertes möglich.</Typography>
                <div style={{ paddingTop: 10 }}>

                    {
                        GetPhValues(values)
                    }

                </div>
            </div>
        </div>
    );
}




export default PHWert;
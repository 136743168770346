import React, { useContext } from 'react';
import StepWizard from "react-step-wizard";

import { default as EnergieKonzentrationGrassilage } from './steps/grassilage/energiekonzentration';
import { default as EnergieKonzentrationAufwuchsGrassilage } from './steps/grassilage/energiekonzentrationaufwuchs';
import { default as FarbeBraeunungGrassilage } from './steps/grassilage/farbe_braeunung';
import { default as FarbeSonstigesGrassilage } from './steps/grassilage/farbe_sonstiges';
import { default as FarbeVergilbungGrassilage } from './steps/grassilage/farbe_vergilbung';

import { default as GeruchButterGrassilage } from './steps/grassilage/geruch_butter';
import { default as GeruchEssigGrassilage } from './steps/grassilage/geruch_essig';
import { default as GeruchErwaermungGrassilage } from './steps/grassilage/geruch_erwaermung';
import { default as GeruchHefenGrassilage } from './steps/grassilage/geruch_hefen';
import { default as GeruchSchimmelGrassilage } from './steps/grassilage/geruch_schimmel';
import { default as VerschmutzungGrassilage } from './steps/grassilage/verschmutzung';
import { default as GefuegeGrassilage } from './steps/grassilage/gefuege';
import { default as TMGehaltGrassilage } from './steps/grassilage/tmgehalt';
import { default as StartGrassilage } from './steps/grassilage/start';
import { default as FinalGrassilage } from './steps/grassilage/final';

import { default as FarbeHeu } from './steps/heu/farbe';
import { default as GeruchHeu } from './steps/heu/geruch';
import { default as VerschmutzungHeu } from './steps/heu/verschmutzung';
import { default as StrukturHeu } from './steps/heu/struktur';
import { default as StartHeu } from './steps/heu/start';
import { default as EnergieKonzentrationHeu } from './steps/heu/energiekonzentration';
import { default as EnergieKonzentrationAufwuchsHeu } from './steps/heu/energiekonzentrationaufwuchs';
import { default as FinalHeu } from './steps/heu/final';

import { default as FarbeMaissilage } from './steps/maissilage/farbe';
import { default as GeruchMaissilage } from './steps/maissilage/geruch';
import { default as VerschmutzungMaissilage } from './steps/maissilage/verschmutzung';
import { default as GefuegeMaissilage } from './steps/maissilage/gefuege';
import { default as TMGehaltMaissilage } from './steps/maissilage/tmgehalt';
import { default as StartMaissilage } from './steps/maissilage/start';
import { default as FinalMaissilage } from './steps/maissilage/final';

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import transitions from './transitions.less';
import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';

import AppContext from './../appcontext'
import Initial from './steps/Inital';
import PHWert from './steps/grassilage/phwert';
import Kolbenanteil from './steps/maissilage/kolbenanteil';
import Box from '@mui/material/Box';


function FodderAnalysisComponent(props) {
  const appContext = useContext(AppContext);

  const [silage, setSilage] = React.useState(
    {
      // Grassilage
      grassilage: {
        aufwuchs: 0,

        tmpercent: 0,
        energyNumber: 0,

        ph: -1,

        butter: 0,
        essig: 0,
        erwaermung: 0,
        hefen: 0,
        schimmel: 0,
        // Farbe
        braeunung: 0,
        vergilbung: 0,
        farbe_sonstiges: 0,
        // Gefüge
        gefuege: 0,
        // Wringprobe
        TmGehalt: 0.2,
        //
        verschmutzung: 0,

        energy: function () {
          switch (this.energyNumber) {
            case 0:
              return 7.1;
            case 1:
              return 6.8;
            case 2:
              return 6.4;
            case 3:
              return 6.0;
            case 4:
              return 5.7;
            case 5:
              return 6.5;
            case 6:
              return 6.3;
            case 7:
              return 6.0;
            case 8:
              return 5.7;
            default:
          }
        },

        tm: function () {
          switch (this.tmpercent) {
            case 0:
              return 0.2 * 1000;
            case 1:
              return 0.25 * 1000;
            case 2:
              return 0.3 * 1000;
            case 3:
              return 0.35 * 1000;
            case 4:
              return 0.4 * 1000;
            case 5:
              return 0.45 * 1000;
            case 6:
              return 0.5 * 1000;
            default:
          }
        },

        sonstiges: function () {
          switch (this.farbe_sonstiges) {
            case 1:
              return 7;
            case 2:
              return 7;
            default:
              return 0;
          }
        },

        realEnergy: function () {
          let grade = this.butter + this.essig + this.erwaermung + this.hefen + this.schimmel + this.braeunung + this.vergilbung + this.sonstiges() + this.gefuege;

          if (this.ph !== -1) {
            grade += this.ph;
          }

          let quality = 0.2;

          if (this.ph === -1) {
            if (grade > 0 && grade <= 1) {
              quality = 0.2;
            } else if (grade >= 2 && grade <= 3) {
              quality = 0.3;
            } else if (grade >= 4 && grade <= 5) {
              quality = 0.4;
            } else if (grade >= 6 && grade <= 8) {
              quality = 0.5;
            } else if (grade > 8) {
              quality = 0.6;
            }
          } else {
            if (grade > 0 && grade <= 2) {
              quality = 0.2;
            } else if (grade >= 3 && grade <= 5) {
              quality = 0.3;
            } else if (grade >= 6 && grade <= 8) {
              quality = 0.4;
            } else if (grade >= 9 && grade <= 11) {
              quality = 0.5;
            } else if (grade > 11) {
              quality = 0.6;
            }
          }

          return this.energy() - quality - this.verschmutzung;
        },
      },

      heu: {
        aufwuchs: 0,
        energyNumber: 0,
        farbe: 0,
        geruch: 0,
        struktur: 0,
        verschmutzung: 0,

        energy: function () {
          switch (this.energyNumber) {
            case 0:
              return 7.1;
            case 1:
              return 6.8;
            case 2:
              return 6.4;
            case 3:
              return 6.0;
            case 4:
              return 5.7;
            case 5:
              return 6.5;
            case 6:
              return 6.3;
            case 7:
              return 6.0;
            case 8:
              return 5.7;
            default:
          }
        },

        tm: function () {
          return 860;
        },

        realEnergy: function () {
          let grade = this.farbe + this.geruch + this.struktur;

          let quality = 0.2;


          if (grade >= 0 && grade <= 1) {
            quality = 0;
          } else if (grade >= 2 && grade <= 3) {
            quality = 0.1;
          } else if (grade >= 4 && grade <= 5) {
            quality = 0.3;
          } else if (grade >= 6 && grade <= 8) {
            quality = 0.5;
          } else if (grade > 8) {
            quality = 0.7;
          }

          return this.energy() - quality - this.verschmutzung;
        },
      },

      maissilage: {
        tmpercent: 1,
        geruch: 0,
        gefuege: 0,
        farbe: 0,
        schimmel: false,
        verschmutzung: 0,
        poppetquantity: 1,

        tm: function () {
          switch (this.tmpercent) {
            case 0:
              return 0.28 * 1000;
            case 1:
              return 0.30 * 1000;
            case 2:
              return 0.32 * 1000;
            case 3:
              return 0.34 * 1000;
            case 4:
              return 0.36 * 1000;
            default:
          }
        },

        energy: function () {
          if (this.poppetquantity === 0) {
            if (this.tmpercent === 1) {
              return 6.0;
            }
            if (this.tmpercent === 2) {
              return 6.3;
            }
            if (this.tmpercent === 3) {
              return 6.4;
            }
            if (this.tmpercent === 4) {
              return 6.5;
            }
            if (this.tmpercent === 5) {
              return 6.5;
            }
          }

          if (this.poppetquantity === 1) {
            if (this.tmpercent === 1) {
              return 6.3;
            }
            if (this.tmpercent === 2) {
              return 6.5;
            }
            if (this.tmpercent === 3) {
              return 6.6;
            }
            if (this.tmpercent === 4) {
              return 6.7;
            }
            if (this.tmpercent === 5) {
              return 6.8;
            }
          }
          if (this.poppetquantity === 2) {
            if (this.tmpercent === 1) {
              return 6.6;
            }
            if (this.tmpercent === 2) {
              return 6.7;
            }
            if (this.tmpercent === 3) {
              return 6.9;
            }
            if (this.tmpercent === 4) {
              return 6.9;
            }
            if (this.tmpercent === 5) {
              return 6.9;
            }
          }
        },

        realEnergy: function () {
          let grade = this.geruch + this.gefuege + this.farbe + (this.schimmel ? 7 : 0);


          let quality = 0.2;
          if (grade > 0 && grade <= 2) {
            quality = 0.2;
          } else if (grade > 2 && grade <= 5) {
            quality = 0.3;
          } else if (grade > 5 && grade <= 8) {
            quality = 0.4;
          } else if (grade > 8 && grade <= 11) {
            quality = 0.5;
          } else if (grade > 11) {
            quality = 0.6;
          }

          return this.energy() - quality - this.verschmutzung;
        },
      }
    }
  );

  function energyNumberToString(energyNumber) {
    switch (energyNumber) {
      default:
        return "Blattstadium";
      case 1:
        return "Schossen";
      case 2:
        return "Ähren-, Rispenschieben";
      case 3:
        return "Beginn Blüte";
      case 4:
        return "Ende Blüte";
      case 5:
        return "Blattstadium <4 Wo";
      case 6:
        return "Schossen 4-5 Wo";
      case 7:
        return "Ähren-, Rispenschieben 6-8 Wo";
      case 8:
        return "Beginn Blüte >8 Wo";
    }
  }



  const initialState = { values: { fm: 0, name: "", tm: 0, xf: 0, andfom: 0, xp: 0, nxp: 0, nel: 0, ca: 0, p: 0, mg: 0, na: 0, k: 0 }, index: -1 };

  const [dialogType, setDialogType] = React.useState(0);
  const [fodderValues, setFodderValues] = React.useState(initialState);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  var dialogName = 'Grasssilage';
  var energy;

  switch (dialogType) {
    case 1:
      dialogName = 'Heu';
      energy = silage.heu.realEnergy().toFixed(1);
      break;
    case 2:
      dialogName = 'Maissilage';
      energy = silage.maissilage.realEnergy().toFixed(1);
      break;
    default:
      dialogName = 'Grassilage';
      energy = silage.grassilage.realEnergy().toFixed(1);
      break;
  }

  const handleFormChange = (event, text, value) => {
    setFodderValues({ ...fodderValues, values: { ...fodderValues.values, [text]: value } });
  }

  const [state, updateState] = React.useState({
    form: {},
    transitions: {
      enterRight: `${transitions.animated} ${transitions.enterRight}`,
      enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
      exitRight: `${transitions.animated} ${transitions.exitRight}`,
      exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
      intro: `${transitions.animated} ${transitions.intro}`,
    },
    demo: true, // uncomment to see more
  });

  const { SW } = state;

  const setInstance = SW => updateState({
    ...state,
    SW,
  });



  const newValues = (key, value) => {
        console.log(key, value);
    value = Number.parseFloat(value);

    if (dialogType === 0) {
      if (key === 'aufwuchs' && value === 1) {
        setSilage({ ...silage, grassilage: { ...silage.grassilage, [key]: value, energyNumber: 5 } });
        return;
      } else if (key === 'aufwuchs' && value === 0) {
        setSilage({ ...silage, grassilage: { ...silage.grassilage, [key]: value, energyNumber: 0 } });
        return;
      } else {
        setSilage({ ...silage, grassilage: { ...silage.grassilage, [key]: value } });
        return;
      }
    }

    if (dialogType === 1) {
      if (key === 'aufwuchs' && value === 1) {
        setSilage({ ...silage, heu: { ...silage.heu, [key]: value, energyNumber: 5 } });
        return;
      } else if (key === 'aufwuchs' && value === 0) {
        setSilage({ ...silage, heu: { ...silage.heu, [key]: value, energyNumber: 0 } });
        return;
      } else {
        setSilage({ ...silage, heu: { ...silage.heu, [key]: value } });
        return;
      }
    }

    if (dialogType === 2) {
      setSilage({ ...silage, maissilage: { ...silage.maissilage, [key]: value } });
      return;
    }
  }


  const InstanceControls = ({ SW }) => {
    let currentStep;
    let totalSteps;
    if (state.hasOwnProperty('SW') && state.SW.hasOwnProperty('state')) {
      currentStep = state.SW.state.activeStep;
      totalSteps = state.SW.totalSteps;
    }

    return (
      <React.Fragment>
        <Box sx={{ backgroundColor: '#80BA26', overflow: 'hidden',}}>
          <Button sx={{
            fontSize: 16,
            fontWeight: 600,
            color: 'white',
            float: 'left',
            
          }} disabled={currentStep === 0} onClick={() => SW.previousStep()}>Zurück</Button>
          {totalSteps - 1 === currentStep ?
            <Button sx={{
              fontSize: 16,
              fontWeight: 600,
              color: "white",
              float: "right",
            }} onClick={() => {

              var now = new Date().toISOString().split('.')[0].split('T');

              if (dialogType === 0) {
                setFodderValues({ ...fodderValues, values: { ...fodderValues.values, name: `Grassilage Bewertung (${energyNumberToString(silage.grassilage.energyNumber)}) ${now[0]} ${now[1]}`, nel: silage.grassilage.realEnergy().toFixed(2), tm: silage.grassilage.tm() } });
              }
              if (dialogType === 1) {
                setFodderValues({ ...fodderValues, values: { ...fodderValues.values, name: `Heu Bewertung (${energyNumberToString(silage.heu.energyNumber)}) ${now[0]} ${now[1]}`, nel: silage.heu.realEnergy().toFixed(2), tm: silage.heu.tm() } });
              }
              if (dialogType === 2) {
                setFodderValues({ ...fodderValues, values: { ...fodderValues.values, name: `Maissilage Bewertung ${now[0]} ${now[1]}`, nel: silage.maissilage.realEnergy().toFixed(2), tm: silage.maissilage.tm() } });
              }


              setDialogOpen(true);
            }}>Hinzufügen</Button>
            : <Button sx={{
              fontSize: 16,
              fontWeight: 600,
              color: "white",
              float: "right",
            }} onClick={() => SW.nextStep()}>Weiter</Button>}
        </Box>
      </React.Fragment>
    )
  };

  const handleClickDialogAbortDialog = (event) => {
    setFodderValues(initialState);
    setDialogOpen(false);
  }



  const handleClickDialogAddDialog = (event) => {
    setDialogOpen(false)

    appContext.ownFodder.push(fodderValues.values);

    setFodderValues(initialState);

    if (state.hasOwnProperty('SW')) {
      state.SW.goToStep(1);
    }
  }

  let currentStep;

  if (state.hasOwnProperty('SW') && state.SW.hasOwnProperty('state')) {
    currentStep = state.SW.state.activeStep;
  }




  return (
    <React.Fragment>
      <div>
        <Dialog open={dialogOpen} onClose={handleClickDialogAbortDialog} aria-labelledby="form-dialog-title">

          <DialogTitle sx={{
            backgroundColor: '#80BA26',
            width: '100%',
          }} id="form-dialog-title">Futter hinzufügen</DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <div>
              <TextField id="txt_name" variant="outlined" sx={{ margin: 1, }}
                label="Futterart" onChange={(e) => handleFormChange(e, "name", e.target.value)} autoComplete="off" value={fodderValues.values.name} />
              <TextField id="txt_tm" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="TM g/kg" onChange={(e) => handleFormChange(e, "tm", parseFloat(e.target.value))} value={fodderValues.values.tm} />
              <TextField id="txt_xf" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="XF (Grobf. g)" onChange={(e) => handleFormChange(e, "xf", parseFloat(e.target.value))} value={fodderValues.values.xf} />
              <TextField id="txt_andfom" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="aNDFom (Grobf.) g/kg (TM)" onChange={(e) => handleFormChange(e, "andfom", parseFloat(e.target.value))} value={fodderValues.values.andfom} />
              <TextField id="txt_xp" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="XP g/kg (TM)" onChange={(e) => handleFormChange(e, "xp", parseFloat(e.target.value))} value={fodderValues.values.xp} />
              <TextField id="txt_nxp" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="nXP g/kg (TM)" onChange={(e) => handleFormChange(e, "nxp", parseFloat(e.target.value))} value={fodderValues.values.nxp} />
              <TextField id="txt_nel" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="NEL MJ" onChange={(e) => handleFormChange(e, "nel", parseFloat(e.target.value))} value={fodderValues.values.nel} />
              <TextField id="txt_ca" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="Ca g/kg (TM)" onChange={(e) => handleFormChange(e, "ca", parseFloat(e.target.value))} value={fodderValues.values.ca} />
              <TextField id="txt_p" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="P g/kg (TM)" onChange={(e) => handleFormChange(e, "p", parseFloat(e.target.value))} value={fodderValues.values.p} />
              <TextField id="txt_mg" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="MG g/kg (TM)" onChange={(e) => handleFormChange(e, "mg", parseFloat(e.target.value))} value={fodderValues.values.mg} />
              <TextField id="txt_na" type='number' inputProps={{ min: '0' }} variant="outlined" autoComplete="off" sx={{ margin: 1, }}
                label="NA g/kg (TM)" onChange={(e) => handleFormChange(e, "na", parseFloat(e.target.value))} value={fodderValues.values.na} />
              <TextField id="txt_k" type='number' inputProps={{ min: '0' }} variant="outlined" sx={{ margin: 1, }}
                label="K g/kg (TM)" onChange={(e) => handleFormChange(e, "k", parseFloat(e.target.value))} value={fodderValues.values.k} />
            </div>
          </DialogContent>
          <DialogActions sx={{
            backgroundColor: '#80BA26',
            width: '100%',
          }}>

            <Button sx={{
              fontSize: 16,
              fontWeight: 600,
              color: 'white',
            }} color="primary" onClick={handleClickDialogAbortDialog}>
              Abbrechen
            </Button>
            <Button sx={{
              fontSize: 16,
              fontWeight: 600,
              color: 'white',
            }} color="primary" onClick={handleClickDialogAddDialog}>
              Hinzufügen
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Box sx={{
        backgroundColor: '#80BA26',
        padding: '5px',
        color: 'black',
        minHeight: '85px'
      }}>
        <Typography sx={{ color: '#2E2E2E' }} variant="h6" align="left">Futterbewertung {dialogName}</Typography>
        {
          currentStep > 0 ?
            <Typography sx={{ color: '#2E2E2E', float: 'right' }} variant="h7" >Aktueller Energiegehalt: {energy} MJ</Typography>
            : null
        }

      </Box>

      <Box sx={{ padding: '10px' }}/>

      <TableContainer component={Paper} style={{ overflow: 'hidden' }}>
        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{}}
          >
            <Grid item xs={3} style={{ minHeight: '50vh', minWidth: '20vw' }}>
              {dialogType === 0 ?
                <StepWizard
                  isHashEnabled
                  //transitions={state.transitions} // comment out for default transitions
                  instance={setInstance}>
                  <StartGrassilage />
                  <EnergieKonzentrationGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <EnergieKonzentrationAufwuchsGrassilage values={silage.grassilage} onValueChanged={newValues} />

                  <TMGehaltGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <PHWert values={silage.grassilage} onValueChanged={newValues} />

                  <FarbeBraeunungGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <FarbeVergilbungGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <FarbeSonstigesGrassilage values={silage.grassilage} onValueChanged={newValues} />

                  <GeruchButterGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <GeruchEssigGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <GeruchErwaermungGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <GeruchHefenGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <GeruchSchimmelGrassilage values={silage.grassilage} onValueChanged={newValues} />

                  <GefuegeGrassilage values={silage.grassilage} onValueChanged={newValues} />


                  <VerschmutzungGrassilage values={silage.grassilage} onValueChanged={newValues} />
                  <FinalGrassilage values={silage.grassilage} onValueChanged={newValues} />
                </StepWizard>
                : null}

              {dialogType === 1 ?
                <StepWizard
                  isHashEnabled
                  //transitions={state.transitions} // comment out for default transitions
                  instance={setInstance}>
                  <StartHeu />

                  <EnergieKonzentrationHeu values={silage.heu} onValueChanged={newValues} />
                  <EnergieKonzentrationAufwuchsHeu values={silage.heu} onValueChanged={newValues} />


                  <FarbeHeu values={silage.heu} onValueChanged={newValues} />

                  <GeruchHeu values={silage.heu} onValueChanged={newValues} />

                  <StrukturHeu values={silage.heu} onValueChanged={newValues} />

                  <VerschmutzungHeu values={silage.heu} onValueChanged={newValues} />
                  <FinalHeu values={silage.heu} onValueChanged={newValues} />
                </StepWizard>
                : null}

              {dialogType === 2 ?
                <StepWizard
                  isHashEnabled
                  //transitions={state.transitions} // comment out for default transitions
                  instance={setInstance}>
                  <StartMaissilage />
                  <TMGehaltMaissilage values={silage.maissilage} onValueChanged={newValues} />
                  <Kolbenanteil values={silage.maissilage} onValueChanged={newValues} />

                  <FarbeMaissilage values={silage.maissilage} onValueChanged={newValues} />

                  <GeruchMaissilage values={silage.maissilage} onValueChanged={newValues} />

                  <GefuegeMaissilage values={silage.maissilage} onValueChanged={newValues} />

                  <VerschmutzungMaissilage values={silage.maissilage} onValueChanged={newValues} />
                  <FinalMaissilage values={silage.maissilage} onValueChanged={newValues} />
                </StepWizard>
                : null}

              {currentStep === 0 ?
                <Initial onValueChanged={(type) => setDialogType(type)} />
                :
                null
              }
            </Grid>

          </Grid>
        </div>
      </TableContainer>

      <Box sx={{ padding: '10px' }}/>

      <InstanceControls SW={SW} />


    </React.Fragment>
  )
}





export default FodderAnalysisComponent;


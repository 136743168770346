import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import React, { useEffect } from 'react';
import AlmaBeunruhigt from './../assets/alma/alma beunruhigt.png';
import AlmaHappy from './../assets/alma/alma happy.png';
import AlmaNeutral from './../assets/alma/alma neutral.png';
import AlmaTot from './../assets/alma/alma tot.png';

import {
  BrowserView, isBrowser,
  isMobile, MobileView
} from "react-device-detect";


export default function CowPopOver(props) {
  const { warnings, opened } = props;

  var cowRef = React.createRef();
  let alma = AlmaNeutral;

  useEffect(() => {
    if (opened) {
      cowRef.current.click()
    }
  });


  const SetAlmaColor = () => {
    if (warnings) {
      let warn = 0;
      let errors = 0;

      warnings.forEach(item => {
        if (item[0] === "warning") {
          warn++;
        } if (item[0] === "error") {
          errors++;
        }
      })

      if (errors > 0) {
        alma = AlmaTot;
        return;
      } else if (warn > 0) {
        alma = AlmaBeunruhigt;
        return;
      }

      alma = AlmaHappy;
    }
  }

  SetAlmaColor();

  return (
    <Box sx={isBrowser ? {
      position: 'absolute',
      top: 80,
      right: 5,
    /*'background-color': 'green',
    border: '2px solid #4CAF50',*/} : {
      position: 'absolute',
      top: 80,
      right: 0,
      /*'background-color': 'green',
      border: '2px solid #4CAF50',*/
    }} >
      <PopupState variant="popper" popupId="demo-popup-popper">
        {(popupState) => (
          <div>

            <BrowserView>
              <img src={alma} alt="" width="150" height="150" {...bindToggle(popupState)} ref={cowRef} />
            </BrowserView>
            <MobileView>
              <img src={alma} alt="" width="100" height="100" {...bindToggle(popupState)} ref={cowRef} />
            </MobileView>


            <Popper {...bindPopper(popupState)} transition placement={isBrowser ? 'bottom' : 'bottom'} >

              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={400}>
                  <Paper>
                    {
                      warnings ?
                        warnings.map((warning, index) =>
                          isMobile || !warning[2] ?
                            <Alert key={index} variant="outlined" severity={warning[0]}><div dangerouslySetInnerHTML={{ __html: warning[1] }}></div></Alert>
                            :
                            <Alert key={index} variant="outlined" severity={warning[0]}><div dangerouslySetInnerHTML={{ __html: warning[1] + '<br/>' + warning[2] }}></div></Alert>
                        )
                        :
                        null
                    }
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    </Box>
  );
}
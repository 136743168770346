import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

Geruch.propTypes = {

};



function Geruch(props) {

    const { values, onValueChanged } = props;



    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Geruch:</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Buttersäure (Geruch nach Schweiß, ranziger Butter)</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth >
                        <RadioGroup
                            aria-labelledby="geruch-radio-buttons-group-label"
                            name="geruch"
                            value={values.geruch}
                            onChange={(e) => handleChange("geruch", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="angenehm säuerlich, aromatisch, brotartig (-0 Punkte)" />
                            <FormControlLabel value="1" control={<Radio color="default"/>} label="leicht alkoholisch oder leichter Essigsäuregeruch (-1 Punkte)" />
                            <FormControlLabel value="3" control={<Radio color="default"/>} label="stark alkoholischer oder Röstgeruch (-3 Punkte)" />
                            <FormControlLabel value="5" control={<Radio color="default"/>} label="muffig oder leichter Buttersäuregeruch (-5 Punkte)" />
                            <FormControlLabel value="7" control={<Radio color="default"/>} label="widerlich, Fäulnisgeruch, jauchig (-7 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default Geruch;
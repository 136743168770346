
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


Farbe.propTypes = {

};

function Farbe(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Farbe:</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Bräunung</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="braeunung-radio-buttons-group-label"
                            name="braeunung"
                            value={values.braeunung}
                            onChange={(e) => handleChange('braeunung', e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="normale Farbe (-0 Punkte)" />
                            <FormControlLabel value="1" control={<Radio color="default"/>} label="bräunlicher als normal (-1 Punkte)" />
                            <FormControlLabel value="2" control={<Radio color="default"/>} label="deutlich gebräunt (-2 Punkte)" />
                            <FormControlLabel value="4" control={<Radio color="default"/>} label="stark gebräunt (-4 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default Farbe;
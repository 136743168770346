import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import logoAusgleichsfutter from './../../assets/icons/braun/87/icon0003.png';

function CompensationTationTutorial(props) {
    return (
        <div >
            <Paper>
                <img sx={{backgroundColor: '#80BA26',}}  src={logoAusgleichsfutter} alt="" style={{float: 'left', marginRight: '10px'}}/>

                <Typography variant="body1" align='left' style={{ padding: 10 }}>
                    Wähle + FUTTER HINZUFÜGEN, um beliebige Futtermittel zur Ration hinzuzufügen. <br/>
                    Bestätige das Futtermittel mit HINZUFÜGEN<br/>
                    Info:	In diesem Schritt ist vor allem die RNB auf „0“ auszugleichen.<br/>
                    Die nötige Milchmenge aus nXP und NEL soll an die Trogration angepasst werden. <br/>
                    MINERALSTOFFE:	Wähle rechts {'>>'} neben Nährstoffe den Button MINERALSTOFFE aus, um die Mineralstoffe zu überprüfen<br/>
                    Hier kannst du ein Mineralfutter hinzufügen und an die benötigte Milchmenge anpassen<br/>
                    Mit WEITER kannst du die maximale Milchmenge aus Leistungskraftfutter berechnen.<br/>
                </Typography>


            </Paper>

            
        </div>
    );
}

export default CompensationTationTutorial;
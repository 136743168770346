import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import logoGesamtration from './../../assets/icons/braun/87/icon0002.png';

function SumRationTutorial(props) {
    return (
        <div>
            <Paper>
                <img sx={{ backgroundColor: '#80BA26', }} src={logoGesamtration} alt="" style={{ float: 'left', marginRight: '10px' }} />

                <Typography variant="body1" align='left' style={{ padding: 10, paddingBlock: 19 }}>
                    Wähle DRUCKEN, um ein schriftliches Dokument zu erstellen und um Papier zu verschwenden. <br />
                    Info:	<u>THINK BEFORE PRINT!</u><br />
                </Typography>


            </Paper>

        </div>
    );
}

export default SumRationTutorial;
import data from './alerts.json';

export function ParseAlertsBasicFodderNutrients(fodderValues) {
    return ParseAlerts(fodderValues, data.grundfutter.nutrients);
}

export function ParseAlertsCompenstationFodderNutrients(fodderValues) {
    return ParseAlerts(fodderValues, data.ausgleichsfutter.nutrients);
}

export function ParseAlertsPerformanceFodderNutrients(fodderValues) {
    return ParseAlerts(fodderValues, data.leistungskraftfutter.nutrients);
}

export function ParseAlertsPerformanceFodderMinerals(fodderValues) {
    return ParseAlerts(fodderValues, data.leistungskraftfutter.minerals);
}


function ParseAlerts(fodderValues, rules) {
    let warnings = [];

    rules.forEach(rule => {
        if (rule.rulesType === 'percentage') {
            let referenceValue = fodderValues[rule.reference];

            if (referenceValue == undefined) {
                //console.log(rule.reference);
                return;
            }

            let compareTo = 1;

            if (rule.hasOwnProperty('compareTo')) {
                compareTo = fodderValues[rule.compareTo];
            }

            let finalWarning = [];
            let startEndFound = false;

            rule.rules.forEach(innerRule => {
                if (startEndFound)
                    return;

                if (innerRule.hasOwnProperty('disabled') && innerRule.disabled)
                    return;

                if (innerRule.range.hasOwnProperty('start') && innerRule.range.hasOwnProperty('end')) {
                    let percentageStart = innerRule.range.start / 100 * compareTo
                    let percentageEnd = innerRule.range.end / 100 * compareTo

                    if (referenceValue > percentageStart && referenceValue < percentageEnd) {
                        startEndFound = true;


                        if (innerRule.hasOwnProperty('show') && innerRule.show === true) {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                } else if (innerRule.range.hasOwnProperty('start')) {
                    let percentageStart = innerRule.range.start / 100 * compareTo

                    if (referenceValue > percentageStart) {
                        if (innerRule.hasOwnProperty('show') && innerRule.show) {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }

                } else if (innerRule.range.hasOwnProperty('end')) {
                    let percentageEnd = innerRule.range.end / 100 * compareTo
                    if (referenceValue < percentageEnd) {
                        if (innerRule.hasOwnProperty('show') && innerRule.show) {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                }
            })
            if (finalWarning.length > 0)
                warnings.push(finalWarning);
        }

        if (rule.rulesType === 'number') {
            let referenceValue = fodderValues[rule.reference];

            let finalWarning = [];
            let startEndFound = false;

            rule.rules.forEach(innerRule => {
                if (startEndFound)
                    return;

                if (innerRule.hasOwnProperty('disabled') && innerRule.disabled)
                    return;

                if (innerRule.range.hasOwnProperty('start') && innerRule.range.hasOwnProperty('end')) {
                    if (referenceValue > innerRule.range.start && referenceValue < innerRule.range.end) {
                        startEndFound = true;


                        if (innerRule.hasOwnProperty('show')) {
                            if (innerRule.show === true) {
                                finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                            } else {
                                finalWarning = [];
                            }
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }

                } else if (innerRule.range.hasOwnProperty('start')) {
                    if (referenceValue > innerRule.range.start) {
                        if (innerRule.hasOwnProperty('show')) {
                            if (innerRule.show === true) {
                                finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                            } else {
                                finalWarning = [];
                            }
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                } else if (innerRule.range.hasOwnProperty('end')) {
                    if (referenceValue < innerRule.range.end) {

                        if (innerRule.hasOwnProperty('show')) {
                            if (innerRule.show === true) {
                                finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                            } else {
                                finalWarning = [];
                            }
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                }
            });

            if (finalWarning.length > 0)
                warnings.push(finalWarning);
        }

        if (rule.rulesType === 'ratio') {
            if (rule.hasOwnProperty('disabled') && rule.disabled) {
                return;
            }
            let compareTo;

            if (rule.hasOwnProperty('compareTo')) {
                compareTo = fodderValues[rule.compareTo];
            } else {
                return;
            }

            let referenceValue = fodderValues[rule.reference];
            let finalWarning = [];
            let startEndFound = false;

            rule.rules.forEach(innerRule => {
                if (startEndFound)
                    return;

                if (innerRule.hasOwnProperty('disabled') && innerRule.disabled)
                    return;

                if (innerRule.ratio.hasOwnProperty('startMultiplier') && innerRule.ratio.hasOwnProperty('endMultiplier')) {
                    if (referenceValue * innerRule.ratio.startMultiplier > compareTo && referenceValue * innerRule.ratio.endMultiplie < compareTo) {
                        startEndFound = true;
                        if (innerRule.hasOwnProperty('show')) {
                            if (innerRule.show === true) {
                                finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                            } else {
                                finalWarning = [];
                            }
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                } else if (innerRule.ratio.hasOwnProperty('startMultiplier')) {
                    if (referenceValue * innerRule.ratio.startMultiplier > compareTo) {
                        if (innerRule.hasOwnProperty('show')) {
                            if (innerRule.show === true) {
                                finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                            } else {
                                finalWarning = [];
                            }
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                } else if (innerRule.ratio.hasOwnProperty('endMultiplier')) {
                    if (referenceValue * innerRule.ratio.endMultiplier < compareTo) {

                        if (innerRule.hasOwnProperty('show')) {
                            if (innerRule.show === true) {
                                finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                            } else {
                                finalWarning = [];
                            }
                        } else {
                            finalWarning = [innerRule.type, innerRule.shortInfo, innerRule.explanation]
                        }
                    }
                }
            });

            if (finalWarning.length > 0) {
                warnings.push(finalWarning);
            }
        }
    })

    return warnings;
}

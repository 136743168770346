import AddIcon from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useContext, useEffect } from 'react';
import AppContext from '../appcontext';
import './../css/dataTable.css';


function WebFulabComponent(props: any) {
    const appContext = useContext(AppContext);

    const [data, setData] = React.useState<Array<IFutterMittel>>([]);
    const [webfulabDetails, setWebfulabDetails] = React.useState<ILoginDetails>({
        username: 'Testen',
        password: '8g1cfW',
    });


    interface ILoginDetails {
        username: string;
        password: string;
    }

    interface ILoginData {
        answerCode: number;
        error: string;
        logonToken: number;
        betriebsNummer: number;
        name: string;
        oekoOderKonvKz: number;
        tierarten: number[];
    }

    interface IFutterMittel {
        creationDate: Date,
        name: string,
        tm: number,
        xf: number,
        andfom: number,
        xp: number,
        nxp: number,
        nel: number,
        ca: number,
        p: number,
        mg: number,
        na: number,
        k: number
    }


    class FutterMittel implements IFutterMittel {
        creationDate: Date = new Date();
        name: string = '';
        tm: number = 0;
        xf: number = 0;
        andfom: number = 0;
        xp: number = 0;
        nxp: number = 0;
        nel: number = 0;
        ca: number = 0;
        p: number = 0;
        mg: number = 0;
        na: number = 0;
        k: number = 0;
    }

    let login: ILoginData;

    useEffect(() => {
    }, []);

    async function GetWebFulabBetriebsmittel(callback: Function) {
        const { data } = await axios.post('https://fulab.bayern.de/nfl/rest/futMitOverview', {
            logonToken: login.logonToken,
            betriebsNummer: login.betriebsNummer
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        });

        if (data.hasOwnProperty('futMits')) {
            callback(data.futMits);
        }
    }

    async function GetWebFuLabLoginToken() {

        console.log('trying to login');
        
        const { data } = await axios.post('http://fulab.bayern.de/nfl/rest/loginext',
            {
                user: webfulabDetails.username,
                pass: webfulabDetails.password
            });

        console.log(data);


/*
        axios({
            method: 'post',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            url: 'http://fulab.bayern.de/nfl/rest/loginext',
            data: {
                user: webfulabDetails.username,
                pass: webfulabDetails.password
            }
        }).then(function (response: any) {
            console.log(response);
        });


*/

        //login = data;
    }

    // Create a function to parse date from a number (YYYYmmdd) to date
    function parseDate(date: number) {
        let year = date.toString().substring(0, 4);
        let month = date.toString().substring(4, 6);
        let day = date.toString().substring(6, 8);
        return new Date(+year, +month, +day);
    }

    function numberBodyTemplate(num: number) {
        return num.toFixed(2);
    }

    function tmBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.tm));
    }

    function xfBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.xf));
    }

    function andfomBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.andfom));
    }

    function xpBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.xp));
    }

    function nxpBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.nxp));
    }

    function nelBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.nel));
    }

    function caBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.ca));
    }

    function pBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.p));
    }

    function mgBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.mg));
    }

    function naBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.na));
    }

    function kBodyTemplate(rowDate: IFutterMittel) {
        return (numberBodyTemplate(rowDate.k));
    }

    function actionBodyTemplate(rowData: any) {
        return (
            <React.Fragment>
                <div style={{ display: 'flex' }}>
                    <IconButton aria-label="edit row" size="small" onClick={(e) => {
                        appContext.ownFodder.push({ fm: 0, name: rowData.name, tm: rowData.tm.toFixed(2), xf: rowData.xf.toFixed(2), andfom: rowData.andfom.toFixed(2), xp: rowData.xp.toFixed(2), nxp: rowData.nxp.toFixed(2), nel: rowData.nel.toFixed(2), ca: rowData.ca.toFixed(2), p: rowData.p.toFixed(2), mg: rowData.mg.toFixed(2), na: rowData.na.toFixed(2), k: rowData.k.toFixed(2) });
                    }}>
                        <AddIcon />
                    </IconButton>
                </div>
            </React.Fragment>
        );
    }

    function ParseWebFuLab(futtermittel: any) {
        let allFuttermittel: IFutterMittel[] = [];

        futtermittel.forEach((futter: any) => {
            let parsedFuttermittel = new FutterMittel();
            parsedFuttermittel.name = futter.bezeichnung;
            parsedFuttermittel.creationDate = parseDate(futter.creationDate);

            futter.futMitInh.forEach((futterinhalt: any) => {
                switch (futterinhalt.inhStoffId) {
                    case 110:
                        parsedFuttermittel.tm = futterinhalt.value;
                        break;
                    case 300:
                        parsedFuttermittel.xf = futterinhalt.value;
                        break;
                    case 321:
                        parsedFuttermittel.andfom = futterinhalt.value;
                        break;
                    case 200:
                        parsedFuttermittel.xp = futterinhalt.value;
                        break;
                    case 213:
                        parsedFuttermittel.nxp = futterinhalt.value;
                        break;
                    case 410:
                        parsedFuttermittel.nel = futterinhalt.value;
                        break;

                    case 510:
                        parsedFuttermittel.ca = futterinhalt.value;
                        break;
                    case 520:
                        parsedFuttermittel.p = futterinhalt.value;
                        break;
                    case 530:
                        parsedFuttermittel.mg = futterinhalt.value;
                        break;
                    case 532:
                        parsedFuttermittel.na = futterinhalt.value;
                        break;
                    case 534:
                        parsedFuttermittel.k = futterinhalt.value;
                        break;
                }
            })

            allFuttermittel.push(parsedFuttermittel);
        })

        return allFuttermittel;
    }

    function handleClickLoadWebFulabData() {
        GetWebFuLabLoginToken().then(response => {
            /*GetWebFulabBetriebsmittel((futtermittel: any) => {
                setData(ParseWebFuLab(futtermittel));
            });*/
        });
    }

    return (
        <div>
            <Box sx={{
                backgroundColor: '#80BA26',
                padding: 5,
                color: 'black',
                minHeight: '85px'
            }} style={{ minWidth: '1000px' }} >
                <Typography variant="h6">WebFulab</Typography>
            </Box>
            <Box sx={{ padding: '10px' }} />
            <Box sx={{
                backgroundColor: '#80BA26',
                padding: 0,
                color: 'black',
                minHeight: '85px'
            }} style={{ minWidth: '1000px' }} >
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <TextField id="txt_username" type='string' variant="outlined" autoComplete="off" sx={{ margin: 2, }} style={{}}
                        label="Username" onChange={(e) => {
                            setWebfulabDetails({ ...webfulabDetails, username: e.target.value });
                        }} value={webfulabDetails.username} />
                    <TextField id="txt_password" type='password' variant="outlined" autoComplete="off" sx={{ margin: 2, }}
                        label="Password" onChange={(e) => {
                            setWebfulabDetails({ ...webfulabDetails, password: e.target.value });
                        }} value={webfulabDetails.password} />
                    <Button sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'white',
                    }} color="primary" onClick={handleClickLoadWebFulabData}>
                        DatenLaden
                    </Button>
                </div>
            </Box>
            <Box sx={{ padding: '10px' }} />
            <DataTable value={data}
                responsiveLayout="scroll"
                style={{ minWidth: '1000px' }}
                filterDisplay="row"
            >
                <Column body={actionBodyTemplate} />
                <Column field="name" header="Name" sortable filter filterMatchMode="contains" filterPlaceholder="Suche" ></Column>
                <Column field="tm" header="TM" body={tmBodyTemplate} sortable={true} />
                <Column field="xf" header="XF" body={xfBodyTemplate} sortable={true} />
                <Column field="andfom" header="aNDF" body={andfomBodyTemplate} sortable={true} />
                <Column field="xp" header="XP" body={xpBodyTemplate} sortable={true} />
                <Column field="nxp" header="nXP" body={nxpBodyTemplate} sortable={true} />
                <Column field="nel" header="NEL" body={nelBodyTemplate} sortable={true} />
                <Column field="ca" header="Ca" body={caBodyTemplate} sortable={true} />
                <Column field="p" header="P" body={pBodyTemplate} sortable={true} />
                <Column field="mg" header="Mg" body={mgBodyTemplate} sortable={true} />
                <Column field="na" header="Na" body={naBodyTemplate} sortable={true} />
                <Column field="k" header="K" body={kBodyTemplate} sortable={true} />
            </DataTable>
        </div >
    );
}

export default WebFulabComponent;
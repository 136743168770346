import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

Farbe.propTypes = {

};

function Farbe(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <div>
                <Typography variant='h6'>Farbe:</Typography>

                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="farbe-radio-buttons-group-label"
                            name="farbe"
                            value={values.farbe}
                            onChange={(e) => handleChange("farbe", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="dem Ausgangsmaterial ähnliche Farbe (-0 Punkte)" />
                            <FormControlLabel value="1" control={<Radio color="default"/>} label="Farbe wenig verändert (-1 Punkte)" />
                            <FormControlLabel value="2" control={<Radio color="default"/>} label="Farbe stark verändert (-2 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>

            </div>
        </div>
    );
}

export default Farbe;

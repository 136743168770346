import { Typography } from '@mui/material';


Start.propTypes = {

};

function Start(props) {
    return (
        <div>
            <Typography variant='h6' sx={{ paddingTop: 5 }}>Futterbewertung Maissilage</Typography>
        </div>
    );
}

export default Start;
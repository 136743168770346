
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


Farbe.propTypes = {

};

function Farbe(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {


        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Farbe:</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Sonstige Beobachtungen</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="farbe_sonstiges-radio-buttons-group-label"
                            name="farbe_sonstiges"
                            value={values.farbe_sonstiges}
                            onChange={(e) => handleChange('farbe_sonstiges', e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="Keine (-0 Punkte)" />
                            <FormControlLabel value="1" control={<Radio color="default"/>} label="giftgrün durch starke Buttersäuregärung (-7 Punkte)" />
                            <FormControlLabel value="2" control={<Radio color="default"/>} label="sichtbarer Schimmelbefall: Silage nicht verfüttern (-7 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default Farbe;
const MenuItem = {
    Help: '/hilfe',
    Rations: '/ration',
    BasicRation: '/grundfutter',
    CompensationRation: '/ausgleichsfutter',
    PerformanceRation: '/leistungsfutter',
    Analysis: '/gesamtration',
    OwnFodder: '/eigenefuttermittel',
    GruberTable: '/grubertabelle',
    FodderAnalysis: '/futterbewertung',
    WebFuLab: '/webfulab',
    Impressum: '/impressum',
    Blend: '/teilmischung',
}

export default MenuItem;
import { TextField, TextFieldClasses } from '@mui/material';
import React, { ChangeEvent, KeyboardEventHandler, useEffect } from 'react';


type NumberTextBoxProps = {
    id?: string;
    value: number;
    newValue: (value: number) => void;
    sx?: any;
    size?: "small" | "medium";
    label?: string;
    variant?: "standard" | "filled" | "outlined";
    autoComplete?: string;
}



function NumberTextBox(props: NumberTextBoxProps) {
    const { value: currentValue, newValue } = props;
    const [value, setValue] = React.useState(currentValue.toString());

    function handleFormChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const re = /^\d*(\.|\,)?\d*$/;

        if (event.target.value === "" || re.test(event.target.value)) {
            setValue(event.target.value);
        }
    };


    function setValueFromString(value: string) {
        if (value == null || value === "" || isNaN(parseFloat(value))) {
            setValue("0");
        } else {
            setValue(parseFloat(value).toFixed(2));
        }
    }


    useEffect(() => {
        setValueFromString(currentValue.toString());
        //setValue(currentValue.toString());
    }, [currentValue]);


    function SetValue() {
        if (value === "") {
            setValue("0");
            newValue(0);
        } else {
            newValue(parseFloat(value));
        }
    }

    function handleOnBlur(event: React.ChangeEvent) {
        SetValue();
    };

    function keyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        event.stopPropagation();
        if (event.key === "Enter") {
            SetValue();
        }
    };


    return (
        <TextField
            type="number"
            inputProps={{
                step: "0.1",
                inputMode: "decimal",
                pattern: "([0-9]+)?[,\\.]?[0-9]*",
            }}

            onChange={(e) => handleFormChange(e)}
            onBlur={(e) => handleOnBlur(e)}
            value={value}
            sx={props.sx}
            size={props.size}
            label={props.label}
            variant={props.variant}
            autoComplete={props.autoComplete}
            id={props.id}
            onKeyDown={e => keyDown(e)}
        />
    );
}

export default NumberTextBox;

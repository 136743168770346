import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from '../components/helpcomponent';
import FodderComponent from '../components/fodder/foddercomponent'
import RationComponent from '../components/rationcomponent'
import DialogType from '../components/fodder/dialogType'
import AnalysisPrinterComponent from '../components/analysis/analysisPrinterComponent'
import OwnFodderComponenet from '../components/ownfoddercomponent/ownfoddercomponent'
import GruberTableComponent from '../components/grubertable/grubertablecomponent'
import FodderAnalysisComponent from '../components/fodderanalysis/fodderanalysiscomponenent'
import Impressum from "../components/impressum";
import WebFulabComponent from "../components/webfulab/webfulabcomponent";
import Blend from "../components/blend/blendcomponent";

const style = {
    marginTop: '40px'
}

function AppRouter(props) {
    return (
        <div style={style}>
            <Routes>
                <Route
                    path="/"
                    exact
                    element={<Navigate to="/ration" replace />}
                />
                <Route path="/hilfe" exact element={<Home />}></Route>
                <Route path="/ration" exact element={<RationComponent />}></Route>
                <Route path="/grundfutter" exact element={<FodderComponent dialogName="Grundfutter" dialogType={DialogType.BasicRation} />}></Route>
                <Route path="/ausgleichsfutter" exact element={<FodderComponent dialogName="Ausgleichsfutter" dialogType={DialogType.CompensationRation} />}></Route>
                <Route path="/leistungsfutter" exact element={<FodderComponent dialogName="Leistungsfutter" dialogType={DialogType.PerformanceRation} />}></Route>

                <Route path="/gesamtration" exact element={<AnalysisPrinterComponent />}></Route>
                <Route path="/eigenefuttermittel" exact element={<OwnFodderComponenet isAddable />}></Route>
                <Route path="/grubertabelle" exact element={<GruberTableComponent />}></Route>
                <Route path="/futterbewertung" exact element={<FodderAnalysisComponent />}></Route>
                <Route path="/impressum" exact element={<Impressum />}></Route>
                <Route path="/teilmischung" exact element={<Blend />}></Route>
            </Routes>
        </div>
    )
}


export default AppRouter;

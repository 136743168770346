import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';


function Initial(props) {
  const [dialogType, setDialogType] = React.useState(0);
    const { onValueChanged } = props;
    

    

    return (
        <div>
            <FormControl fullWidth sx={{paddingTop: 5, paddingBottom: 5}}>
                <InputLabel></InputLabel>
                <Select
                  value={dialogType}
                  label=""
                  onChange={(e) => {setDialogType(e.target.value); onValueChanged(e.target.value);}}
                >
                  <MenuItem value={0}>Grassilage</MenuItem>
                  <MenuItem value={1}>Heu</MenuItem>
                  <MenuItem value={2}>Maissilage</MenuItem>

                </Select>
              </FormControl>
        </div>
    );
}

export default Initial;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useContext, useRef } from 'react';
import {
  BrowserView
} from "react-device-detect";
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { FoodCalculator } from '../../foodcalc/FoodCalc';
import SumBasicRation from '../fodder/SumBasicRation';
import SumCompenstationRation from '../fodder/SumCompenstationRation';
import SumPerformanceRation from '../fodder/SumPerformanceRation';
import RationTable from '../fodder/Todo/RationTable';
import { ParseAlertsBasicFodderNutrients, ParseAlertsCompenstationFodderNutrients, ParseAlertsPerformanceFodderNutrients } from './../../alertparser/alertparser';
import AppContext from './../appcontext';
import CowPopOver from './../cowpopovercomponent';
import DialogType from './../fodder/dialogType';



function AnalysisPrinterComponent(props) {
  const linkToPrint = () => {
    return (
      <Button sx={{
        fontSize: 16,
        fontWeight: 600,
        color: "white",
        float: "right",
      }} align="right">Drucken</Button>
    )
  }

  const componentRef = useRef();

  return (
    <React.Fragment>
      <div ref={componentRef}>
        <AnalysisComponent />
      </div>

      <Box sx={{ padding: '10px' }} />
      <Box sx={{ backgroundColor: '#80BA26', overflow: 'hidden'}}>
        <Button sx={{
          fontSize: 16,
          fontWeight: 600,
          color: 'white',
          float: 'left',
        }} component={Link} to="/leistungsfutter">Zurück</Button>
        <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} />
      </Box>
    </React.Fragment>
  )
}

function AnalysisComponent(props) {
  const appContext = useContext(AppContext);

  let rationIndex;

  // Search Active Ration
  appContext.rations.forEach((ration, index) => {
    if (ration.active) {
      rationIndex = index;
      return;
    }
  });

  let calculator = FoodCalculator.getInstance()

  let minVal = Math.min(...[calculator.milkInKgCompensationRation.xp, calculator.milkInKgCompensationRation.nxp, calculator.milkInKgCompensationRation.nel]);
  let milkRatio = minVal / appContext.rations[rationIndex].feederMilkProductionKg;

  let warningMessages = ParseAlertsBasicFodderNutrients({ tm: calculator.sumBasicRation.tm, cowWeight: appContext.rations[rationIndex].cowWeight, andf: calculator.andfomBasicRationPercentage / 100, xf: calculator.xfBasicRationPercentage / 100 });
  warningMessages = warningMessages.concat(ParseAlertsCompenstationFodderNutrients({ tm: calculator.sumCompensationRation.tm, cowWeight: appContext.rations[rationIndex].cowWeight, milkPerformance: milkRatio, xf: calculator.xfCompensationRationPercentage / 100, andf: calculator.andfomCompensationRationPercentage / 100, rnb: calculator.sumCompensationRation.rnb }));
  warningMessages = warningMessages.concat(ParseAlertsPerformanceFodderNutrients({ tm: calculator.sumPerformanceRation.tm, cowWeight: appContext.rations[rationIndex].cowWeight, milkPerformance: milkRatio, xf: calculator.xfPerformanceRationPercentage / 100, andf: calculator.andfomPerformanceRationPercentage / 100, rnb: calculator.sumPerformanceRation.rnb }));

  /*
  backgroundColor: '#80BA26',
                  padding: 5,
                  color: 'black',
                  minHeight: '100px'*
          */

  return (
    <Box sx={{ paddingTop: 0 }}>
      <Box sx={{
        backgroundColor: '#80BA26',
        padding: '5px' ,
        color: 'black',
        minHeight: '100px',
      }}>
        <Typography sx={{ color: '#2E2E2E' }} variant="h6" align="left">Gesamtration {appContext.rations[rationIndex].name}:</Typography>
      </Box>

      <Box>
        <Box sx={{ paddingTop: '10px' }}>
          <RationTable rationIndex={rationIndex} DialogType={[DialogType.BasicRation, DialogType.CompensationRation, DialogType.PerformanceRation]} />
        </Box>

        <Box sx={{ paddingTop: '10px' }}>
          <SumBasicRation ration={appContext.rations[rationIndex]} SetOpen="True" />
        </Box>

        <Box sx={{ paddingTop: '10px' }}>
          <SumCompenstationRation ration={appContext.rations[rationIndex]} SetOpen="True" />
        </Box>

        <Box sx={{ paddingTop: '10px' }}>
          <SumPerformanceRation ration={appContext.rations[rationIndex]} SetOpen="True" />
        </Box>
      </Box>

      <BrowserView>
        <CowPopOver warnings={warningMessages} />
      </BrowserView>
    </Box>
  )
}


export default AnalysisPrinterComponent;

import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


function EnergieKonzentration(props) {

    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6' sx={{ paddingBottom: 1 }}>Energiekonzentration Grünfutter:</Typography>
            <div style={{ paddingTop: 10 }}>
                <FormControl fullWidth>
                    <RadioGroup
                        aria-labelledby="aufwuchs-radio-buttons-group-label"
                        name="aufwuchs"
                        value={values.aufwuchs}
                        onChange={(e) => handleChange('aufwuchs', e.target.value)}
                    >
                        <FormControlLabel value={0} control={<Radio color="default" />} label="Erster Aufwuchs" />
                        <FormControlLabel value={1} control={<Radio color="default" />} label="Folgeaufwüchse" />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
}

export default EnergieKonzentration;
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import React, { useContext } from 'react';
import { round } from '../../helperfunctions';
import AppContext from './../../../appcontext';
import DialogType from './../../dialogType';


function SumTable(props) {
    const { SetDialogType, RationIndex, Editable, onEditRow, showSum } = props;

    const appContext = useContext(AppContext);

    const initialState = { values: { fm: 0, name: "", tm: 0, xf: 0, andfom: 0, xp: 0, nxp: 0, nel: 0, ca: 0, p: 0, mg: 0, na: 0, k: 0 }, index: -1 };
    const [fodderValues, setFodderValues] = React.useState(initialState);

    // Hack
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    var fm = 0;
    var tm = 0;
    var rationsArray = [];

    if (SetDialogType === DialogType.BasicRation) {
        rationsArray = appContext.rations[RationIndex].basicRation;
    } else if (SetDialogType === DialogType.CompensationRation) {
        rationsArray = appContext.rations[RationIndex].compensationRation;
    } else if (SetDialogType === DialogType.PerformanceRation) {
        rationsArray = appContext.rations[RationIndex].performanceRation;
    }

    const editRow = (event, row, index) => {
        setFodderValues({ values: row, index: index });

        if (onEditRow) {
            onEditRow(fodderValues);
        }
    }

    const editFm = (event, row, index) => {
        if (SetDialogType === DialogType.BasicRation) {
            appContext.rations[RationIndex].basicRation[index].fm = parseFloat(event.target.value);
        } else if (SetDialogType === DialogType.CompensationRation) {
            appContext.rations[RationIndex].compensationRation[index].fm = parseFloat(event.target.value);
        } else if (SetDialogType === DialogType.PerformanceRation) {
            appContext.rations[RationIndex].performanceRation[index].fm = parseFloat(event.target.value);
        }

        forceUpdate();
    }

    const deleteRow = (event, row, index) => {
        if (SetDialogType === DialogType.BasicRation) {
            appContext.rations[RationIndex].basicRation = appContext.rations[RationIndex].basicRation.filter(item => item.name !== row.name);
        } else if (SetDialogType === DialogType.CompensationRation) {
            appContext.rations[RationIndex].compensationRation = appContext.rations[RationIndex].compensationRation.filter(item => item.name !== row.name);
        } else if (SetDialogType === DialogType.PerformanceRation) {
            appContext.rations[RationIndex].performanceRation = appContext.rations[RationIndex].performanceRation.filter(item => item.name !== row.name);
        }

        forceUpdate();

    }

    return (
        <React.Fragment>
            {
                rationsArray.map((row, index) => {
                    fm += row.fm;
                    tm += row.fm * (row.tm / 1000)

                    return <Row editable={Editable} index={index} key={row.name} row={row} editFm={editFm} editRow={editRow} deleteRow={deleteRow} cowNumber={appContext.rations[RationIndex].cowNumber} />
                })
            }
            {
                showSum ?
                    <TableRow >
                        <TableCell>
                            Summe
                        </TableCell>
                        {Editable ?
                            <TableCell />
                            :
                            ""
                        }
                        <TableCell component="th" scope="row">
                            {round(fm)} kg
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {round(tm)} kg
                        </TableCell>
                    </TableRow >
                    :
                    null
            }


        </React.Fragment>
    );
}

function Row(props) {
    const { editable, row, editFm, editRow, deleteRow, cowNumber, index } = props;

    const handleFormChange = (event) => {
        editFm(event, row, index);
    }

    return (
        <React.Fragment>
            <TableRow >
                {editable ?
                    <TableCell>

                        <React.Fragment>
                            <IconButton aria-label="edit row" size="small" onClick={(e) => { editRow(e, row, index) }}>
                                <EditIcon />
                            </IconButton>

                            <IconButton aria-label="edit  row" size="small" onClick={(e) => { deleteRow(e, row, index) }}>
                                <DeleteIcon />
                            </IconButton>
                        </React.Fragment>
                    </TableCell>
                    :
                    ""
                }
                <TableCell >{row.name}</TableCell>
                <TableCell component="th" scope="row">
                    {editable ?
                        <TextField type="number" inputProps={{ step: "0.1" }} size="small" onChange={(e) => handleFormChange(e)} value={row.fm} sx={{
                            marginLeft: 4,
                            marginRight: 4,
                            width: '10ch',
                        }} />
                        :
                        row.fm
                    } kg
                </TableCell>
                <TableCell component="th" scope="row">
                    {(row.fm * (row.tm / 1000)).toFixed(2)} kg
                </TableCell>
                <TableCell component="th" scope="row">
                    {(row.fm * cowNumber).toFixed(2)} kg
                </TableCell>

            </TableRow>
        </React.Fragment>
    );
}



export default SumTable;
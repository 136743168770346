import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';



function EnergieKonzentrationAufwuchs(props) {

    const { values, onValueChanged } = props;

    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>

            {
                values.aufwuchs === 0 ?
                    <div>
                        <Typography variant='h6' sx={{ paddingBottom: 1 }}>Energiekonzentration Grünfutter:</Typography>
                        <div style={{ paddingTop: 10 }}>
                            <FormControl fullWidth>
                                <RadioGroup
                                    aria-labelledby="Grünfutter-radio-buttons-group-label"
                                    name="Grünfutter"
                                    value={values.energyNumber}
                                    onChange={(e) => handleChange('energyNumber', e.target.value)}
                                >
                                    <FormControlLabel value={0} control={<Radio color="default" />} label="Blattstadium" />
                                    <FormControlLabel value={1} control={<Radio color="default" />} label="Schossen" />
                                    <FormControlLabel value={2} control={<Radio color="default" />} label="Ähren-, Rispenschieben" />
                                    <FormControlLabel value={3} control={<Radio color="default" />} label="Beginn Blüte" />
                                    <FormControlLabel value={4} control={<Radio color="default" />} label="Ende Blüte" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    :
                    <div>
                        <Typography variant='h6' sx={{ paddingBottom: 1 }}>Energiekonzentration Grünfutter:</Typography>
                        <div style={{ paddingTop: 10 }}>
                            <FormControl fullWidth>
                                <RadioGroup
                                    aria-labelledby="Grünfutter-radio-buttons-group-label"
                                    name="Grünfutter"
                                    value={values.energyNumber}
                                    onChange={(e) => handleChange('energyNumber', e.target.value)}
                                >
                                    <FormControlLabel value={5} control={<Radio color="default" />} label="Blattstadium (&lt;4 Wochen)" />
                                    <FormControlLabel value={6} control={<Radio color="default" />} label="Schossen (4-5 Wochen)" />
                                    <FormControlLabel value={7} control={<Radio color="default" />} label="Ähren-, Rispenschieben (6-8 Wochen)" />
                                    <FormControlLabel value={8} control={<Radio color="default" />} label="Beginn Blüte (&gt; 8 Wochen)" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
            }
        </div>
    );
}

export default EnergieKonzentrationAufwuchs;
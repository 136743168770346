class Food {
    fm = 0;
    name = "";
    tm = 0;
    xf = 0;
    andfom = 0;
    xp = 0;
    nxp = 0;
    nel = 0;
    ca = 0;
    p = 0;
    na = 0;
    mg = 0;
    k = 0;

    Blend : FoodExtended[] = new Array<FoodExtended>();

    get rnb(): number {
        return (this.xp - this.nxp) / 6.25;
    }

    get tmFmPercentage(): number {
        return this.tm / this.fm * 100;
    }

    constructor(fm?: number, name?: string, tm?: number, xf?: number, andfom?: number, xp?: number, nxp?: number, nel?: number, ca?: number, p?: number, na?: number, mg?: number, k?: number) {
        this.fm = fm || 0;
        this.name = name || '';
        this.tm = tm || 0;
        this.xf = xf || 0;
        this.andfom = andfom || 0;
        this.xp = xp || 0;
        this.nxp = nxp || 0;
        this.nel = nel || 0;
        this.ca = ca || 0;
        this.p = p || 0;
        this.na = na || 0;
        this.mg = mg || 0;
        this.k = k || 0;
    }
}


class FoodExtended extends Food {
    get tmPerKg(): number {
        if (this.fm === 0 || this.tm === 0) {
            return 0.0;
        }

        return this.fm * this.tm / 1000;
    }

    get xfPerKg(): number {
        return this.tmPerKg * this.xf;
    }

    get andFomPerKg(): number {
        return this.tmPerKg * this.andfom;
    }

    get xpPerKg(): number {
        return this.tmPerKg * this.xp;
    }

    get xpPerKgFM(): number {
        return this.fm * this.xp;
    }

    get nxpPerKg(): number {
        return this.tmPerKg * this.nxp;
    }

    get nxpPerKgFM(): number {
        return this.fm * this.nxp;
    }

    get nelPerKg(): number {
        return this.tmPerKg * this.nel;
    }

    get nelPerKgFM(): number {
        return this.fm * this.nel;
    }

    get caPerKg(): number {
        return this.tmPerKg * this.ca;
    }

    get caPerKgFM(): number {
        return this.fm * this.ca;
    }

    get pPerKg(): number {
        return this.tmPerKg * this.p;
    }

    get pPerKgFM(): number {
        return this.fm * this.p;
    }

    get mgPerKg(): number {
        return this.tmPerKg * this.mg;
    }

    get mgPerKgFM(): number {
        return this.fm * this.mg;
    }

    get naPerKg(): number {
        return this.tmPerKg * this.na;
    }

    get naPerKgFM(): number {
        return this.fm * this.na;
    }

    get kPerKg(): number {
        return this.tmPerKg * this.k;
    }

    get kPerKgFM(): number {
        return this.fm * this.k;
    }

    get rnbPerKg(): number {
        return (this.xpPerKg - this.nxpPerKg) / 6.25;
    }

}


class FoodCalculator {
    private _cowWeight: number = 700;

    get cowWeight(): number {
        return this._cowWeight;
    }

    set cowWeight(value: number) {
        this._cowWeight = value;
    }

    public milkExpectationKgPerday: number = 25;

    private foodIntakeKgPerday: number = 18.2;
    public milkFatPercentage: number = 4.0;
    public milkProtein: number = 3.4;

    // BasicRation
    public basicRation: FoodExtended[] = [];
    public sumBasicRation: Food = new Food();
    public maintenanceBasicRation: Food = new Food();
    public inMilkBasicRation: Food = new Food();

    // per get?!
    public demandPerKgMilkBasicRation: Food = new Food();
    public milkInKgBasicRation: Food = new Food();
    public xfBasicRationPercentage: Number = 0;
    public andfomBasicRationPercentage: Number = 0;

    //CompensationRation
    public compensationRation: FoodExtended[] = [];
    public sumCompensationRation: Food = new Food();
    public milkInKgCompensationRation: Food = new Food();
    public demandPerKgMilkCompensationRation: Food = new Food();

    public xfCompensationRationPercentage: Number = 0;
    public andfomCompensationRationPercentage: Number = 0;

    // PerformanceRation

    public performanceRation: FoodExtended[] = [];
    public sumPerformanceRation: Food = new Food();
    public milkInKgPerformanceRation: Food = new Food();

    public xfPerformanceRationPercentage: Number = 0;
    public andfomPerformanceRationPercentage: Number = 0;

    //Make this class a singleton   (Singleton Design Pattern)  
    private static instance: FoodCalculator;

    public static getInstance(): FoodCalculator {
        if (!FoodCalculator.instance) {
            FoodCalculator.instance = new FoodCalculator();
        }
        return FoodCalculator.instance;
    }

    public PrepareNewCalculation(cowWeight: number, milkExpectationKgPerday: number, milkFatPercentage: number, milkProtein: number) {
        this.cowWeight = cowWeight;
        this.milkExpectationKgPerday = milkExpectationKgPerday;
        this.foodIntakeKgPerday = cowWeight * 0.03;

        this.milkFatPercentage = milkFatPercentage;
        this.milkProtein = milkProtein;
    }

    public AddBasicRation(food: FoodExtended) {
        this.basicRation.push(food);
    }

    public AddBasicRationArray(array: any[]) {
        this.basicRation = [];

        array.forEach(element => {
            // RNB is a calculated value, otherwise the assignment will fail
            if (element.rnb !== undefined) {
                delete element.rnb;
            }

            this.AddBasicRation( Object.assign(new FoodExtended, element));
        });
    }

    public AddCompensationRation(food: FoodExtended) {
        this.compensationRation.push(food);
    }

    public AddCompensationRationArray(array: any[]) {
        this.compensationRation = [];

        array.forEach(element => {
            // RNB is a calculated value, otherwise the assignment will fail
            if (element.rnb !== undefined) {
                delete element.rnb;
            }

            this.AddCompensationRation(Object.assign(new FoodExtended, element));
        });
    }

    public AddPerformanceRation(food: FoodExtended) {
        this.performanceRation.push(food);
    }

    public AddPerformanceRationArray(array: any[]) {
        this.performanceRation = [];

        array.forEach(element => {
            // RNB is a calculated value, otherwise the assignment will fail
            if (element.rnb !== undefined) {
                delete element.rnb;
            }

            this.AddPerformanceRation(Object.assign(new FoodExtended, element));
        });
    }

    public CalculateRation() {
        this.CalculateSumBasicRation();
        this.CalculateMaintenanceBasicRation();
        this.CalculateInMilkBasicRation();
        this.CalculateDemandPerKgMilkBasicRation();
        this.CalculateMilkPerKgBasicRation();

        this.CalculateSumCompensationRation();
        this.CalculateMilkCompensationRation();
        this.CalculateDemandPerKgCompensationRation();

        this.CalculateSumPerformanceRation();
        this.CalculateMilkPerformanceRation();
    }


    private CalculateSumBasicRation() {
        this.sumBasicRation = new Food();

        this.basicRation.forEach(value => {
            if (!(value instanceof FoodExtended)) {
                value = Object.assign(new FoodExtended(), value);
            }

            this.sumBasicRation.fm += value.fm;
            this.sumBasicRation.tm += value.tmPerKg;
            this.sumBasicRation.xf += value.xfPerKg;
            this.sumBasicRation.andfom += value.andFomPerKg;
            this.sumBasicRation.xp += value.xpPerKg;
            this.sumBasicRation.nxp += value.nxpPerKg;
            this.sumBasicRation.nel += value.nelPerKg;
            this.sumBasicRation.ca += value.caPerKg;
            this.sumBasicRation.p += value.pPerKg;
            this.sumBasicRation.mg += value.mgPerKg;
            this.sumBasicRation.na += value.naPerKg;
            this.sumBasicRation.k += value.kPerKg;
        })

        this.xfBasicRationPercentage = (this.sumBasicRation.xf / this.sumBasicRation.tm) / 10;
        this.andfomBasicRationPercentage = (this.sumBasicRation.andfom / this.sumBasicRation.tm) / 10;
    }

    private CalculateMaintenanceBasicRation() {
        this.maintenanceBasicRation = new Food();

        this.maintenanceBasicRation.xp = 0.4 * this.cowWeight + 190;
        this.maintenanceBasicRation.nxp = 0.4 * this.cowWeight + 190;
        this.maintenanceBasicRation.nel = 0.293 * Math.pow(this.cowWeight, 0.75)

        this.maintenanceBasicRation.ca = 2 * this.foodIntakeKgPerday;
        this.maintenanceBasicRation.p = 1.43 * this.foodIntakeKgPerday;
        this.maintenanceBasicRation.mg = 0.8 * this.foodIntakeKgPerday;
        this.maintenanceBasicRation.na = 0.6 * this.foodIntakeKgPerday;
        this.maintenanceBasicRation.k = 8.8 * this.foodIntakeKgPerday;
    }

    private CalculateInMilkBasicRation() {
        this.inMilkBasicRation = new Food();

        this.inMilkBasicRation.xp = this.sumBasicRation.xp - this.maintenanceBasicRation.xp;
        this.inMilkBasicRation.nxp = this.sumBasicRation.nxp - this.maintenanceBasicRation.nxp;
        this.inMilkBasicRation.nel = this.sumBasicRation.nel - this.maintenanceBasicRation.nel;
        this.inMilkBasicRation.ca = this.sumBasicRation.ca - this.maintenanceBasicRation.ca;
        this.inMilkBasicRation.p = this.sumBasicRation.p - this.maintenanceBasicRation.p;
        this.inMilkBasicRation.mg = this.sumBasicRation.mg - this.maintenanceBasicRation.mg;
        this.inMilkBasicRation.na = this.sumBasicRation.na - this.maintenanceBasicRation.na;
        this.inMilkBasicRation.k = this.sumBasicRation.k - this.maintenanceBasicRation.k;
    }

    private CalculateDemandPerKgMilkBasicRation() {
        this.demandPerKgMilkBasicRation = new Food();

        this.demandPerKgMilkBasicRation.xp = 20 * this.milkProtein + 17;
        this.demandPerKgMilkBasicRation.nxp = 20 * this.milkProtein + 17;
        this.demandPerKgMilkBasicRation.nel = 1.05 + (0.38 * this.milkFatPercentage) + (0.21 * this.milkProtein);
        this.demandPerKgMilkBasicRation.ca = 2.5
        this.demandPerKgMilkBasicRation.p = 1.43
        this.demandPerKgMilkBasicRation.mg = 0.8
        this.demandPerKgMilkBasicRation.na = 0.6
        this.demandPerKgMilkBasicRation.k = 1.6
    }

    private CalculateMilkPerKgBasicRation() {
        this.milkInKgBasicRation = new Food();

        this.milkInKgBasicRation.xp = this.inMilkBasicRation.xp / this.demandPerKgMilkBasicRation.xp;
        this.milkInKgBasicRation.nxp = this.inMilkBasicRation.nxp / this.demandPerKgMilkBasicRation.nxp;
        this.milkInKgBasicRation.nel = this.inMilkBasicRation.nel / this.demandPerKgMilkBasicRation.nel;
        this.milkInKgBasicRation.ca = this.inMilkBasicRation.ca / this.demandPerKgMilkBasicRation.ca;
        this.milkInKgBasicRation.p = this.inMilkBasicRation.p / this.demandPerKgMilkBasicRation.p;
        this.milkInKgBasicRation.mg = this.inMilkBasicRation.mg / this.demandPerKgMilkBasicRation.mg;
        this.milkInKgBasicRation.na = this.inMilkBasicRation.na / this.demandPerKgMilkBasicRation.na;
        this.milkInKgBasicRation.k = this.inMilkBasicRation.k / this.demandPerKgMilkBasicRation.k;
    }

    private CalculateSumCompensationRation() {
        this.sumCompensationRation = new Food();

        this.compensationRation.forEach(value => {
            if (!(value instanceof FoodExtended)) {
                value = Object.assign(new FoodExtended(), value);
            }

            this.sumCompensationRation.tm += value.tmPerKg;
            this.sumCompensationRation.fm += value.fm;
            this.sumCompensationRation.xf += value.xfPerKg;
            this.sumCompensationRation.andfom += value.andFomPerKg;

            this.sumCompensationRation.xp += value.xpPerKg;
            this.sumCompensationRation.nxp += value.nxpPerKg;
            this.sumCompensationRation.nel += value.nelPerKg;
            this.sumCompensationRation.ca += value.caPerKg;
            this.sumCompensationRation.p += value.pPerKg;
            this.sumCompensationRation.mg += value.mgPerKg;
            this.sumCompensationRation.na += value.naPerKg;
            this.sumCompensationRation.k += value.kPerKg;
        });


        this.sumCompensationRation.fm += this.sumBasicRation.fm;
        this.sumCompensationRation.tm += this.sumBasicRation.tm;
        this.sumCompensationRation.xf += this.sumBasicRation.xf;
        this.sumCompensationRation.andfom += this.sumBasicRation.andfom;
        this.sumCompensationRation.xp += this.inMilkBasicRation.xp;
        this.sumCompensationRation.nxp += this.inMilkBasicRation.nxp;
        this.sumCompensationRation.nel += this.inMilkBasicRation.nel;
        this.sumCompensationRation.ca += this.inMilkBasicRation.ca;
        this.sumCompensationRation.p += this.inMilkBasicRation.p;
        this.sumCompensationRation.mg += this.inMilkBasicRation.mg;
        this.sumCompensationRation.na += this.inMilkBasicRation.na;
        this.sumCompensationRation.k += this.inMilkBasicRation.k;


        this.xfCompensationRationPercentage = (this.sumCompensationRation.xf / this.sumCompensationRation.tm) / 10;
        this.andfomCompensationRationPercentage = (this.sumBasicRation.andfom / this.sumCompensationRation.tm) / 10;
    }


    private CalculateMilkCompensationRation() {
        this.milkInKgCompensationRation = new Food();

        this.milkInKgCompensationRation.xp = this.sumCompensationRation.xp / this.demandPerKgMilkBasicRation.xp
        this.milkInKgCompensationRation.nxp = this.sumCompensationRation.nxp / this.demandPerKgMilkBasicRation.nxp
        this.milkInKgCompensationRation.nel = this.sumCompensationRation.nel / this.demandPerKgMilkBasicRation.nel
        this.milkInKgCompensationRation.ca = this.sumCompensationRation.ca / this.demandPerKgMilkBasicRation.ca
        this.milkInKgCompensationRation.p = this.sumCompensationRation.p / this.demandPerKgMilkBasicRation.p
        this.milkInKgCompensationRation.mg = this.sumCompensationRation.mg / this.demandPerKgMilkBasicRation.mg
        this.milkInKgCompensationRation.na = this.sumCompensationRation.na / this.demandPerKgMilkBasicRation.na
        this.milkInKgCompensationRation.k = this.sumCompensationRation.k / this.demandPerKgMilkBasicRation.k
    }


    private CalculateDemandPerKgCompensationRation() {
        this.demandPerKgMilkCompensationRation = new Food();
        this.demandPerKgMilkCompensationRation.xp = this.sumCompensationRation.xp - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.xp
        this.demandPerKgMilkCompensationRation.nxp = this.sumCompensationRation.nxp - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.nxp
        this.demandPerKgMilkCompensationRation.nel = this.sumCompensationRation.nel - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.nel
        this.demandPerKgMilkCompensationRation.ca = this.sumCompensationRation.ca - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.ca
        this.demandPerKgMilkCompensationRation.p = this.sumCompensationRation.p - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.p
        this.demandPerKgMilkCompensationRation.mg = this.sumCompensationRation.mg - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.mg
        this.demandPerKgMilkCompensationRation.na = this.sumCompensationRation.na - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.na
        this.demandPerKgMilkCompensationRation.k = this.sumCompensationRation.k - this.milkExpectationKgPerday * this.demandPerKgMilkBasicRation.k
    }


    private CalculateSumPerformanceRation() {
        this.sumPerformanceRation = new Food();

        this.performanceRation.forEach(value => {
            if (!(value instanceof FoodExtended)) {
                value = Object.assign(new FoodExtended(), value);
            }

            this.sumPerformanceRation.tm += value.tmPerKg;
            this.sumPerformanceRation.fm += value.fm;
            this.sumPerformanceRation.xf += value.xfPerKg;
            this.sumPerformanceRation.andfom += value.andFomPerKg;

            this.sumPerformanceRation.xp += value.xpPerKg;
            this.sumPerformanceRation.nxp += value.nxpPerKg;
            this.sumPerformanceRation.nel += value.nelPerKg;
            this.sumPerformanceRation.ca += value.caPerKg;
            this.sumPerformanceRation.p += value.pPerKg;
            this.sumPerformanceRation.mg += value.mgPerKg;
            this.sumPerformanceRation.na += value.naPerKg;
            this.sumPerformanceRation.k += value.kPerKg;
        });


        this.sumPerformanceRation.tm += this.sumCompensationRation.tm;
        this.sumPerformanceRation.fm += this.sumCompensationRation.fm;
        this.sumPerformanceRation.xf += this.sumCompensationRation.xf;
        this.sumPerformanceRation.andfom += this.sumCompensationRation.andfom;

        this.sumPerformanceRation.xp += this.sumCompensationRation.xp;
        this.sumPerformanceRation.nxp += this.sumCompensationRation.nxp;
        this.sumPerformanceRation.nel += this.sumCompensationRation.nel;
        this.sumPerformanceRation.ca += this.sumCompensationRation.ca;
        this.sumPerformanceRation.p += this.sumCompensationRation.p;
        this.sumPerformanceRation.mg += this.sumCompensationRation.mg;
        this.sumPerformanceRation.na += this.sumCompensationRation.na;
        this.sumPerformanceRation.k += this.sumCompensationRation.k;


        this.xfPerformanceRationPercentage = (this.sumPerformanceRation.xf / this.sumPerformanceRation.tm) / 10;
        this.andfomPerformanceRationPercentage = (this.sumBasicRation.andfom / this.sumPerformanceRation.tm) / 10;
    }

    private CalculateMilkPerformanceRation() {
        this.milkInKgPerformanceRation = new Food();

        this.milkInKgPerformanceRation.xp = this.sumPerformanceRation.xp / this.demandPerKgMilkBasicRation.xp
        this.milkInKgPerformanceRation.nxp = this.sumPerformanceRation.nxp / this.demandPerKgMilkBasicRation.nxp
        this.milkInKgPerformanceRation.nel = this.sumPerformanceRation.nel / this.demandPerKgMilkBasicRation.nel
        this.milkInKgPerformanceRation.ca = this.sumPerformanceRation.ca / this.demandPerKgMilkBasicRation.ca
        this.milkInKgPerformanceRation.p = this.sumPerformanceRation.p / this.demandPerKgMilkBasicRation.p
        this.milkInKgPerformanceRation.mg = this.sumPerformanceRation.mg / this.demandPerKgMilkBasicRation.mg
        this.milkInKgPerformanceRation.na = this.sumPerformanceRation.na / this.demandPerKgMilkBasicRation.na
        this.milkInKgPerformanceRation.k = this.sumPerformanceRation.k / this.demandPerKgMilkBasicRation.k

    }
}


export { FoodCalculator, Food, FoodExtended };
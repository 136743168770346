import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AppContext from "./appcontext";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CowPopOver from "./cowpopovercomponent";

import { FoodCalculator } from "../foodcalc/FoodCalc";

import {
    BrowserView,
    //MobileView,
    //isBrowser,
    //isMobile
} from "react-device-detect";
import NumberTextBox from "./controls/NumberTextBox";

function RationComponent(props) {
    const initialRationState = {
        id: -1,
        active: false,
        name: "",
        date: new Date().toISOString().split(".")[0],
        cowWeight: 700,
        compensationMilkProductionKg: 24,
        performanceMilkProductionKg: 30,
        milkFatPercent: 4.2,
        proteinPercent: 3.6,
        foodIntake: 0,
        cowNumber: 60,
        basicRation: [],
        compensationRation: [],
        performanceRation: [],
    };

    const appContext = useContext(AppContext);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogEdit, setDialogEdit] = React.useState(false);
    const [idRationEditing, setIdRationEditing] = React.useState(-1);

    const [openImportDialog, setOpenImportDialog] = React.useState(false);

    const [importetRation, setImportedRation] = React.useState("");

    const calculator = FoodCalculator.getInstance();

    // Hack
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [rationValues, setRationsValues] = React.useState(initialRationState);

    const handleClickOpenDialog = (event) => {
        setDialogOpen(true);
        setRationsValues(initialRationState);
    };

    const handleClickAbortDialog = (event) => {
        setDialogOpen(false);
        setRationsValues(initialRationState);
    };

    const handleClickAddDialog = (event) => {
        if (rationValues.name !== "") {
            rationValues.active = true;
            rationValues.foodIntake = rationValues.cowWeight * 0.03;

            rationValues.id = appContext.rations.length;

            appContext.rations.forEach((ration) => {
                ration.active = false;
            });

            appContext.rations.push(rationValues);
        }

        setDialogOpen(false);
    };

    const handleAddRationDialogTxtChange = (value, field) => {
        if (field === "cowWeight") {
            setRationsValues({
                ...rationValues,
                [field]: value,
                foodIntake: value * 0.03,
            });
        } else {
            setRationsValues({ ...rationValues, [field]: value });
        }
    };

    const handleDelRation = (event, id) => {
        event.stopPropagation();

        if (id < 0) {
            return;
        }

        if (appContext.rations[id].active === true && id !== 0) {
            appContext.rations.splice(id, 1);
            appContext.rations[0].active = true;
        } else {
            appContext.rations.splice(id, 1);
        }

        forceUpdate();
    };

    const handleEditRation = (event, id) => {
        event.stopPropagation();

        setRationsValues(appContext.rations[id]);
        setDialogOpen(true);
        setDialogEdit(true);
        setIdRationEditing(id);
    };

    const handleExportRation = async (event, id) => {
        event.stopPropagation();

        const myData = appContext.rations[id];
        const fileName = myData.name;
        delete myData.id;
        const json = JSON.stringify(myData);
        const element = document.createElement("a");
        const file = new Blob([json], { type: "application/json" });

        element.href = URL.createObjectURL(file);
        element.download = fileName + ".json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);

        /*
        const blob = new Blob([json], { type: 'application/json' });
        console.log(blob)

        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        */
    };

    const changeActiveRow = (event, id) => {
        event.stopPropagation();

        appContext.rations.forEach((ration) => {
            ration.active = false;
        });

        appContext.rations[id].active = true;

        calculator.PrepareNewCalculation(
            appContext.rations[id].cowWeight,
            appContext.rations[id].performanceMilkProductionKg,
            appContext.rations[id].compensationMilkProductionKg,
            appContext.rations[id].milkFatPercent,
            appContext.rations[id].proteinPercent,
            appContext.rations[id].cowNumber
        );
        forceUpdate();
    };

    const handleClickDialogEditDialog = (event) => {
        setDialogOpen(false);
        setDialogEdit(false);

        appContext.rations[idRationEditing] = rationValues;
    };

    const handleImportDialogAbort = (event) => {
        setOpenImportDialog(false);
    };

    const handleImportDialogAdd = (event) => {
        setOpenImportDialog(false);

        if (importetRation !== "") {
            appContext.rations.push(importetRation);
        }
    };

    const onImportRationFromFile = (event) => {
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = (event) => {
            let ration = JSON.parse(event.target.result);
            ration.name += " (IMPORT)";
            ration.active = false;
            setImportedRation(ration);
        };
    };

    return (
        <Box sx={{ paddingTop: 0 }}>
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleClickAbortDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        sx={{
                            backgroundColor: "#80BA26",
                            width: "100%",
                        }}
                        id="form-dialog-title"
                    >
                        <Typography variant="h5">Ration hinzufügen</Typography>
                        Fügen Sie eine Ration hinzu.
                    </DialogTitle>

                    <DialogContent>
                        <Box>
                            <TextField
                                id="txt_name"
                                label="Name"
                                variant="outlined"
                                autoComplete="off"
                                onChange={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e.target.value,
                                        "name"
                                    );
                                }}
                                defaultValue={rationValues.name}
                                sx={{ margin: 1 }}
                            />

                            <TextField
                                id="txt_date"
                                type="datetime-local"
                                label="Datum"
                                variant="outlined"
                                autoComplete="off"
                                onChange={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e.target.value,
                                        "date"
                                    );
                                }}
                                defaultValue={rationValues.date}
                                sx={{ margin: 1 }}
                            />

                            <NumberTextBox
                                id="kgcows"
                                variant="outlined"
                                autoComplete="off"
                                label="Gewicht der Kühe (kg)"
                                newValue={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e,
                                        "cowWeight"
                                    );
                                }}
                                value={rationValues.cowWeight}
                                sx={{ margin: 1 }}
                            />

                            <NumberTextBox
                                id="milkperday"
                                variant="outlined"
                                autoComplete="off"
                                label="Leistungsration (kg Milch / Tag)"
                                newValue={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e,
                                        "performanceMilkProductionKg"
                                    );
                                }}
                                value={rationValues.performanceMilkProductionKg}
                                sx={{ margin: 1 }}
                            />

                            <NumberTextBox
                                id="feederMilkProduction"
                                variant="outlined"
                                autoComplete="off"
                                label="Ausgleichsration (kg Milch / Tag)"
                                newValue={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e,
                                        "compensationMilkProductionKg"
                                    );
                                }}
                                value={
                                    rationValues.compensationMilkProductionKg
                                }
                                sx={{ margin: 1 }}
                            />

                            <NumberTextBox
                                id="fatMilk"
                                variant="outlined"
                                autoComplete="off"
                                label="Fettgehalt (%)"
                                newValue={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e,
                                        "milkFatPercent"
                                    );
                                }}
                                value={rationValues.milkFatPercent}
                                sx={{ margin: 1 }}
                            />

                            <NumberTextBox
                                id="protein"
                                variant="outlined"
                                autoComplete="off"
                                label="Eiweißgehalt (%)"
                                newValue={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e,
                                        "proteinPercent"
                                    );
                                }}
                                value={rationValues.proteinPercent}
                                sx={{ margin: 1 }}
                            />
                            <NumberTextBox
                                id="cowNumber"
                                variant="outlined"
                                autoComplete="off"
                                label="Anzahl Kühe"
                                newValue={(e) => {
                                    handleAddRationDialogTxtChange(
                                        e,
                                        "cowNumber"
                                    );
                                }}
                                value={rationValues.cowNumber}
                                sx={{ margin: 1 }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            backgroundColor: "#80BA26",
                            width: "100%",
                        }}
                    >
                        <Button
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "white",
                            }}
                            onClick={handleClickAbortDialog}
                        >
                            Abbrechen
                        </Button>
                        {dialogEdit ? (
                            <Button
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "white",
                                }}
                                onClick={handleClickDialogEditDialog}
                            >
                                Speichern
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "white",
                                }}
                                onClick={handleClickAddDialog}
                            >
                                Hinzufügen
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={openImportDialog}
                    onClose={handleImportDialogAbort}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Ration importieren
                    </DialogTitle>
                    <DialogContent>
                        Ration auswählen und importieren.
                    </DialogContent>
                    <Box sx={{ margin: 2 }}>
                        <input
                            type="file"
                            name="file"
                            onChange={onImportRationFromFile}
                        />
                    </Box>
                    <DialogActions>
                        <Box
                            align="right"
                            sx={{
                                backgroundColor: "#80BA26",
                                width: "100%",
                            }}
                        >
                            <Button
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "white",
                                }}
                                color="primary"
                                onClick={handleImportDialogAbort}
                            >
                                Abbrechen
                            </Button>
                            <Button
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: "white",
                                }}
                                color="primary"
                                onClick={handleImportDialogAdd}
                            >
                                Hinzufügen
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
            <form
                sx={{
                    "& .MuiTextField-root": {
                        margin: 4,
                        width: "200",
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#80BA26",
                        padding: "5px",
                        color: "black",
                        minHeight: "85px",
                    }}
                >
                    <Typography
                        sx={{ color: "#2E2E2E" }}
                        variant="h6"
                        align="left"
                    >
                        Rationen{" "}
                    </Typography>
                    <div align="right" sx={{ backgroundColor: "#80BA26" }}>
                        <Button
                            color="primary"
                            onClick={handleClickOpenDialog}
                            sx={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "white",
                            }}
                        >
                            <AddIcon />
                            Ration hinzufügen
                        </Button>
                    </div>
                </Box>

                <Box
                    sx={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsable table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Name</TableCell>
                                    <TableCell>Datum</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appContext.rations.map((row, i) => (
                                    <Row
                                        key={i}
                                        id={i}
                                        row={row}
                                        onDelRation={handleDelRation}
                                        onEditRation={handleEditRation}
                                        onChangeActiveRow={changeActiveRow}
                                        onExportRation={handleExportRation}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{ backgroundColor: "#80BA26", overflow: "hidden" }}>
                    <Button
                        sx={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "white",
                            float: "left",
                        }}
                        component={Link}
                        to="/grundfutter"
                    >
                        Weiter
                    </Button>
                    <Button
                        sx={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "white",
                            float: "right",
                        }}
                        onClick={() => setOpenImportDialog(true)}
                    >
                        <ImportExportIcon />
                        Ration importieren
                    </Button>
                </Box>
            </form>
            <BrowserView>
                <CowPopOver
                    opened
                    warnings={[
                        [
                            "info",
                            "<h1>Muuuuh!</h1>Sobald du mich anklickst, gebe ich dir Hinweise, wie du mich leistungsgerecht fütterst!",
                        ],
                    ]}
                />
            </BrowserView>
        </Box>
    );
}

function Row(props) {
    const {
        row,
        id,
        onDelRation,
        onEditRation,
        onExportRation,
        onChangeActiveRow,
    } = props;

    return (
        <React.Fragment>
            <TableRow
                onClick={(event) => {
                    if (onChangeActiveRow) onChangeActiveRow(event, id);
                }}
                key={id}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            onChangeActiveRow(e, id);
                        }}
                    >
                        {row.active ? (
                            <RadioButtonCheckedIcon />
                        ) : (
                            <RadioButtonUncheckedIcon />
                        )}
                    </IconButton>
                    <IconButton
                        id="test"
                        size="small"
                        onClick={(e) => {
                            if (onEditRation) onEditRation(e, id);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            if (onDelRation) onDelRation(e, id);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            if (onExportRation) onExportRation(e, id);
                        }}
                    >
                        <SaveAltIcon />
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell>{row.date}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default RationComponent;


import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function TMGehalt(props) {
    const { values, onValueChanged } = props;


    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>TM-Gehalt (Wringprobe):</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Bei feuchter Silage einen Ball formen und danach die Silage pressen.<br />Ab 30% TM aus der Silage einen Strang formen und einmal kräftig wringen (nicht nachfassen!).</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="Geruch-radio-buttons-group-label"
                            name="Geruch"
                            value={values.tmpercent}
                            onChange={(e) => handleChange("tmpercent", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="Starker Saftaustritt schon bei leichtem Händedruck" />
                            <FormControlLabel sx={{paddingTop: 1}} value="1" control={<Radio color="default"/>} label="Starker Saftaustritt bei kräftigem Händedruck" />
                            <FormControlLabel sx={{paddingTop: 1}} value="2" control={<Radio color="default"/>} label="Beim Wringen Saftaustritt zwischen den Fingern, Hände werden nass" />
                            <FormControlLabel sx={{paddingTop: 1}} value="3" control={<Radio color="default"/>} label="Beim Wringen kein Saftaustritt zwischen den Fingern, Hände werden noch feucht" />
                            <FormControlLabel sx={{paddingTop: 1}} value="4" control={<Radio color="default"/>} label="Nach dem Wringen glänzen die Hände" />
                            <FormControlLabel sx={{paddingTop: 1}} value="5" control={<Radio color="default"/>} label="Nach dem Wringen nur noch schwaches Feuchtegefühl auf den Händen" />
                            <FormControlLabel sx={{paddingTop: 1}} value="6" control={<Radio color="default"/>} label="Hände bleiben vollständig trocken" />

                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default TMGehalt;
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { FoodCalculator } from '../../foodcalc/FoodCalc';


function Row(props) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                <TableCell>{row.xf} g</TableCell>
                <TableCell>{row.andfom} g</TableCell>
                <TableCell>{row.rnbPerKg.toFixed()}</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.xp} g</TableCell>
                <TableCell>{row.nxp} g</TableCell>
                <TableCell>{row.nel} MJ</TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function RowMinerals(props) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.ca} g</TableCell>
                <TableCell>{row.p} g</TableCell>
                <TableCell>{row.mg} g</TableCell>
                <TableCell>{row.na} g</TableCell>
                <TableCell>{row.k} g</TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function SumBasicRation(props) {
    const { isMinerals, SetOpen, dialogType } = props;

    const [open, setOpen] = React.useState(SetOpen ? SetOpen : false);

    const calculator = FoodCalculator.getInstance();


    useEffect(() => {

    }, []);

    if (calculator.basicRation.length > 0) {
        if (isDesktop) {
            return (
                <React.Fragment>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsable table"
                        >
                            <TableHead onClick={() => setOpen(!open)} >
                                <TableRow>
                                    <TableCell style={{ width: 210 }}>
                                        <Typography sx={{ float: "left", marginTop: .6 }}>Grundfutter</Typography>
                                        <IconButton aria-label="expand row" size="small">
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>TM</TableCell>
                                    {isMinerals ? <TableCell></TableCell> : null}
                                    <TableCell>{isMinerals ? "Ca" : "XF"} </TableCell>
                                    <TableCell>{isMinerals ? "P" : "aNDF"}</TableCell>

                                    <TableCell>{isMinerals ? "Mg" : "RNB"}</TableCell>
                                    {isMinerals ? null : <TableCell></TableCell>}
                                    <TableCell>{isMinerals ? "Na" : "XP"}</TableCell>
                                    <TableCell>{isMinerals ? "K" : "nXP"}</TableCell>

                                    {isMinerals ? null : <TableCell>NEL</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {open ?
                                    <React.Fragment >
                                        {
                                            !isMinerals ?
                                                calculator.basicRation.map((row, index) => {
                                                    return <Row row={row} />
                                                })
                                                :
                                                calculator.basicRation.map((row, index) => {
                                                    return <RowMinerals row={row} />
                                                })
                                        }
                                        <TableRow >
                                            <TableCell > - Erhaltungsbedarf</TableCell>
                                            <TableCell >{isMinerals ? "" : null}</TableCell>
                                            {isMinerals ? <TableCell></TableCell> : null}
                                            <TableCell >{isMinerals ? "- " + calculator.maintenanceBasicRation.ca.toFixed() + " g" : null}</TableCell>
                                            <TableCell >{isMinerals ? "- " + calculator.maintenanceBasicRation.p.toFixed() + " g" : null}</TableCell>
                                            <TableCell >{isMinerals ? "- " + calculator.maintenanceBasicRation.mg.toFixed() + " g" : null}</TableCell>
                                            {isMinerals ? null : <TableCell ></TableCell>}
                                            <TableCell >{isMinerals ? "- " + calculator.maintenanceBasicRation.na.toFixed() + " g" : "- " + calculator.maintenanceBasicRation.xp.toFixed() + " g"}</TableCell>
                                            <TableCell >{isMinerals ? "- " + calculator.maintenanceBasicRation.k.toFixed() + " g" : "- " + calculator.maintenanceBasicRation.nxp.toFixed() + " g"}</TableCell>
                                            {
                                                isMinerals ?
                                                    null
                                                    :
                                                    <React.Fragment>
                                                        <TableCell > {"- " + calculator.maintenanceBasicRation.nel.toFixed()} MJ</TableCell>
                                                    </React.Fragment>
                                            }
                                        </TableRow>
                                    </React.Fragment>
                                    :
                                    null
                                }
                                <TableRow >
                                    <TableCell>Summe GF</TableCell>
                                    <TableCell>{calculator.sumBasicRation.tm.toFixed()} kg ({calculator.sumBasicRation.tmFmPercentage.toFixed()}%)</TableCell>
                                    {isMinerals ? (!open ? <TableCell>Milch:</TableCell> : <TableCell></TableCell>) : null}
                                    <TableCell>{isMinerals ? (!open ? calculator.milkInKgBasicRation.ca.toFixed() + " kg" : calculator.inMilkBasicRation.ca.toFixed() + " g") : calculator.xfBasicRationPercentage.toFixed() + "%"}</TableCell>
                                    <TableCell>{isMinerals ? (!open ? calculator.milkInKgBasicRation.p.toFixed() + " kg" : calculator.inMilkBasicRation.p.toFixed() + " g") : calculator.andfomBasicRationPercentage.toFixed() + "%"}</TableCell>
                                    <TableCell>{isMinerals ? (!open ? calculator.milkInKgBasicRation.mg.toFixed() + " kg" : calculator.inMilkBasicRation.mg.toFixed() + " g") : calculator.sumBasicRation.rnb.toFixed()} </TableCell>
                                    {isMinerals ? null : !open ? <TableCell>Milch:</TableCell> : <TableCell></TableCell>}
                                    <TableCell>{isMinerals ? (!open ? calculator.milkInKgBasicRation.na.toFixed() + " kg" : calculator.inMilkBasicRation.na.toFixed() + " g") : open ? calculator.inMilkBasicRation.xp.toFixed() + " g" : calculator.milkInKgBasicRation.xp.toFixed() + " kg"} </TableCell>
                                    <TableCell>{isMinerals ? (!open ? calculator.milkInKgBasicRation.k.toFixed() + " kg" : calculator.inMilkBasicRation.k.toFixed() + " g") : open ? calculator.inMilkBasicRation.nxp.toFixed() + " g" : calculator.milkInKgBasicRation.nxp.toFixed() + " kg"}</TableCell>
                                    {
                                        isMinerals ?
                                            ""
                                            :
                                            <React.Fragment>
                                                <TableCell>{open ? calculator.inMilkBasicRation.nel.toFixed() + " MJ" : calculator.milkInKgBasicRation.nel.toFixed() + " kg"}</TableCell>
                                            </React.Fragment>
                                    }
                                </TableRow>

                                {open ?
                                    <React.Fragment>
                                        <TableRow>
                                            <TableCell >÷ Bedarf pro kg Milch</TableCell>
                                            <TableCell >{isMinerals ? "" : null}</TableCell>
                                            {isMinerals ? <TableCell></TableCell> : null}
                                            <TableCell >{isMinerals ? calculator.demandPerKgMilkBasicRation.ca.toFixed(2) + " g" : null}</TableCell>
                                            <TableCell >{isMinerals ? calculator.demandPerKgMilkBasicRation.p.toFixed(2) + " g" : null}</TableCell>
                                            <TableCell >{isMinerals ? calculator.demandPerKgMilkBasicRation.mg.toFixed(2) + " g" : null}</TableCell>
                                            {isMinerals ? null : <TableCell ></TableCell>}
                                            <TableCell >{isMinerals ? calculator.demandPerKgMilkBasicRation.na.toFixed(2) + " g" : calculator.demandPerKgMilkBasicRation.xp.toFixed() + " g"}</TableCell>
                                            <TableCell >{isMinerals ? calculator.demandPerKgMilkBasicRation.k.toFixed(2) + " g" : calculator.demandPerKgMilkBasicRation.nxp.toFixed() + " g"}</TableCell>
                                            {
                                                isMinerals ?
                                                    null
                                                    :
                                                    <React.Fragment>
                                                        <TableCell > 3,3 MJ </TableCell>
                                                    </React.Fragment>
                                            }
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>= Milch aus GF</TableCell>
                                            <TableCell>{isMinerals ? "" : null}</TableCell>
                                            {isMinerals ? <TableCell></TableCell> : null}
                                            <TableCell>{isMinerals ? calculator.milkInKgBasicRation.ca.toFixed() + " kg" : null}</TableCell>
                                            <TableCell>{isMinerals ? calculator.milkInKgBasicRation.p.toFixed() + " kg" : null}</TableCell>
                                            <TableCell>{isMinerals ? calculator.milkInKgBasicRation.mg.toFixed() + " kg" : null}</TableCell>
                                            {isMinerals ? null : <TableCell ></TableCell>}
                                            <TableCell>{isMinerals ? calculator.milkInKgBasicRation.na.toFixed() + " kg" : calculator.milkInKgBasicRation.xp.toFixed() + " kg"}</TableCell>
                                            <TableCell>{isMinerals ? calculator.milkInKgBasicRation.k.toFixed() + " kg" : calculator.milkInKgBasicRation.nxp.toFixed() + " kg"}</TableCell>
                                            {
                                                isMinerals ?
                                                    null
                                                    :
                                                    <React.Fragment>
                                                        <TableCell>{calculator.milkInKgBasicRation.nel.toFixed() + " kg"}</TableCell>
                                                    </React.Fragment>
                                            }
                                        </TableRow>
                                    </React.Fragment>
                                    : ""
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>

                </React.Fragment>
            );
        }

        if (isMobile) {
            if (isMinerals) {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead onClick={() => setOpen(!open)} >
                                    <TableRow>
                                        <TableCell style={{ width: 210 }}>
                                            <Typography sx={{ float: "left", marginTop: .6 }}>Grundfutter</Typography>
                                            <IconButton aria-label="expand row" size="small">
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        {dialogType === 0 || open ?
                                            <TableCell>Summe GF</TableCell>
                                            : null}
                                        {open ? calculator.basicRation.map((row, index) => {
                                            return <TableCell>{row.name}</TableCell>
                                        }) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogType === 0 || open ?
                                        <React.Fragment>

                                            <TableRow>
                                                <TableCell>TM </TableCell>
                                                <TableCell>{calculator.sumBasicRation.tm.toFixed()} kg ({(calculator.sumBasicRation.tm / calculator.sumBasicRation.fm * 100).toFixed()}%)</TableCell>
                                                {open ? calculator.basicRation.map((row, index) => {
                                                    return <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                                                }) : null}
                                            </TableRow>
                                            {open ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell>Ca</TableCell>
                                                        <TableCell>{calculator.milkInKgBasicRation.ca.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.ca.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>P</TableCell>
                                                        <TableCell>{calculator.milkInKgBasicRation.p.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.p.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Mg</TableCell>
                                                        <TableCell>{calculator.milkInKgBasicRation.mg.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.mg.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Na</TableCell>
                                                        <TableCell>{calculator.milkInKgBasicRation.na.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.na.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>K</TableCell>
                                                        <TableCell>{calculator.milkInKgBasicRation.k.toFixed()} kg</TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.k.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </React.Fragment>
                                                : null
                                            }
                                            <TableRow>
                                                <TableCell>Milch:</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Ca</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.ca.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>P</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.p.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mg</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.mg.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Na</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.na.toFixed()} kg</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>K</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.k.toFixed()} kg</TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )
            }
            else {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead onClick={() => setOpen(!open)} >
                                    <TableRow>
                                        <TableCell style={{ width: 210 }}>
                                            <Typography sx={{ float: "left", marginTop: .6 }}>Grundfutter</Typography>
                                            <IconButton aria-label="expand row" size="small">
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        {dialogType === 0 || open ?
                                            <TableCell>Summe GF</TableCell>
                                            : null}
                                        {open ? calculator.basicRation.map((row, index) => {
                                            return <TableCell>{row.name}</TableCell>
                                        }) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogType === 0 || open ?
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell>TM</TableCell>
                                                <TableCell>{calculator.sumBasicRation.tm.toFixed()} kg ({(calculator.sumBasicRation.tm / calculator.sumBasicRation.fm * 100).toFixed()}%)</TableCell>
                                                {open ? calculator.basicRation.map((row, index) => {
                                                    return <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>XF</TableCell>
                                                <TableCell>{calculator.xfBasicRationPercentage.toFixed() + "%"}</TableCell>
                                                {open ? calculator.basicRation.map((row, index) => {
                                                    return <TableCell>{row.xf.toFixed()} g</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>aNDF</TableCell>
                                                <TableCell>{calculator.andfomBasicRationPercentage.toFixed() + "%"} </TableCell>
                                                {open ? calculator.basicRation.map((row, index) => {
                                                    return <TableCell>{row.andfom.toFixed()} g</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>RNB</TableCell>
                                                <TableCell>{calculator.sumBasicRation.rnb.toFixed()}</TableCell>
                                                {open ? calculator.basicRation.map((row, index) => {
                                                    return <TableCell>{row.rnbPerKg.toFixed()}</TableCell>
                                                }) : null}
                                            </TableRow>

                                            {open ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell>XP</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.xp.toFixed()} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>nXP</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.nxp.toFixed()} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>NEL</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.basicRation.map((row, index) => {
                                                                return <TableCell>{row.nel.toFixed()} MJ</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </React.Fragment>

                                                : null}


                                            <TableRow>
                                                <TableCell>Milch:</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>XP</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.xp.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>nXP</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.nxp.toFixed() + " kg"}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>NEL</TableCell>
                                                <TableCell>{calculator.milkInKgBasicRation.nel.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                );
            }
        }
    }

    return (
        <React.Fragment>

        </React.Fragment>
    );
}
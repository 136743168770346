import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CowPopOver from '../cowpopovercomponent';
import logoGrundfutter from './../../assets/icons/braun/87/icon0011.png';

function BasicRationtTutorial(props) {
    return (
        <div >
            <Paper >
                <img sx={{backgroundColor: '#80BA26',}}  src={logoGrundfutter} alt="" style={{float: 'left', marginRight: '10px'}}/>

                <Typography variant="body1" align='left' style={{ padding: 10 }} >
                    Wähle + FUTTER HINZUFÜGEN, um beliebige Futtermittel zur Ration hinzuzufügen.<br/>
                    Bestätige das Futtermittel mit HINZUFÜGEN<br/>
                    Info:	Über den Button GRUBER TABELLE oder EIGENE FUTTERMITTEL kannst du aus dem Eingabefenster aus Futtermittelsammlungen Futtermittel auswählen.<br/>
                    Mit WEITER kannst du den Rationsausgleich mit Ausgleichsfutter durchführen<br/>
                </Typography>


            </Paper>

            <CowPopOver />
        </div>
    );
}


export default BasicRationtTutorial;
import { Food, FoodExtended } from '../../foodcalc/FoodCalc';


class BlendCalculator {
    //public Food: FoodExtended[];
    public Blend: FoodExtended;

    constructor(food: FoodExtended[]) {
        this.Blend = new FoodExtended();
        this.Blend.Blend = food;
    }

    public CreateBlend(): void {
        this.Blend.name = 'Blend';


        this.Blend.Blend.forEach((food) => {
            this.Blend.fm += food.fm;
        });

        for (let i = 0; i < this.Blend.Blend.length; i++) {
            if (this.Blend.Blend[i].fm == 0) continue;

            let percentage = this.Blend.Blend[i].fm / this.Blend.fm;

            this.Blend.tm += this.Blend.Blend[i].tm * percentage;
            this.Blend.xf += this.Blend.Blend[i].xf * percentage;
            this.Blend.andfom += this.Blend.Blend[i].andfom * percentage;
            this.Blend.xp += this.Blend.Blend[i].xp * percentage;
            this.Blend.nxp += this.Blend.Blend[i].nxp * percentage;
            this.Blend.nel += this.Blend.Blend[i].nel * percentage;
            this.Blend.ca += this.Blend.Blend[i].ca * percentage;
            this.Blend.p += this.Blend.Blend[i].p * percentage;
            this.Blend.k += this.Blend.Blend[i].k * percentage;
            this.Blend.na += this.Blend.Blend[i].na * percentage;
            this.Blend.mg += this.Blend.Blend[i].mg * percentage;
        }
    }

    public get ANDFPercentage(): number {
        if (this.Blend.tm == 0)
            return 0;
        return this.Blend.xf / this.Blend.tm * 100;
    }

    public get XfPercentage(): number {
        if (this.Blend.tm == 0)
            return 0;

        return this.Blend.andfom / this.Blend.tm * 100
    }

}


export { BlendCalculator };
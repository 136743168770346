import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

GeruchButter.propTypes = {

};



function GeruchButter(props) {

    const { values, onValueChanged } = props;



    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Geruch:</Typography>

            <div style={{ paddingTop: 10 }}>
                <Typography>Buttersäure (Geruch nach Schweiß, ranziger Butter)</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth >
                        <RadioGroup
                            aria-labelledby="Geruch-radio-buttons-group-label"
                            name="Geruch"
                            value={values.butter}
                            onChange={(e) => handleChange("butter", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="nicht wahrnehmbar (-0 Punkte)" />
                            <FormControlLabel value="2" control={<Radio color="default"/>} label="schwach, erst nach Fingerprobe (reiben) wahrnehmbar (-2 Punkte)" />
                            <FormControlLabel value="3" control={<Radio color="default"/>} label="auch ohne Fingerprobe schwach wahrnehmbar (-3 Punkte)" />
                            <FormControlLabel value="5" control={<Radio color="default"/>} label="aus ca. 1m Entfernung deutlich wahrnehmbar (-5 Punkte)" />
                            <FormControlLabel value="7" control={<Radio color="default"/>} label="schon aus einiger Entfernung stark wahrnehmbar, fäkalartig (-7 Punkte)" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default GeruchButter;
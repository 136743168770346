import { Typography } from '@mui/material';

function Start(props) {
    return (
        <div>
            <Typography variant='h6' sx={{paddingTop: 5}}>Futterbewertung Grassilage</Typography>
        </div>
    );
}

export default Start;
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';



function Verschmutzung(props) {

    const { values, onValueChanged } = props;

    const handleChange = (key, value) => {
        onValueChanged(key, value);
    };

    return (
        <div>
            <Typography variant='h6'>Verschmutzung:</Typography>


            <div style={{ paddingTop: 10 }}>
                <Typography>Qualitätsabzug durch Verschmutzung</Typography>
                <div style={{ paddingTop: 10 }}>
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="verschmutzung-radio-buttons-group-label"
                            name="verschmutzung"
                            value={values.verschmutzung}
                            onChange={(e) => handleChange("verschmutzung", e.target.value)}
                        >
                            <FormControlLabel value="0" control={<Radio color="default"/>} label="Keine Verschmutzung (-0 MJ)" />
                            <FormControlLabel value="0.2" control={<Radio color="default"/>} label="Handfläche nach der TM-Bestimmung (Wringprobe) mit leichten Schmutzspuren (-0.2 MJ)" />
                            <FormControlLabel value="0.4" control={<Radio color="default"/>} label="leichte, aber deutlich feststellbare Verschmutzungen (Sandkörner, Erdteilchen, Güllereste) (-0.4 MJ)" />
                            <FormControlLabel value="0.6" control={<Radio color="default"/>} label="starke Verschmutzungen (-0.6 MJ)" />
                        </RadioGroup>

    
                    </FormControl>
                </div>
            </div>

        </div>
    );
}

export default Verschmutzung;
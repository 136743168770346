import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect } from 'react';

import { FoodCalculator } from '../../foodcalc/FoodCalc';

import { isDesktop, isMobile } from 'react-device-detect';

function Row(props) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                <TableCell>{row.xf} g</TableCell>
                <TableCell>{row.andfom} g</TableCell>
                <TableCell>{row.rnbPerKg.toFixed()} g</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.xp} g</TableCell>
                <TableCell>{row.nxp} g</TableCell>
                <TableCell>{row.nel} MJ</TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function RowMinerals(props) {
    const { row } = props;



    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                <TableCell></TableCell>
                <TableCell>{row.ca} g</TableCell>
                <TableCell>{row.p} g</TableCell>
                <TableCell>{row.mg} g</TableCell>
                <TableCell>{row.na} g</TableCell>
                <TableCell>{row.k} g</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function SumPerformanceRation(props) {
    const { ration, isMinerals, SetOpen, dialogType } = props;
    const [open, setOpen] = React.useState(SetOpen ? SetOpen : false);

    const calculator = FoodCalculator.getInstance();



    useEffect(() => {

    }, []);




    if (ration.performanceRation.length > 0) {
        if (isDesktop) {
            return (
                <React.Fragment>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsable table">
                            <TableHead onClick={() => setOpen(!open)} >
                                <TableRow>
                                    <TableCell style={{ width: 210 }}>
                                        <Typography sx={{ float: "left", marginTop: .6 }}>Leistungsfutter</Typography>
                                        <IconButton aria-label="expand row" size="small">
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>TM</TableCell>
                                    {isMinerals ? <TableCell></TableCell> : null}
                                    <TableCell>{isMinerals ? "Ca" : "XF"}</TableCell>
                                    <TableCell>{isMinerals ? "P" : "aNDF"}</TableCell>

                                    <TableCell>{isMinerals ? "Mg" : "RNB"}</TableCell>
                                    {isMinerals ? null : <TableCell></TableCell>}
                                    <TableCell>{isMinerals ? "Na" : "XP"}</TableCell>
                                    <TableCell>{isMinerals ? "K" : "nXP"}</TableCell>

                                    {isMinerals ? null : <TableCell>NEL</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {open ?
                                    <React.Fragment >
                                        {
                                            !isMinerals ?
                                                calculator.performanceRation.map((row, index) => {
                                                    return <Row row={row} />
                                                })
                                                :
                                                calculator.performanceRation.map((row, index) => {
                                                    return <RowMinerals row={row} />
                                                })
                                        }
                                    </React.Fragment>
                                    :
                                    null
                                }
                                <TableRow>
                                    <TableCell>Summe GF+AF+LF</TableCell>
                                    <TableCell>{calculator.sumPerformanceRation.tm.toFixed()} kg ({(calculator.sumPerformanceRation.tm / calculator.sumPerformanceRation.fm * 100).toFixed()}%)</TableCell>
                                    {isMinerals ? (!open ? <TableCell>Milch:</TableCell> : <TableCell></TableCell>) : null}
                                    <TableCell>{isMinerals ? !open ? calculator.milkInKgPerformanceRation.ca.toFixed() + " kg" : calculator.sumPerformanceRation.ca.toFixed() + " g" : calculator.xfPerformanceRationPercentage.toFixed() + "%"}</TableCell>
                                    <TableCell>{isMinerals ? !open ? calculator.milkInKgPerformanceRation.p.toFixed() + " kg" : calculator.sumPerformanceRation.p.toFixed() + " g" : calculator.andfomPerformanceRationPercentage.toFixed() + "%"}</TableCell>

                                    <TableCell>{isMinerals ? !open ? calculator.milkInKgPerformanceRation.mg.toFixed() + " kg" : calculator.sumPerformanceRation.mg.toFixed() + " g" : calculator.sumPerformanceRation.rnb.toFixed()}</TableCell>
                                    {isMinerals ? null : <TableCell>{open ? null : "Milch:"}</TableCell>}
                                    <TableCell>{isMinerals ? !open ? calculator.milkInKgPerformanceRation.na.toFixed() + " kg" : calculator.sumPerformanceRation.na.toFixed() + " g" : !open ? calculator.milkInKgPerformanceRation.xp.toFixed() + " kg" : calculator.sumPerformanceRation.xp.toFixed() + " g"}</TableCell>
                                    <TableCell>{isMinerals ? !open ? calculator.milkInKgPerformanceRation.k.toFixed() + " kg" : calculator.sumPerformanceRation.k.toFixed() + " g" : !open ? calculator.milkInKgPerformanceRation.nxp.toFixed() + " kg" : calculator.sumPerformanceRation.nxp.toFixed() + " g"}  </TableCell>

                                    {isMinerals ? null : <TableCell>{!open ? calculator.milkInKgPerformanceRation.nel.toFixed() + " kg" : calculator.sumPerformanceRation.nel.toFixed() + "  MJ"}</TableCell>}
                                </TableRow>
                                {open ?
                                    <TableRow>
                                        <TableCell >Milch aus GF+AF+LF</TableCell>
                                        <TableCell ></TableCell>
                                        {isMinerals ? <TableCell></TableCell> : null}
                                        <TableCell >{isMinerals ? calculator.milkInKgPerformanceRation.ca.toFixed() + " kg" : null}</TableCell>
                                        <TableCell >{isMinerals ? calculator.milkInKgPerformanceRation.p.toFixed() + " kg" : null}</TableCell>

                                        <TableCell >{isMinerals ? calculator.milkInKgPerformanceRation.mg.toFixed() + " kg" : null}</TableCell>
                                        <TableCell >{isMinerals ? calculator.milkInKgPerformanceRation.na.toFixed() + " kg" : null}</TableCell>
                                        <TableCell >{isMinerals ? calculator.milkInKgPerformanceRation.k.toFixed() + " kg" : calculator.milkInKgPerformanceRation.xp.toFixed() + " kg"} </TableCell>
                                        {isMinerals ? null : <TableCell >{calculator.milkInKgPerformanceRation.nxp.toFixed() + " kg"}</TableCell>}

                                        {isMinerals ? null : <TableCell >{calculator.milkInKgPerformanceRation.nel.toFixed() + " kg"}</TableCell>}

                                    </TableRow>
                                    : null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            );
        }

        if (isMobile) {

            if (isMinerals) {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead onClick={() => setOpen(!open)} >
                                    <TableRow>
                                        <TableCell style={{ width: 210 }}>
                                            <Typography sx={{ float: "left", marginTop: .6 }}>Leistungsfutter</Typography>
                                            <IconButton aria-label="expand row" size="small">
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        {dialogType === 2 || open ?
                                            <TableCell>Summe GF+AF+LF</TableCell>
                                            : null}
                                        {open ? calculator.performanceRation.map((row, index) => {
                                            return <TableCell>{row.name}</TableCell>
                                        }) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogType === 2 || open ?
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell>TM</TableCell>
                                                <TableCell>{calculator.sumPerformanceRation.tm.toFixed()} kg ({(calculator.sumPerformanceRation.tm / calculator.sumPerformanceRation.fm * 100).toFixed()}%)</TableCell>
                                                {open ? calculator.performanceRation.map((row, index) => {
                                                    return <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                                                }) : null}
                                            </TableRow>


                                            {open ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell>Ca</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.ca.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>P</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.p.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Mg</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.mg.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Na</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.na.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>K</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.k.toFixed(1)} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </React.Fragment>

                                                : null}
                                            <TableRow>
                                                <TableCell>Milch:</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Ca</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.ca.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>P</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.p.toFixed() + " kg"}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Mg</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.mg.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Na</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.na.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>K</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.k.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                );
            }
            else {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsable table">
                                <TableHead onClick={() => setOpen(!open)} >
                                    <TableRow>
                                        <TableCell style={{ width: 210 }}>
                                            <Typography sx={{ float: "left", marginTop: .6 }}>Leistungsfutter</Typography>
                                            <IconButton aria-label="expand row" size="small">
                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </TableCell>
                                        {dialogType === 2 || open ?
                                            <TableCell>Summe GF+AF+LF</TableCell>
                                            : null}
                                        {open ? calculator.performanceRation.map((row, index) => {
                                            return <TableCell>{row.name}</TableCell>
                                        }) : null}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dialogType === 2 || open ?
                                        <React.Fragment>
                                            <TableRow>
                                                <TableCell>TM</TableCell>
                                                <TableCell>{calculator.sumPerformanceRation.tm.toFixed()} kg ({(calculator.sumPerformanceRation.tm / calculator.sumPerformanceRation.fm * 100).toFixed()}%)</TableCell>
                                                {open ? calculator.performanceRation.map((row, index) => {
                                                    return <TableCell>{row.tmPerKg.toFixed(2)} kg</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>XF</TableCell>
                                                <TableCell>{calculator.xfPerformanceRationPercentage.toFixed() + "%"}</TableCell>
                                                {open ? calculator.performanceRation.map((row, index) => {
                                                    return <TableCell>{row.xf.toFixed()} g</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>aNDF</TableCell>
                                                <TableCell>{calculator.andfomPerformanceRationPercentage.toFixed() + "%"} </TableCell>
                                                {open ? calculator.performanceRation.map((row, index) => {
                                                    return <TableCell>{row.andfom.toFixed()} g</TableCell>
                                                }) : null}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>RNB</TableCell>
                                                <TableCell>{calculator.sumPerformanceRation.rnb.toFixed()}</TableCell>
                                                {open ? calculator.performanceRation.map((row, index) => {
                                                    return <TableCell>{row.rnbPerKg.toFixed()}</TableCell>
                                                }) : null}
                                            </TableRow>

                                            {open ?
                                                <React.Fragment>
                                                    <TableRow>
                                                        <TableCell>XP</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.xp.toFixed()} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>nXP</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.nxp.toFixed()} g</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>NEL</TableCell>
                                                        <TableCell></TableCell>
                                                        {
                                                            calculator.performanceRation.map((row, index) => {
                                                                return <TableCell>{row.nel.toFixed()} MJ</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                </React.Fragment>

                                                : null}
                                            <TableRow>
                                                <TableCell>Milch:</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>XP</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.xp.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>nXP</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.nxp.toFixed() + " kg"}</TableCell>

                                            </TableRow>
                                            <TableRow>
                                                <TableCell>NEL</TableCell>
                                                <TableCell>{calculator.milkInKgPerformanceRation.nel.toFixed() + " kg"}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        : null }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                );
            }
        }
    }

    return (
        <React.Fragment>

        </React.Fragment>
    );
}